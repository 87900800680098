.c-btn {
    background: none !important;
    border: 1px solid #0079fe;
    color: none !important;
}
.c-btn.disabled {
    background: #ccc !important;
}
.selected-list {
    .c-btn {
        height: 24px;
        width: 100%;
        background-color: #fff !important;
        border-radius: 3px;
        display: inline-block;
        border: 2px solid #202020;
        padding: 4px 28px 4px 8px !important;
        border: 0 !important;
        outline: 0 !important;
        -moz-appearance: none !important;
        -webkit-appearance: none !important;
        line-height: normal !important;
        overflow: hidden;
    }
    .c-angle-down,
    .c-angle-up {
        svg {
            fill: none !important;
        }
    }
}


.dropdown-list {
    padding-top: 0 !important;
    z-index: 12001 !important;
}
.arrow-up,
.arrow-down {
    border-bottom: 0 !important;
}

.arrow-2 {
    border-bottom: 0 !important;
}
.pure-checkbox input[type="checkbox"]:checked + label:before {
    background-image: url("~assets/images/confirmation_check_white.svg");
    background-position: center;
    height: 24px;
    width: 24px;
    background-color: #0f155b !important;
}
.pure-checkbox {
    input[type="checkbox"] + label:before {
        box-sizing: content-box !important;
        position: absolute !important;
        top: 50% !important;
        left: 0 !important;
        width: 24px !important;
        height: 24px !important;
        margin-top: -9px !important;
        text-align: center !important;
        transition: all 0.4s ease !important;
        border: 2px solid #202020;
    }
    input[type="checkbox"] + label:after {
        margin-top: -7px !important;
        border-style: none !important;
    }
    input[type="checkbox"]:disabled + label:before {
        border-color: #ccc !important;
    }
    input[type="checkbox"]:disabled:checked + label:before {
        background-color: #ccc !important;
    }
}
.selected-item {
    background: #e9f4ff;
}
.btn-iceblue {
    background: #0079fe;
    border: 1px solid #ccc;
    color: #fff !important;
}
.contentDiv.contentDivDimensions {
    width: 100%;
    height: 2.875rem;
    border: 1px solid grey;
    padding-top: 0.5rem !important;
    padding-right: 0;
    border-radius: 3px 0px 0px 3px;
}
.contentDiv2.contentDiv2Dimensions {
    width: 178px;
    height: 2.875rem;
    background: #002f87;
    border-radius: 0px 3px 3px 0px;
    padding-top: 0.3rem;
}

.outer-div {
    width: 100%;
    text-align: center;
}
.inner-div {
    display: inline-block;
    margin: 0 auto;
    padding: 3px;
    width: 9px;
    height: 9px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(45deg);
    transform-origin: 20% 40%;
}
 .multiSelectParent {
    position: relative;
    angular2-multiselect {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .selected-list {
            opacity: 0;
        }
        .select-all {
            border-bottom: 1px solid #ccc !important;
            padding-bottom: 1.3em;
        }
        .list-filter {
            padding-left: 0 !important;
            border-bottom: 1px solid #ccc;
            .c-search {
                svg {
                    fill: none !important;
                }
            }
            .c-clear {
                svg {
                    fill: none !important;
                }
            }
        }
    }
    .addingFocus {
        outline: none;
        border: 1px solid $focus-color;
        -webkit-box-shadow: 0 0 5px $focus-color;
        box-shadow: 0 0 5px $focus-color;
        border-radius: 0.2rem;
    }
    &:hover {
        .contentDiv , .contentDiv2 {
            border: 1px solid #0F155B;
            box-shadow: 0 0 5px #0F155B;
            -webkit-box-shadow: 0 0 5px #0F155B;
        }
    }
}
.disabledDropdown {
    pointer-events: none;
    opacity: 0.5;
}

.filterBlueBox {
    height: 35px;
    border: 1px solid #0679ca;
    display: inline-block;
    border-radius: 4px;
    margin-top: 10px;
}

.display-inline-block {
    display: inline-block;
}

.filterBoxClose {
    display: inline-block;
    cursor: pointer;
}

.color-0679ca {
    color: #0679ca;
}



.cursor-pointer {
    cursor: pointer;
}

.padding-right-5px {
    padding-right: 5px;
}

.padding-left-5px {
    padding-left: 5px;
}

.font-size-19px {
    font-size: 19px;
}

.margin-top-10px {
    margin-top: 10px;
}

.removeAll {
    padding-left: 6px;
    padding-top: 5px;
}

.padding-top-5px {
    padding-top: 5px;
}
.selected-list .c-list .c-token .c-remove {
    pointer-events: none !important;
}

 .selected-list .c-remove.clear-all {
    pointer-events: none !important;
}
