

@mixin border-top ($size, $type ,$color){
    border-top-style: $type;
    border-top-color: $color;
    border-top-width: $size;
}
@mixin border-bottom ($size, $type ,$color){
    border-bottom-style: $type;
    border-bottom-color: $color;
    border-bottom-width: $size;
}
@mixin border ($size, $type ,$color){
    border-style: $type;
    border-color: $color;
    border-width: $size;
}

@mixin button($background-color, $border-radius) {
    background-color: $background-color;
    border-radius: $border-radius;
    border: solid 3px $background-color;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
        background-color: $background-color;
    }
    &:hover{
        @include box-shadow( 0px 3px 5px $button-shadow);
    }
    &:disabled{
        cursor: not-allowed;
        opacity: .35;
        @include box-shadow(none !important);
    }
    &:focus{
        outline: none;
        border: 2px solid $white !important;
        outline: 1px solid $button-color;
    }
}

@mixin card($background-color, $border-radius, $border, $min-height) {
    background-color: $background-color;
    border-radius: $border-radius;
    border: $border;
    min-height: $min-height;
}

@mixin tab($background, $color, $padding: 1em 2em){
    background-color: $background;
    .nav{
        border-bottom: solid 2px $gray;
        .nav-link{
            padding: $padding;
            color: $color;
            &.active{
                font-weight: 700;
                background-color: transparent;
                @include border-bottom(0.25em,solid, $link);
            }
            &.disabled{
                
            }
        }
    }
}

//change color with opacity 
@mixin backGroundColorOpacityMixin($opacity,$color) {
    background-color: rgba(red($color), green($color), blue($color), $opacity);
  }