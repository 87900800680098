/* PF Dialog/Modal */

div.bp-dialog {
  div.ui-dialog-content.ui-widget-content {
    padding: 0;

    div {
      overflow: hidden;
    }
  }

  &.ui-dialog.ui-widget.ui-widget-content.ui-corner-all.ui-shadow.ui-overlay-visible div.ui-dialog-titlebar.ui-widget-header.ui-helper-clearfix.ui-corner-top {
    display: none !important;
  }

  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;

  .introtext {
    padding-top: 1em;
  }

  h2.bp-dialog-header {
    margin-top: 1em;
  }
}

/* TABLET & DESKTOP */
@media screen and (min-width: 767px) {
  div.bp-dialog {
    .container-fluid {
      padding-left: 20px;
    }

    &.ui-dialog.ui-widget.ui-widget-content.ui-corner-all.ui-shadow.ui-overlay-visible {
      width: 800px !important;
      background: white;
      left: 50% !important;
      top: 50% !important;
      transform: translate(-50%, -50%) !important;
      /* IE9 Fix */
      -ms-transform: translate(-50%, -50%) !important;
      padding-bottom: 2em;
      -moz-box-shadow: 0 0 10px #8C8C8C;
      -webkit-box-shadow: 0 0 10px #8C8C8C;
      box-shadow: 0 0 10px #8C8C8C;
    }
  }
}

/* TABLET - CR-51127-UI-010 */
@media screen and (min-width: $screen-mobile) and (max-width: $screen-tablet) {
  div.bp-dialog {
    &.ui-dialog.ui-widget.ui-widget-content.ui-corner-all.ui-shadow.ui-overlay-visible {
      width: 95% !important;
    }

    max-height: 78.125% !important;
  }
}

/* MOBILE */
@media screen and (max-width: $screen-mobile) {
  div.bp-dialog.ui-dialog.ui-widget.ui-widget-content.ui-corner-all.ui-shadow.ui-overlay-visible {
    width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
    top: 0px !important;
    left: 0px !important;
    overflow: hidden;
    background: white;

    div.ui-dialog-content.ui-widget-content {
      border: 1px solid rgb(121, 121, 121);
      padding-bottom: 1em;
      width: 95%;
      height: 95% !important;
      margin: 1em auto 1em auto;
      padding-top: 1em;
    }
  }
}

/** Modal Changes **/
.modalheader {
  border-bottom: none !important;
  margin-top: 0px;
  background-color: $gray-modal-header;
  padding-left: 25px !important;
  padding-right: 25px !important;

}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modalheader .h4 ,.modalheader h4{
  font-size: 32px;
  line-height: 38.4px;
  letter-spacing: -0.6px;

  &.longHeader {
    width: 90%;
    display: inline-block;
  }
}

.modal-body {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.modal-margin-top {
  margin-top: 0;
}

.cb-modal-close-btn {
  background-image: url("~assets/images/blueprint/close-black.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  display: inline-block;
  position: relative;
  top: 8px; //changed 5px 
  padding: 12px;
}

@media screen and (min-width: 1200px) {
  .modal-open {
    .modal.d-block.disable-modal-scroll {
      overflow-y: hidden;
    }
  }
}