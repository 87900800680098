/*
 * Application global variables.
 */
 $default-font-family: 'cerapro-regular', Helvetica, sans-serif;
 $default-font-size: 16px; 
 $default-background: #ffffff;
 $default-font-color: #252525;
// Set Font Awesome font path
$fa-font-path: "~/font-awesome/fonts";

// ---------------------------------------------------------------------------
// Bootstrap variables
//
// Override Bootstrap variables here to suite your theme.
// Copy variables you want to customize from node_modules/bootstrap/scss/_variables.scss

//
// Color system
//

// $white:  #fff;
// $gray-100: #f8f9fa;
// $gray-200: #e9ecef;
// $gray-300: #dee2e6;
// $gray-400: #ced4da;
// $gray-500: #adb5bd;
// $gray-600: #868e96;
// $gray-700: #495057;
// $gray-800: #343a40;
// $gray-900: #212529;
// $black:  #000;

// $blue:    #0073dd;
// $indigo:  #6610f2;
// $purple:  #6f42c1;
// $pink:    #e83e8c;
// $red:     #dc3545;
// $orange:  #fd7e14;
// $yellow:  #ffc107;
// $green:   #28a745;
// $teal:    #20c997;
// $cyan:    #17a2b8;

// $theme-colors: (
//   primary: $blue,
//   secondary: $gray-600,
//   success: $green,
//   info: $cyan,
//   warning: $yellow,
//   danger: $red,
//   light: $gray-100,
//   dark: $gray-800
// );

// Use Bootstrap defaults for other variables, imported here so we can access all app variables in one place when used
// in components.


// theme variables


$default-spacing: 10px; //overriding bootstrap's default spacing 15px padding and margin
// $default-card-radius: , $default-card-border

$themes : (
    bTheme : (
      text-color: $default-font-color,
      font-family: $default-font-family,
      font-size: 1em,
      theme-color: $button-color,
      border-radius: 0,
      default-spacing: 10px,
      default-card: (
        background-color: white,
        border-radius: 0, 
        border: 1px solid rgba($default-font-color, 0.12)
      ),
      default-tab: (
        background-color: white,
        color: $default-font-color,
        padding: .5em 1em
      ),
      default-gradient:(
        color1: #1ab3f0,
        color2: #002F87
      ),
      line-height: 1.5em
    ),
    // rTheme : (
    //   text-color: $default-font-color,
    //   theme-color: $cyan-blue,
    //   border-radius: 24px
    // )
);

$button-colors: ("green-button": $green);
$card-types: (
  homecard: (
    background-color: $white,
    border-radius: 0, 
    border-color: rgba($default-font-color, 0.12),
    border-style: solid,
    min-height: 361px
  ),
  green-card : (
    background-color: $green,
    border-radius: 0.35em,
    border-color: rgba($default-font-color, 0.22),
    border-style: dashed,
    min-height: 1px
  )
); 

$headings-type: (
    h1: (
      font-size: 32px
    ),
    h2: (
      font-size: 28px
    ),
    h3: (
      font-size: 24px
    ),
    h4: (
      font-size: 22px
    ),
    h5: (
      font-size: 20px,
      font-family: $default-font-family-medium
    ),
    h6: (
      font-size: 18px,
      font-family: $default-font-family-medium
    )
);

$tab-types: (
  gray-tab: (
    background-color: $light-gray,
    color: $default-font-color
  ),
  dark-tab:(
    background-color: $button-color,
    color: $white
  )
);