// .datatable-header-cell-label, .datatable-header-cell-template-wrap {
//   color: $table-header-color;
//   font-family: "open_sanssemibold", Arial, Helvetica, Sans-serif;
// }
ngx-datatable.secondary-dataTable .datatable-header-cell-label,
.datatable-header-cell-template-wrap {
    color: $black;
}

.ngx-datatable.bootstrap {
    font-size: 16px;
}

.ngx-datatable.bootstrap:not(.secondary-dataTable) {
    border-top: 1px solid $datatable-row-bottom;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-body-cell {
    padding: 0.75em 0.935em 0.75em 0.65em;
}

.ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap {
    background-color: transparent;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row {
    border-bottom: 1px solid $datatable-row-bottom;
    border-top: none;
    padding: 0px;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active a,
.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
    background-color: $dark-link-color;
    font-weight: bold;
    color: $white;
    text-decoration: none;
}

.ngx-datatable.bootstrap .datatable-footer,
.ngx-datatable.bootstrap .datatable-footer .datatable-pager a {
    background: transparent;
    font-weight: bold;
    margin: 0em;
    min-width: 1.8125em;
    padding: 0.3125em 0em 1.5em 0.0625em;
}

.ngx-datatable.bootstrap .datatable-footer .page-count {
    display: none;
    color: $link-color;
}

.ngx-datatable .datatable-footer .datatable-pager .pager {
    float: left;
}

.sortable .sort-btn:not([class*="datatable-icon-"]):before {
    background: url('~assets/images/blueprint/icons/arrow-down-small-blue.svg') no-repeat;
    font-family: data-table;
    content: "c";
    color: transparent;
}

.sort-btn {
    float: right;
    margin-top: 0.7em;
}

.secondary-dataTable .datatable-row-wrapper:last-child>.datatable-body-row {
    border-bottom: 1px solid $light-gray !important;
}

.datatable-row-detail {
    height: 100% !important;
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $datatable-disable-color;
    padding-left: 2.7em !important;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager {
    margin-top: 20px;
    margin-left: 0px;
    padding-bottom: 5px;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li {
    margin-right: 10px;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(:first-child) {
    margin-left: 10px;
}

.ngx-datatable.bootstrap {
    .datatable-footer {
        .datatable-pager {
            ul {
                li {
                    &:first-child {
                        border-radius: 5px 0 0 5px;
                    }

                    &:last-child {
                        border-radius: 0 5px 5px 0;
                    }

                    a:focus {
                        outline: 1px solid $link-color !important;
                    }
                }
            }
        }
    }
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li.disabled {
    background-color: transparent;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li {
    background-color: $datatable-disable-color;
    border: solid 1px $gray;
    color: $link-color;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li.pages {
    background-color: transparent;
    border: 1px solid $white;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li.pages.active {
    border: 1px solid $dark-link-color;
    background-color: $dark-link-color;
    color: $white;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li.pages a {
    color: $dark-link-color;
    text-decoration: underline;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li a {
    color: $dark-link-color;
    font-weight: bold;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li.disabled a {
    color: $datatable-pager-disabled;
    opacity: 0.2;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
    background-color: transparent;
    color: $white;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:hover {
    background-color: $dark-link-color;
}

// .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:hover a{
//   color: $white;
// }
.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li.disabled:hover {
    border: solid 1px $gray;
    background-color: transparent;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li.pages.active:hover a {
    color: $white;
}


/*Ngx Data table background changes*/

.datatable-row-even {
    .datatable-stripped & {
        background-color: $datatable-row-even-background-color;
        border-bottom: 2px solid $datatable-row-border-bottom;
    }

    &+.datatable-row-detail {
        background-color: rgba(0, 0, 0, 0.05);
    }
}

.ngx-datatable.bootstrap {
    .datatable-body {
        .secondary-dataTable {
            .datatable-row-even {
                background-color: transparent !important;
            }
        }
    }
}

// .datatable-row-odd{
//   border-bottom: 2px solid $datatable-row-border-bottom;
//   }
.text-datatable-count {
    font-weight: bold;
    color: $dark-link-color;
}

.datatable-icon-up {
    background: url('~assets/images/blueprint/icons/arrow-up-small-blue.svg') no-repeat;
    height: 10px;
    width: 20px;
}

.datatable-icon-down {
    background: url('~assets/images/blueprint/icons/arrow-down-small-blue.svg') no-repeat;
    height: 10px;
    width: 20px
}

.datatable-icon-up:before,
.datatable-icon-down:before {
    content: none;
}

.ngx-datatable {
    .datatable-header {
        display: block !important;
        overflow: hidden;

        .datatable-header-inner {
            .datatable-header-cell {

                // &.resizeable {
                &:not(:first-child) {
                    &::before {
                        content: '';
                        height: 35%;
                        width: 0.15em;
                        position: absolute;
                        left: -1px;
                        top: 17px;
                        background-color: $whitee2;
                    }

                    &.sort-active {

                        &::after,
                        &::before {
                            // background-color: transparent;
                        }
                    }
                }

                // }
            }
        }
    }
}

.datatable-header .datatable-header-inner .datatable-row-center {
    width: 100% !important;
}

::ng-deep .ngx-datatable.bootstrap.smallFooter .datatable-footer .datatable-pager ul li.pages {
    margin-right: 4px;
}

// To make whole header sortable
.datatable-header-cell-label {
    width: calc(100% - 13px); // leave room for sort icon
    // float: left;
}

.datatable-header-cell:not(:last-child) {
    content: "|";
}

//Removing table highlight on sort
// .datatable-header-cell.datatable-header-cell.sortable.resizeable.sort-active.sort-desc,
// .datatable-header-cell.datatable-header-cell.sortable.sort-active.sort-desc {
//   background-color: $table-header-color;
//   span.datatable-header-cell-label.draggable {
//     color: $white;
// }
//   span.sort-btn.sort-desc.datatable-icon-down {
//     background-image: url('~assets/images/blueprint/icons/arrow-down-small-white.svg');
//     background-repeat: no-repeat;
//   }
// }
// .datatable-header-cell.datatable-header-cell.sortable.sort-active.sort-asc,
// .datatable-header-cell.datatable-header-cell.sortable.resizeable.sort-active.sort-asc {
//   background-color: $table-header-color;
//   span.datatable-header-cell-label.draggable {
//     color: $white;
//   }
//   span.sort-btn.sort-asc.datatable-icon-up {
//     background-image: url('~assets/images/blueprint/icons/arrow-up-small-white.svg');
//     background-repeat: no-repeat;
//   }
// }
.datatable-header-cell.datatable-header-cell {
    span.datatable-header-cell-label.draggable {
        color: $black;
        font-family: $default-font-family-bold;
    }
}

.datatable-header-cell.datatable-header-cell.sortable {
    span.datatable-header-cell-label.draggable {
        color: $cyan-blue;
        font-family: $default-font-family-bold;
    }
}

.ngx-datatable {
    &.bootstrap {
        &.no-bg {
            .datatable-body {
                .datatable-body-row {
                    border-bottom: 2px solid #dee2e6;

                    &.datatable-row-even {
                        background-color: transparent;
                    }
                }

                .datatable-row-detail {
                    .secondary-dataTable {
                        .datatable-row-even {
                            background-color: rgba(0, 0, 0, 0.05);
                        }
                    }
                }
            }
        }

        .datatable-body {
            .datatable-body-row {
                .datatable-body-cell {
                    span {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        .datatable-header-cell {
            padding: 0.9375rem 0.625rem 0.9375rem 0.625rem !important;
        }
    }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li {
        background-color: $datatable-disable-color;
        border: solid 1px $gray;
        color: $link-color;
        display: -ms-inline-grid;
    }
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.active {
    background-color: rgba(0, 0, 0, .05) !important;
    color: $black !important;

    &.datatable-row-odd {
        background-color: transparent !important;
    }
}

.network-map {
    .datatable-row-odd {
        background-color: rgba(0, 0, 0, 0.05);
    }
}

// Updating Ngx-datatable version to 19.0.0
// Sorting icon fix
.datatable-icon-sort-unset::before {
    content: none;
    opacity: 0;
}

span.datatable-icon-sort-unset.sort-btn {
    background: url('~assets/images/blueprint/icons/arrow-down-small-blue.svg') no-repeat;
    height: 10px;
    width: 20px;
}

.ngx-datatable.bootstrap .datatable-body {

    .datatable-scroll,
    .datatable-body-row,
    .datatable-row-center.datatable-row-group {
        width: 100% !important;
    }
}

// Horizontal scrollbar fix
.ngx-datatable.scroll-horz .datatable-body .datatable-scroll {
    display: grid;
}

// Display blue background color and quick links on hover
.ngx-datatable.bootstrap:not(.cell-selection).hoverColor .datatable-body-row:hover,
.ngx-datatable.bootstrap:not(.cell-selection).hoverColor .datatable-body-row:focus {
    background-color: $button-color;
    color: white;
    border-radius: 7px;
    font-family: $default-font-family-bold;

    .showLink {
        display: block;
    }
}

.ngx-datatable.bootstrap.hoverColor .datatable-body-row.active {
    background-color: $button-color !important;
    color: white !important;
    border-radius: 7px !important;
    font-family: $default-font-family-bold;

    &.datatable-row-odd {
        background: $button-color !important;
        color: white !important;
        border-radius: 7px !important;
        font-family: $default-font-family-bold;
    }

    .showLink {
        display: block;
    }
}

.ngx-datatable.bootstrap.hoverColor.scrollEffect {
    -webkit-mask-image: linear-gradient(to bottom, black 75%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 75%, transparent 100%);
}

.ngx-datatable.bootstrap.scrollEffect {
    -webkit-mask-image: linear-gradient(to bottom, black 75%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 75%, transparent 100%);
}

.ngx-datatable.bootstrap.scrollEnabled .datatable-body {
    overflow-y: scroll;
    height: 33em !important;

    .datatable-body-row {
        height: 51.5px;
    }
}

// Mobile View
.ngx-datatable.bootstrap.hoverColor.scrollEffectMobile {
    -webkit-mask-image: linear-gradient(to right, black 85%, transparent 100%);
    mask-image: linear-gradient(to right, black 85%, transparent 100%);
}

.ngx-datatable.bootstrap.scrollEffectMobile {
    -webkit-mask-image: linear-gradient(to right, black 85%, transparent 100%);
    mask-image: linear-gradient(to right, black 85%, transparent 100%);
}

.ngx-datatable.bootstrap.scrollEnabled.borderNone .datatable-body {
    .datatable-row-detail {
        border-top: none;
    }
}

@media (max-width: $screen-mobile) and (min-width: 300px) {
    .ngx-datatable.bootstrap.scrollEnabled.transactionTable .datatable-body {
        overflow-x: visible !important;
    }
}

.ngx-datatable {
    &.bootstrap {
        &.no-bg {
            &.no-border {
                border-top: none;

                .datatable-header {
                    .datatable-header-inner .datatable-header-cell:not(:first-child)::before {
                        background-color: $white;
                    }

                    .datatable-header-cell {
                        border-bottom: none;
                    }
                }

                .datatable-body {
                    .datatable-body-row {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}

.ngx-datatable {
    &.bootstrap {
        &.no-border {
            border-top: none;

            .datatable-header {
                .datatable-header-inner .datatable-header-cell:not(:first-child)::before {
                    background-color: $white;
                }

                .datatable-header-inner .datatable-header-cell:not(:first-child)::before {
                    background-color: none;
                }

                .datatable-header-cell {
                    border-bottom: none;
                }
            }

            .datatable-body {
                .datatable-body-row {
                    border-bottom: none;
                }
            }
        }
    }
}