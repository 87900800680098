@use "sass:math";
/* COLORS */

// .chat-div-height{
//   height:4rem;
//   bottom: 0;
//   left: 12rem;
//   right: 12rem;
//   position: fixed;
//   background-color: $button-color;
//   a:link, a:visited{
//       color: $white; 
//       text-decoration: underline;
//   }
//   opacity: 0.9;
// }
// .chat-div-img{
//   color: $white;
//   float: right;
// }
.blue {
    color: $link-color !important;
}

.dark-blue {
    color: $dark-link-color !important;
}

.black {
    color: $black !important;
}

.white {
    color: $white !important;
}

.gray {
    color: $gray !important;
}

.lightgray {
    color: #7b7d7d !important;
}

.red {
    color: $alertErrorLeft !important;
}

.green {
    color: $green !important;
}

.offwhite {
    color: #000000 !important;
}

.disabled-grey {
    color: $disabled-grey;
}

.dark-gray-capp-color {
    color: $dark-gray-capp;
}

.pebble-grey {
    color: $pebble-grey;
}

.light-skyblue {
    color: $light-skyblue;
}

.button-color {
    color: $button-color !important;
}

.pebble-grey {
    color: $pebble-grey;
}

.light-skyblue {
    color: $light-skyblue;
}

.ocean-green {
    color: $ocean-green;
}

.deep-skyblue {
    color: $deep-skyblue;
}

.slate-grey {
    color: $card-text-black;
}
/* MOBILE */

@media screen and (max-width: 767px) {
    .bg-white-xs {
        background-color: white !important;
    }
}

@media screen and (min-width: 1300px) {
    .padding-left-25px {
        padding-left: 25px;
    }
}


/****************************************/


/* OPACITY */

.defocus {
    opacity: 0.5;
    pointer-events: none;
}

.pointer-none {
    pointer-events: none;
}

.background-opacity {
    opacity: 0.4;
}

.opacity-0-3 {
    opacity: 0.3;
}


/****************************************/


/* BACKGROUND COLORS */

.black-bg {
    background-color: black;
}

.white-bg {
    background-color: $white;
}

.gray-bg {
    background-color: #f4f4f4;
}

.gray-bg-imp {
    background-color: #f4f4f4 !important;
}

.gray-bg-std {
    background-color: $gray-modal-header;
}

.blue-bg {
    background-color: $link-color;
}

.dark-gray-bg {
    background-color: #999999;
}

.gray-active-bg {
    background-color: rgb(229, 229, 229);
}

.neon-bg {
    background-color: #00FF00;
}

.green-bg {
    background-color: $alertSuccessLeft !important;
}

.red-bg {
    background-color: $alertErrorLeft !important
}

.light-skyblue-bg {
    background-color: $light-skyblue !important;
}

.button-color-bg {
    background-color: $button-color;
}

.green-bg {
    background-color: $alertSuccessLeft !important;
}

.red-bg {
    background-color: $alertErrorLeft !important
}


/* TABLET & DESKTOP */

@media screen and (min-width: 767px) {
    .md-white-bg {
        background-color: white;
    }

    .md-gray-bg {
        background-color: #f4f4f4;
    }
}


/* MOBILE */

@media screen and (max-width: 767px) {
    .xs-gray-bg {
        background-color: #f4f4f4;
    }

    .xs-gray-active-bg {
        background-color: rgb(229, 229, 229);
    }
}


/****************************************/


/* MOUSE */

.mouse-link {
    cursor: pointer;
}

.mouse-default {
    cursor: default !important;
}


/****************************************/


/*Landing Tour Styles*/

.top-98px {
    top: 98px;
}

.bottom-145px {
    bottom: 145px;
}

.top-50px {
    top: 50px;
}

.top-2px {
    top: 2px;
}

.margin-left-350px {
    margin-left: 350px;
}

.margin-left-8px {
    margin-left: 8px !important;
}

/**********/


/* MARGIN */

.margin-0 {
    margin: 0;
}

.margin-top-1px {
    margin-top: 1px !important;
}

.margin-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

.margin-top-neg-0-3 {
    margin-top: -0.3em;
}

.margin-top-neg-0-4 {
    margin-top: -0.4em;
}

.margin-top-neg-0-5 {
    margin-top: -0.5em;
}

.margin-top-neg-0-75 {
    margin-top: -0.75em;
}

.margin-top-neg-0-9 {
    margin-top: -0.9rem;
}

.margin-top-neg-1rem {
    margin-top: -1rem;
}

.margin-top-neg-40 {
    margin-top: -40px;
}

.margin-top-neg-45 {
    margin-top: -45px !important;
}

.margin-top-neg-23 {
    margin-top: -23px !important;
}

.margin-top-neg-20 {
    margin-top: -20px;
}

.margin-top-neg-15 {
    margin-top: -15px !important;
}

.margin-top-neg-14 {
    margin-top: -14px;
}

.margin-top-neg-10 {
    margin-top: -10px !important;
}

.margin-top-neg-5 {
    margin-top: -5px !important;
}

.margin-top-neg-18 {
    margin-top: -18px;
}

.margin-top-neg-1-2rem {
    margin-top: -1.2rem;
}

.margin-top-neg-0-2rem {
    margin-top: -0.2rem !important;
}

.margin-top-28 {
    margin-top: 28px !important;
}

.margin-top-10px {
    margin-top: 10px !important;
}

.margin-top-1 {
    margin-top: 1em;
}

.margin-top-1-imp {
    margin-top: 1em !important;
}

.margin-top-1-5em {
    margin-top: 1.5em !important;
}

.margin-top-pos-1-7 {
    margin-top: 1.7em;
}

.margin-top-0-1 {
    margin-top: 0.1rem !important;
}

.margin-top-0-19 {
    margin-top: 0.19rem !important;
}

.margin-top-0-5 {
    margin-top: 0.5em !important;
}

.margin-top-0-8 {
    margin-top: 0.8rem !important;
}

.margin-top-0-7 {
    margin-top: 0.7rem !important;
}

.margin-top-pos-1-5 {
    margin-top: 1.5em;
}

.margin-top-pos-1-2 {
    margin-top: 1.2em;
}

.margin-top-pos-1-6 {
    margin-top: 1.6em;
}

.margin-top-pos-1-8 {
    margin-top: 1.8em;
}

.margin-top-6em {
    margin-top: 6em;
}

.margin-top-6em-imp {
    margin-top: 6em !important;
}

.margin-top-8em {
    margin-top: 8em !important;
}

.margin-top-10em {
    margin-top: 10em !important;
}

.margin-top-20em {
    margin-top: 20em;
}

.margin-top-2 {
    margin-top: 2em;
}

.margin-top-2p {
    margin-top: 2px !important;
}

.margin-top-12 {
    margin-top: 12px !important;
}

.margin-top-14 {
    margin-top: 14px !important;
}

.margin-top-4 {
    margin-top: 4px;
}

.margin-top-3 {
    margin-top: 3px;
}


.margin-top-2-6 {
    margin-top: 2.6em;
}

.margin-top-1-5 {
    margin-top: -1.5em;
}

.margin-top-n1-7-5 {
    margin-top: -1.75rem;
}

.margin-top-neg-3-1em {
    margin-top: -3.1em;
}

.margin-top-neg-4em {
    margin-top: -4em;
}

.margin-bottom-neg-2em {
    margin-bottom: -2em;
}

.margin-bottom-neg-2px {
    margin-bottom: -2px;
}

.margin-bottom-neg-8px {
    margin-bottom: -8px;
}

.margin-bottom-neg-24px {
    margin-bottom: -24px;
}

.margin-top-15 {
    margin-top: 15px !important;
}

.margin-top-5 {
    margin-top: 5px !important;
}

.margin-top-20 {
    margin-top: 20px !important;
}

.margin-top-0 {
    margin-top: 0em !important;
}

.margin-top-20px {
    margin-top: 20px !important;
}

.margin-top-40px {
    margin-top: 40px !important;
}

.margin-top-45 {
    margin-top: 45px !important;
}

.margin-top-60px {
    margin-top: 60px;
}

.margin-top-2em-imp {
    margin-top: 2em !important;
}

.margin-top-3em {
    margin-top: 3em;
}

.margin-top-3rem {
    margin-top: 3rem !important;
}

.margin-bottom-3-125rem {
    margin-bottom: 3.125rem;
}

.margin-top-5em {
    margin-top: 5em !important;
}

.margin-top-4em {
    margin-top: 4em;
}

.margin-top-4-6em {
    margin-top: 4.6em;
}

.margin-top-neg-2em {
    margin-top: -2em !important;
}

.margin-top-neg-3em {
    margin-top: -3em !important;
}

.margin-top-neg-5em {
    margin-top: -5em;
}

.margin-bottom-30px {
    margin-bottom: 30px;
}

.margin_top_3em {
    margin-top: 3em !important
}

.margin_top_3_75em {
    margin-top: 3.75em !important;
}

.margin-bottom-4em {
    margin-bottom: 4em !important;
}

.margin-top-3-5em {
    margin-top: 3.5em;
}

.margin-bottom-2-1em {
    margin-bottom: 2.1em !important;
}

.margin-bottom-3em {
    margin-bottom: 3em !important;
}

.margin-bottom-15px {
    margin-bottom: 15px !important;
}

.margin-bottom-13px {
    margin-bottom: 13px !important;
}

.margin-bottom-23px {
    margin-bottom: 23px !important;
}

.margin-bottom-8px {
    margin-bottom: 8px;
}

.margin-bottom-40px {
    margin-bottom: 40px !important;
}

.margin-bottom-10em {
    margin-bottom: 10em !important;
}

.margin-bottom-12em {
    margin-bottom: 12em !important;
}

.margin-bottom-18em {
    margin-bottom: 18em !important;
}

.mb-20 {
    margin-bottom: 1.25rem;
}

.margin-bottom-0 {
    margin-bottom: 0em !important;
}

.margin-bottom-0-4 {
    margin-bottom: 0.4em;
}

.margin-bottom-0-6 {
    margin-bottom: 0.6em;
}

.margin-bottom-1 {
    margin-bottom: 1em;
}

.margin-bottom-1-5 {
    margin-bottom: 1.5em;
}

.margin-bottom-m-1 {
    margin-bottom: -1em;
}

.margin-bottom-m-1-5 {
    margin-bottom: -1.5em;
}

.margin-bottom-m-2-25 {
    margin-bottom: -2.25rem;
}

.margin-top-neg-0-6 {
    margin-top: -0.6rem;
}

.margin-bottom-neg-10 {
    margin-bottom: -10px !important;
}

.margin-top-neg-25 {
    margin-top: -25px !important;
}

.margin-left-2-1em {
    margin-left: 2.1em;
}

.margin-top-neg-30 {
    margin-top: -30px !important;
}

.margin-bottom-neg-15 {
    margin-bottom: -15px !important;
}

.margin-bottom-neg-5 {
    margin-bottom: -5px !important;
}

.margin-bottom-neg-4em {
    margin-bottom: -4em !important;
}

.margin-left-0 {
    margin-left: 0 !important;
}

.margin-left-1em {
    margin-left: 1em;
}

.margin-left-10em {
    margin-left: 10em;
}

.margin-left-11em {
    margin-left: 11em;
}

.margin-left-18em {
    margin-left: 18em !important;
}

.margin-left-18-75 {
    margin-left: 18.75rem;
}

.margin-left-10px {
    margin-left: 10px !important;
}

.margin-left-23 {
    margin-left: 23px !important;
}

.margin-left-88 {
    margin-left: 88px !important;
}

.margin-left-82 {
    margin-left: 82px !important;
}

.margin-left-120px {
    margin-left: 120px !important;
}

.margin-left-neg-1 {
    margin-left: -1em;
}

.margin-left-neg-4-3 {
    margin-left: -4.3em;
}

.margin-left-neg-4-5 {
    margin-left: -4.5em;
}

.margin-left-neg-5em {
    margin-left: -5em;
}

.margin-left-neg-4px {
    margin-left: -4px !important;
}

.margin-left-neg-8px {
    margin-left: -8px !important;
}

.margin-left-neg-23 {
    margin-left: -23px !important;
}

.margin-left-neg-0-6 {
    margin-left: -0.6em;
}

.margin-left-neg-2 {
    margin-left: -2em;
}

.margin-left-neg-3-3 {
    margin-left: -3.3rem;
}

.margin-left-neg-30 {
    margin-left: -30px;
}

.margin-left-neg-45 {
    margin-left: -45px;
}

.margin-top-neg-25 {
    margin-top: -25px !important;
}

.margin-top-neg-20 {
    margin-top: -20px !important;
}

.margin-left-neg-48 {
    margin-left: -48px;
}

.margin-left-neg-3 {
    margin-left: -3px !important;
}

.margin-left-2 {
    margin-left: 2em;
}

.margin-left-2-5em {
    margin-left: 2.5em;
}

.margin-left-2-75em {
    margin-left: 2.75em;
}

.margin-left-4-5em {
    margin-left: 4.5em !important;
}

.margin-left-141 {
    margin-left: 141px !important;
}

.margin-left-1 {
    margin-left: 1px;
}

.margin-left-3 {
    margin-left: 3px !important;
}

.margin-left-5em {
    margin-left: 5em;
}

.margin-left-6 {
    margin-left: 6em;
}

.margin-left-6-5em {
    margin-left: 6.5em;
}

.margin-left-0-6 {
    margin-left: 0.6em;
}

.margin-left-35 {
    margin-left: 35% !important;
}

.margin-left-10 {
    margin-left: 10px !important;
}

.margin-left-15 {
    margin-left: 15px !important;
}

.margin-left-14px {
    margin-left: 14px !important;
}

.margin-left-5px {
    margin-left: 5px !important;
}

.margin-left-3point5 {
    margin-left: 3.5px !important;
}

.margin-left-40px {
    margin-left: 40px !important;
}

.margin-left-45 {
    margin-left: 45px;
}

.margin-left-50 {
    margin-left: 50px !important;
}

.margin-left-55 {
    margin-left: 55px !important;
}

.margin-left-28 {
    margin-left: 28px !important;
}

.margin-left-26 {
    margin-left: 26px !important;
}

.margin-left-0em {
    margin-left: 0em !important;
}

.margin-left-8em {
    margin-left: 8em !important;
}

.margin-left-9em {
    margin-left: 9em !important;
}

.margin-left-125 {
    margin-left: 125px;
}

.margin-left-70px {
    margin-left: 70px !important;
}

.margin-left-72px {
    margin-left: 72px !important;
}

.margin-left-neg-25 {
    margin-left: -25px !important;
}

.margin-left-neg-20 {
    margin-left: -20px !important;
}

.margin-left-neg-15 {
    margin-left: -15px !important;
}

.margin-left-neg-10 {
    margin-left: -10px !important;
}

.margin-left-neg-5 {
    margin-left: -5px !important;
}

.margin-left-neg-44 {
    margin-left: -44px !important;
}

.margin-left-neg-50 {
    margin-left: -50px !important;
}

.margin-left-neg-49 {
    margin-left: -49px !important;
}

.margin-left-neg-25 {
    margin-left: -25px !important;
}

.margin-left-neg-58 {
    margin-left: 58px !important;
}

.margin-left-neg-62 {
    margin-left: -62px !important;
}

.margin-left-neg-12 {
    margin-top: -12px !important;
}

.margin-right-neg-10px {
    margin-right: -10px !important;
}

.margin-right-neg-15px {
    margin-right: -15px !important;
}

.margin-right-0-8rem {
    margin-left: 0.8rem;
}

.margin-right-2rem {
    margin-right: 2rem;
}

.margin-right-2-5rem {
    margin-right: 2.5rem;
}

.margin-right-3rem {
    margin-right: 3rem !important;
}

.margin-right-4-5rem {
    margin-right: 4.5rem;
}

.margin-right-8p {
    margin-right: 8%;
}

.margin-left-1-6em {
    margin-left: 1.6em;
}

.margin-left-1-8rem {
    margin-left: 1.8rem;
}

.margin-left-3-5em {
    margin-left: 3.5em;
}

.margin-left-3-4em {
    margin-left: 3.4em;
}

.margin-left-3em {
    margin-left: 3em;
}

.margin-left-14-1em {
    margin-left: 14.1em;
}

.margin-left-12em {
    margin-left: 12em;
}

.margin-left-40em {
    margin-left: 40em;
}

.margin-left-13em {
    margin-left: 13em;
}

.margin-left-17em {
    margin-left: 17em;
}

.margin-left-5-6em {
    margin-left: 5.6em;
}

.margin-left-0-5 {
    margin-left: 0.5em;
}

.margin-left-neg-0-3 {
    margin-left: -0.3em;
}

.margin-left-1em {
    margin-left: 1em;
}

.margin-left-14-5em {
    margin-left: 14.5em;
}

.ml-ng-1 {
    margin-left: -1rem !important;
}

.margin-right-40px {
    margin-right: 40px !important;
}

.margin-right-neg-40px {
    margin-right: -40px !important;
}

.margin-right-neg-400px {
    margin-right: -400px;
}

.margin-right-20px {
    margin-right: 20px !important;
}

.margin-right-19-5px {
    margin-right: 19.5px !important;
}

.margin-right-30px {
    margin-right: 30px !important;
}

.margin-right-imp-0 {
    margin-right: 0em !important;
}

.margin-right-1 {
    margin-right: 1em;
}

.margin-right-10em {
    margin-right: 10em;
}

.margin-right-0-5 {
    margin-right: 0.5em;
}

.margin-right-20 {
    margin-right: 20px;
}

.margin-right-10 {
    margin-right: 10px !important;
}

.margin-right-15 {
    margin-right: 15px !important;
}

.margin-right-5 {
    margin-right: 5px;
}

.margin-right-8 {
    margin-right: 8px;
}

.margin-right-2 {
    margin-right: 2px !important;
}

.margin-right-neg-50 {
    margin-right: -50px !important;
}

.margin-right-5-5 {
    margin-right: 5.5rem !important;
}

.margin-top-0 {
    margin-top: 0px !important;
}

.margin-top-p-5 {
    margin-top: 5px !important;
}

.margin-top-p-4 {
    margin-top: 4px !important;
}

.margin-top-p-40 {
    margin-top: 40px;
}

.margin-top-12 {
    margin-top: 12px !important;
}

.margin-top-5em {
    margin-top: 5em !important;
}

.margin-neg-top-5 {
    margin-top: -5px;
}

.margin-neg-top-17 {
    margin-top: -17px !important;
}

.margin-neg-top-18 {
    margin-top: -18px
}

.margin-neg-top-24 {
    margin-top: -24px !important;
}

.margin-neg-top-40 {
    margin-top: -40px !important;
}

.margin-neg-top-3 {
    margin-top: -3px
}

.margin-neg-top-4 {
    margin-top: -4px !important;
}

.margin-neg-top-2 {
    margin-top: -2px !important;
}

.margin-neg-top-3 {
    margin-top: -3px !important;
}

.margin-neg-top-9 {
    margin-top: -9px !important;
}

.margin-top-neg-15 {
    margin-top: -15px !important;
}

.margin-neg-top-12 {
    margin-top: -12px !important;
}

.margin-right-neg-3 {
    margin-right: -3px !important;
}

.margin-neg-top-0-52rem {
    margin-top: -0.52rem !important;
}

.margin-right-neg-30 {
    margin-right: -30px !important;
}

.margin-right-neg-6 {
    margin-right: -6px
}

.margin-right-neg-80 {
    margin-right: -80px
}

.margin-center-4 {
    margin-left: 4em;
    margin-right: 4em;
}

.margin-top-7 {
    margin-top: 7px;
}

.margin-top-40 {
    margin-top: 40px !important;
}

.margin-top-47 {
    margin-top: 47px !important;
}

.margin-top-41 {
    margin-top: 41px !important;
}

.margin-x-23p {
    margin: 0% 23% !important;
}

.margin-x-28p {
    margin: 0% 28% !important;
}

.margin-x-10p {
    margin: 0% 10% !important;
}

.margin-right-12px {
    margin-right: 12px;
}

.top-0 {
    top: 0;
}

/* ERROR */

.custom-error {
    width: 31% !important;
    margin-left: 26% !important;
}

.opsutility-custom-error {
    width: 34% !important;
    margin-left: 1.3em !important;
}

.customerhelpdec {
    margin-left: 28px !important;
    width: 75% !important;
}

.customersupportdec {
    margin-left: 28px !important;
    width: 75% !important;
}


/* MOBILE ERROR */

@media screen and (max-width: 767px) {
    .custom-error-xs {
        width: 96% !important;
        float: right !important;
        margin-right: 8px !important;
    }

    .custom-erroraccount-xs {
        width: 75% !important;
        float: left !important;
        margin-left: 8px !important;
    }
}


/* MOBILE */

@media screen and (max-width: 767px) {
    .xs-margin-top-1 {
        margin-top: 1em;
    }

    .xs-margin-0 {
        margin: 0;
    }

    .xs-margin-bottom-1 {
        margin-bottom: 1em;
    }

    .xs-margin-top-p-40 {
        margin-top: 40px;
    }

    .xs-margin-left-10 {
        margin-left: 10px;
    }

    .xs-margin-left-20 {
        margin-left: 20px;
    }

    .xs-margin-top-p-70 {
        margin-top: 70px;
    }

    .xs-margin-top-neg-1 {
        margin-top: -1em;
    }

    .xs-margin-left-1 {
        margin-left: 1em;
    }

    .xs-margin-right-1 {
        margin-right: 1em;
    }

    .xs-margin-left-45p {
        margin-left: 45%;
    }

    .xs-margin-left-30p {
        margin-left: 30%;
    }

    .xs-margin-left-neg-1 {
        margin-left: -1em;
    }

    .xs-margin-left-neg-1-5 {
        margin-left: -1.5em;
    }

    .margin-left-none-xs {
        margin-left: 0px;
    }

    .margin-10-xs {
        margin-right: -10px !important;
        margin-left: -10px !important;
    }

    .margin-xs-top {
        margin-top: 10px;
    }

    .margin-xs-top-20 {
        margin-top: 20px;
    }

    .margin-xs-bottom-25 {
        margin-bottom: 25px;
    }

    .margin-top-4 {
        margin-top: 4px
    }

    .margin-top-xs-4 {
        margin-top: 4px
    }

    .font-xs-700 {
        font-weight: 700;
    }

    .heading-xs-h3 {
        font-size: 16px !important;
        font-weight: 700 !important;
    }

    .heading-xs-h4 {
        font-size: 30px !important;
    }

    .word-wrap-mobile {
        width: 75%;
        word-wrap: break-word;
    }

    .xs-modal-layout {
        left: 47% !important;
        width: 260px !important;
        height: 240px !important;
        margin-left: -120px;
        margin-right: -130px;
    }

    .padding-xs-15 {
        padding: 15px !important;
    }

    .xs-left-120 {
        left: 120px !important;
    }

    .xs-left-100 {
        left: 100px !important;
    }

    .xs-padding-bottom-0 {
        padding-bottom: 0px !important;
    }

    .accordion-open-bordered-xs h3::before {
        background: url("/static/img/accordion.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
        background-position: -250px -50px;
        background-color: white;
        content: " ";
        width: 34px;
        height: 34px;
        left: -10px;
        position: absolute;
        margin-left: 1em;
    }

    .accordion-open-bordered-xs h3 {
        padding: .2em 0 0 3em;
        color: $link-color;
        font-size: 1.5em;
        line-height: 2.1875em;
        margin-bottom: 8px;
        font-weight: 100 !important;
        margin-left: -10px;
    }

    table[onboard-table="yes"] {
        & tbody tr:nth-child(even) {
            background-color: rgba(228, 228, 228, 1) !important;
            padding-bottom: 10px;
        }

        & tbody tr:nth-child(odd) {
            background-color: rgba(242, 242, 242, 1) !important;
            padding-bottom: 10px;
        }

        & table,
        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }

        & thead tr {
            position: absolute;
            top: -99999px;
            left: -99999px;
        }

        & td {
            padding-left: 20px;
            font-weight: 400;
        }
    }

    table[onboard-table-allaccounts="yes"] {
        & tbody tr:nth-child(even) {
            background-color: rgba(228, 228, 228, 1) !important;
            padding-bottom: 10px;
        }

        & tbody tr:nth-child(odd) {
            background-color: rgba(242, 242, 242, 1) !important;
            padding-bottom: 10px;
        }

        & table,
        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }

        & thead tr {
            position: absolute;
            top: -99999px;
            left: -99999px;
        }

        & td {
            padding-left: 20px;
            font-weight: 400;
        }
    }

    .table-td-fixed-width td {
        display: block;
        clear: both;
        width: 100%;
    }

    .xs-padding-top-10 {
        padding-top: 10px;
    }
}


/* TABLET & DESKTOP */

@media screen and (min-width: 767px) {

    /* PIXEL PERFECT */
    .margin-md-except-top-p-10 {
        margin: 0 10px 10px 10px;
    }

    /******************************/
    .margin-md-1 {
        margin: 1em;
    }

    .margin-md-0 {
        margin: 0;
    }

    .margin-md-right-0 {
        margin-right: 0em;
    }

    .margin-md-right-1 {
        margin-right: 1em;
    }

    .margin-md-left-0 {
        margin-left: 0em;
    }

    .margin-md-left-0-5 {
        margin-left: 0.5em;
    }

    .margin-md-left-neg-1 {
        margin-left: -1em;
    }

    .margin-md-left-neg-1-5 {
        margin-left: -1.5em;
    }

    .margin-md-left-neg-3 {
        margin-left: -3em;
    }

    .margin-md-left-3 {
        margin-left: 3em;
    }

    .margin-md-left-4 {
        margin-left: 4em;
    }

    .margin-md-bottom-1 {
        margin-bottom: 1em;
    }

    .margin-md-top-1 {
        margin-top: 1em;
    }

    .margin-md-top-0 {
        margin-top: 0em;
    }

    .margin-md-top-imp-0 {
        margin-top: 0em !important;
    }

    .margin-md-top-7em {
        margin-top: 7em;
    }

    .margin-md-top-4em {
        margin-top: 4em;
    }

    .border-left-only {
        border-left: solid 2px #d5d5d5;
    }

    .border-5px {
        border: 5px solid $gray-20;
    }

    .border-2px {
        border: 2px solid;
    }

    .margin-md-top-1p {
        margin-top: 1px;
    }

    .margin-md-top-4p {
        margin-top: 4px;
    }

    .margin-md-top-2-5em {
        margin-top: 2.5em;
    }

    .margin-md-left-neg-20p {
        margin-left: -20px;
    }

    .margin-md-left-20p {
        margin-left: 20px;
    }

    .border-md-red {
        margin-top: -2px;
        padding-right: 0px !important;
        border: solid 2px #d8544c !important;
    }

    .ml-md-10 {
        margin-left: -0.625em;
    }
}


/****************************************/


/* FONT & LINE */

.bold {
    font-family: $default-font-family-bold;
}

.font-cerapro-medium {
    font-family: $default-font-family-medium;
}

.font-normal {
    font-weight: normal !important;
}

.font-weight-700 {
    font-weight: 700;
}

.font-size-12px {
    font-size: 12px !important;
}

.font-size-13px {
    font-size: 13px;
}

.font-size-p-14 {
    font-size: 14px !important;
}

.font-size-p-13 {
    font-size: 13.6px;
}

.font-size-p-24 {
    font-size: 24px;
}

.font-size-p-26 {
    font-size: 26px !important;
}

.font-size-p-22 {
    font-size: 22px !important;
}

.font-size-p-24-imp {
    font-size: 24px !important;
}

.font-size-p-20 {
    font-size: 20px !important;
}

.font-size-p-30 {
    font-size: 30px !important;
}

.font-size-p-40 {
    font-size: 40px !important;
}

.font-size-p-18 {
    font-size: 18px !important;
}

.font-size-p-64 {
    font-size: 64px !important;
}

.font-size-p-48 {
    font-size: 48px !important;
}

.font-size-1-5rem {
    font-size: 1.5rem !important;
}

.font-size-1-375rem {
    font-size: 1.375rem !important;
}

.font-size-1-75rem {
    font-size: 1.75rem !important;
}

.font-size-2-25rem {
    font-size: 2.25rem !important;
}

.font-size-3rem {
    font-size: 3rem !important;
}

.font-size-p-10 {
    font-size: 10px;
}

.line-height-2 {
    line-height: 2em;
}

.line-height-3 {
    line-height: 3em;
}

.line-height-3-5 {
    line-height: 3.5em;
}

.line-height-2-5 {
    line-height: 2.5em;
}

.line-height-1-4 {
    line-height: 1.4em;
}

.line-height-14 {
    line-height: 14px;
}

.line-leight-15 {
    line-height: 15px
}

.line-height-18 {
    line-height: 18px;
}

.line-height-inherit {
    line-height: inherit !important;
}

.line-height-28 {
    line-height: 28px !important;
}

.line-height-30 {
    line-height: 30px !important;
}

.line-height-36 {
    line-height: 36px !important;
}

.line-height-38 {
    line-height: 38px !important;
}

.line-height-40 {
    line-height: 40px !important;
}

.line-height-50 {
    line-height: 40px !important;
}

.line-height-72px {
    line-height: 72px !important;
}

.line-height-56px {
    line-height: 56px !important;
}

.line-height-24px {
    line-height: 24px !important;
}

.line-height-26px {
    line-height: 26px !important;
}

.line-height-21px {
    line-height: 21.6px !important;
}

.line-height-44px {
    line-height: 44px !important;
}

.height-1 {
    height: 1px;
}

.height-67 {
    height: 67px;
}

.height-35 {
    height: 35px !important;
}

.height-45 {
    height: 45px;
}

.height-335 {
    height: 335px;
}

.height-2em {
    height: 2em !important;
}

.height-11em {
    height: 11em;
}

.height-24 {
    height: 24px;
}

.height-25 {
    height: 25px;
}

.height-40 {
    height: 40px;
}

.height-49 {
    height: 49px;
}

.height-20 {
    height: 20px !important;
}

.height-125 {
    height: 125px !important;
}

.height-130 {
    height: 130px;
}

.height-147 {
    height: 147px;
}

.height-39 {
    height: 39px;
}

.height-fit-content {
    height: fit-content;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center !important;
}

.text-justify {
    text-align: justify !important;
}

.text-nowrap {
    white-space: nowrap;
}

.text-prewrap {
    white-space: pre-wrap;
}

.text-normal {
    white-space: normal;
}

.text-lowercase {
    text-transform: lowercase;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-capitalize {
    text-transform: capitalize;
}

.vertical-align-middle {
    vertical-align: middle;
}

.vertical-align-baseline {
    vertical-align: baseline;
}

.div-center-align {
    align-items: center;
}

.div-align-flex-end {
    align-items: flex-end;
}

.flex-direction-column {
    flex-direction: column;
}

input::placeholder {
    text-transform: none;
}

.text-center-DM {
    text-align: justify !important;
}

.dead-center {
    align-items: center;
    justify-content: center;
    display: flex;
}

.dead-end {
    justify-content: flex-end;
    display: flex;
}

.rotate-180 {
    transform: rotate(180deg);
}

/* MOBILE */

@media screen and (max-width: 767px) {
    .text-xs-left {
        text-align: left;
    }

    .text-xs-right {
        text-align: right;
    }

    .text-xs-center {
        text-align: center;
    }

    .text-xs-justify {
        text-align: justify;
    }

    .text-xs-nowrap {
        white-space: nowrap;
    }

    .text-xs-lowercase {
        text-transform: lowercase;
    }

    .text-xs-uppercase {
        text-transform: uppercase;
    }

    .text-xs-capitalize {
        text-transform: capitalize;
    }

    .vertical-xs-align-middle {
        vertical-align: middle;
    }

    .vertical-xs-align-top {
        vertical-align: top;
    }

    .text-center-DM {
        text-align: center !important;
    }
}


/* TABLET & DESKTOP */

@media screen and (min-width: 767px) {
    .text-md-left {
        text-align: left;
    }

    .text-md-right {
        text-align: right;
    }

    .text-md-center {
        text-align: center;
    }

    .text-md-justify {
        text-align: justify;
    }

    .text-md-nowrap {
        white-space: nowrap;
    }

    .text-md-lowercase {
        text-transform: lowercase;
    }

    .text-md-uppercase {
        text-transform: uppercase;
    }

    .text-md-capitalize {
        text-transform: capitalize;
    }

    .vertical-md-align-middle {
        vertical-align: middle;
    }
}


/* ELLIPSIS */

.overflow-visible {
    overflow: visible !important;
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    //white-space: nowrap;
    text-align: left;
}

.text-overflow-ellipsis {
    text-overflow: ellipsis;
}

.second-line-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}


/* TABLET & DESKTOP */

@media screen and (min-width: $screen-mobile) {
    .truncate-md {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .md-top-p-8 {
        top: 8px;
    }
}


/****************************************/


/* POSITION */

.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

.bottom-1 {
    bottom: 1em;
}

.bottom-1-5 {
    bottom: -1.5em;
}

.right-0 {
    right: 0;
}

.right-3 {
    right: 3%;
}

.right-2 {
    right: 2% !important;
}

.right-5 {
    right: 5%;
}

.top-5 {
    top: 5px;
}

.top-7 {
    top: 7px !important;
}


/****************************************/


/* DISPLAY */

.display-block {
    display: block;
}

.display-inline {
    display: inline;
}

.display-inline-block {
    display: inline-block !important;
}

.display-contents {
    display: contents;
}

.hide {
    display: none !important;
}

.show {
    display: block !important;
}

.invisible {
    visibility: hidden;
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.hidden {
    display: none !important;
}

.affix {
    position: fixed;
}

.nowrap {
    white-space: nowrap;
}


/* MOBILE */

@media screen and (max-width: 767px) {
    .display-xs-block {
        display: block;
    }
}


/****************************************/


/* PADDING */

.pad-0 {
    padding: 0px;
}

.padding-2px {
    padding: 2px !important;
}

.pad-0-imp {
    padding: 0px !important;
}

.pad-top-p-2 {
    padding-top: 2px;
}

.pad-top-p-10 {
    padding-top: 10px !important;
}

.pad-top-p-12 {
    padding-top: 12px;
}

.pad-top-p-11 {
    padding-top: 11px;
}

.pad-top-p-20 {
    padding-top: 20px;
}

.padding-top-24px {
    padding-top: 24px;
}

.pad-bottom-0 {
    padding-bottom: 0em;
}

.pad-bottom-3 {
    padding-bottom: 3px !important;
}

.pad-bottom-3-5rem {
    padding-bottom: 3.5rem;
}

.pad-bottom-15 {
    padding-bottom: 15px !important;
}

.pad-bottom-13px {
    padding-bottom: 13px !important;
}

.pad-bottom-0-imp {
    padding-bottom: 0em !important;
}

.pad-bottom-22-imp {
    padding-bottom: 22px !important;
}

.pad-bottom-25-imp {
    padding-bottom: 25px !important;
}

.pad-bottom-28-imp {
    padding-bottom: 28px !important;
}

.padding-bottom-11 {
    padding-bottom: 11px !important;
}

.padding-bottom-8 {
    padding-bottom: 8px !important;
}

.pad-bottom-31 {
    padding-bottom: 31px !important;
}

.pad-bottom-1-2 {
    padding-bottom: 1.2em;
}

.pad-bottom-30 {
    padding-bottom: 30px !important;
}

.pad-bottom-40 {
    padding-bottom: 40px !important;
}

.text-color-malp {
    color: #202020 !important;
}

.pad-right-0 {
    padding-right: 0em;
}

.pad-right-3em {
    padding-right: 3em !important;
}

.pad-right-4em {
    padding-right: 4em !important;
}

.pad-right-4-5-imp {
    padding-right: 4.5em !important;
}

.pad-right-2em {
    padding-right: 2em !important;
}

.pad-right-2-5em {
    padding-right: 2.5em;
}

.pad-left-0 {
    padding-left: 0em;
}

.pad-left-9 {
    padding-left: 9px;
}

.pad-left-7-5 {
    padding-left: 7.5px;
}

.pad-right-6-em {
    padding-right: 6em;
}

.pad-right-p-5 {
    padding-right: 0.32rem;
}

.pad-right-p-0-7-imp {
    padding-right: 0.7rem !important;
}

.pad-right-1-875rem {
    padding-right: 1.875rem;
}

.pad-right-1rem {
    padding-right: 1rem;
}

.pad-right-5rem {
    padding-right: 5rem !important;
}

.pad-top-8rem {
    padding-top: 8rem;
}

.pad-bottom-8rem {
    padding-bottom: 8rem;
}

.pad-left-0-imp {
    padding-left: 0em !important;
}

.pad-left-0 {
    padding-left: 0px !important;
}

.pad-right-0 {
    padding-right: 0px !important;
}

.pad-left-0-5 {
    padding-left: 0.5em;
}

.pad-left-0-4 {
    padding-left: 0.4em;
}

.pad-left-0-4rem {
    padding-left: 0.4rem !important;
}

.pad-left-0-8 {
    padding-left: 0.8em;
}

.pad-left-0-8-imp {
    padding-left: 0.8em !important;
}

.pad-top-0 {
    padding-top: 0em;
}

.pad-top-0-imp {
    padding-top: 0em !important;
}

.padding-top-1-8em {
    padding-top: 1.8em !important;
}

.pad-1 {
    padding: 1em;
}

.pad-right-0 {
    padding-right: 0em;
}

.pad-right-1 {
    padding-right: 1em !important;
}

.pad-right-1-75 {
    padding-right: 1.75em;
}

.pad-right-2em {
    padding-right: 2em !important;
}

.pad-left-1 {
    padding-left: 1em !important;
}

.pad-left-1-1 {
    padding-left: 1.1em !important;
}

.pad-left-1-2 {
    padding-left: 1.2em !important;
}

.pad-bottom-1 {
    padding-bottom: 1em;
}

.pad-bottom-2-188rem {
    padding-bottom: 2.188rem;
}

.pad-top-2-188rem {
    padding-top: 2.188rem;
}

.pad-top-1 {
    padding-top: 1em;
}

.pad-left-1-5 {
    padding-left: 1.5em !important;
}

.pad-right-1-5 {
    padding-right: 1.5em !important;
}

.pad-left-2 {
    padding-left: 2em;
}

.pad-left-2-2 {
    padding-left: 2.2em !important;
}

.pad-left-2-5 {
    padding-left: 2.5em;
}

.pad-left-3 {
    padding-left: 3em;
}

.pad-left-3-imp {
    padding-left: 3em !important;
}

.pad-left-4-imp {
    padding-left: 4em !important;
}

.pad-left-4-5-imp {
    padding-left: 4.5em !important;
}

.pad-left-3-5 {
    padding-left: 3.5em;
}

.pad-left-6 {
    padding-left: 6em;
}

.pad-left-8-25 {
    padding-left: 8.25em;
}

.pad-bottom-2 {
    padding-bottom: 2em;
}

.pad-top-2 {
    padding-top: 2em;
}

.pad-top-0-5 {
    padding-top: 0.5em;
}

.pad-top-0-0-5 {
    padding-top: 0.05rem;
}

.pad-top-30 {
    padding-top: 30px;
}

.pad-top-10 {
    padding-top: 10px;
}

.pad-top-6-imp {
    padding-top: 6px !important;
}

.pad-top-4-em {
    padding-top: 4em;
}

.pad-5px {
    padding: 5px;
}

.pad-bottom-0-5 {
    padding-bottom: 0.5em !important;
}

.pad-bottom-p-6-imp {
    padding-bottom: 6px !important;
}

.pad-bottom-p-10 {
    padding-bottom: 10px;
}

.pad-bottom-p-20 {
    padding-bottom: 20px;
}

.pad-bottom-p-20-imp {
    padding-bottom: 20px !important;
}

.pad-right-p-10 {
    padding-right: 10px !important;
}

.padding-right-8 {
    padding-right: 8px !important;
}

.padding-right-1 {
    padding-right: 1px !important;
}

.padding-left-8 {
    padding-left: 8px !important;
}

.padding-left-4 {
    padding-left: 4px !important;
}

.padding-left-28 {
    padding-left: 28px !important;
}

.pad-left-p-10 {
    padding-left: 10px !important;
}

.pad-left-p-17 {
    padding-left: 17px;
}

.pad-left-p-15 {
    padding-left: 15px;
}

.pad-left-p-15-imp {
    padding-left: 15px !important;
}

.pad-left-p-14 {
    padding-left: 14px;
}

.pad-top-neg-p-10 {
    padding-left: -10px;
}

.pad-left-neg-p-7 {
    padding-left: -7px;
}

.pad-left-bottom-1 {
    padding-left: 1em;
    padding-bottom: 1em;
}

.pad-right-top-1 {
    padding-right: 1em;
    padding-top: 1em;
}

.pad-right-p-20 {
    padding-right: 20px;
}

.pad-right-40 {
    padding-right: 40px !important;
}

.padding {
    padding: 15px !important;
}

.padding-12px {
    padding-right: 12px !important;
    padding-left: 12px !important;
}

.padding-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
}

.padding-250 {
    padding-top: 250px !important;
    padding-bottom: 250px !important;
}

.padding-12 {
    padding: 12px;
}

.padding-1px {
    padding: 1px !important;
}

.padding-8-0-5 {
    padding: 8px 0px 5px 8px !important;
}

.pb-5px {
    padding-bottom: 5px;
}

.padding-20{
    padding: 20px !important;
}

.gap-20{
    gap: 20px !important;
}

.width-19{
    width: 19rem !important;
}

.height-13{
    width: 13rem !important;
}

.height-11-5{
    height: 11.5rem !important;
}

.margin-bottom-120px {
    margin-bottom: 120px;
}


/* MOBILE */

@media screen and (max-width: 767px) {
    .pad-xs-0 {
        padding: 0px;
    }

    .pad-xs-left-0 {
        padding-left: 0em;
    }

    .pad-xs-left-0-imp {
        padding-left: 0em !important;
    }

    .pad-xs-left-2-imp {
        padding-left: 2em !important;
    }

    .pad-xs-left-7-imp {
        padding-left: 7em !important;
    }

    .pad-xs-right-0-imp {
        padding-right: 0em !important;
    }

    .pad-top-0-5-xs {
        padding-top: 0.5em;
    }

    .pad-xs-left-1 {
        padding-left: 1em;
    }

    .pad-xs-right-0 {
        padding-right: 0em;
    }

    .pad-xs-right-1 {
        padding-right: 1em;
    }

    .pad-xs-bottom-1 {
        padding-bottom: 1em;
    }

    .pad-xs-p-10 {
        padding: 10px;
    }

    .pad-xs-top-p-20 {
        padding-top: 20px;
    }

    .pad-xs-top-1 {
        padding-top: 1em;
    }

    .pad-xs-top-6 {
        padding-top: 6em;
    }

    .margin-left-neg-30 {
        margin-left: 0px;
    }
}


/* TABLET & DESKTOP */

@media screen and (min-width: 768px) and (max-width: 959px) {
    .pad-left-sm-7-px {
        padding-left: 7px;
    }
}


/* TABLET & DESKTOP */

@media screen and (min-width: 767px) {

    /* PIXEL PERFECT */
    .pad-md-p-10 {
        padding: 10px;
    }

    .pad-md-bottom-p-5 {
        padding-bottom: 5px;
    }

    .pad-md-right-p-10 {
        padding-right: 10px;
    }

    .pad-left-lg-25 {
        padding-left: 25px;
    }

    .pad-md-left-p-10 {
        padding-left: 10px;
    }

    .pad-left-md-25 {
        padding-left: 2.5em !important;
    }

    /*****************/
    .pad-md-0 {
        padding: 0;
    }

    .pad-md-1 {
        padding: 1em;
    }

    .pad-md-right-1 {
        padding-right: 1em;
    }

    .pad-md-left-1 {
        padding-left: 1em;
    }

    .pad-md-right-0 {
        padding-right: 0em;
    }

    .pad-md-right-imp-0 {
        padding-right: 0em !important;
    }

    .pad-md-left-0 {
        padding-left: 0em;
    }

    .pad-md-left-imp-0 {
        padding-left: 0em !important;
    }

    .pad-top-md-50p {
        padding-top: 50%
    }

    .pad-top-md-35p {
        padding-top: 35%
    }

    .pad-top-md-25p {
        padding-top: 25%
    }

    .pad-top-md-5p {
        padding-top: 5px;
    }

    .pad-bottom-md-25p {
        padding-bottom: 25%
    }

    .pad-bottom-md-28p {
        padding-bottom: 28%
    }

    .pad-md-top-1 {
        padding-top: 1em;
    }

    .pad-md-top-12 {
        padding-top: 12em;
    }

    .pad-md-bottom-1 {
        padding-bottom: 1em;
    }

    .pad-md-bottom-2 {
        padding-bottom: 2em;
    }

    .pad-md-bottom-10p {
        padding-bottom: 10px;
    }

    .bottom-md-3-5 {
        bottom: -3.5em;
    }

    .bottom-md-4 {
        bottom: -4em;
    }

    .margin-md-top-25 {
        margin-top: 25px;
        margin-bottom: 30px;
    }

    .margin-top-6 {
        margin-top: 6px !important;
    }

    .margin-md-left-imp-10 {
        margin-left: 10px !important;
    }

    .height-md-p-686 {
        height: 686px;
    }

    .height-md-700p {
        height: 700px;
    }

    .pad-md-0-imp {
        padding: 0 !important;
    }

    .margin-top-md-25 {
        margin-top: 25px;
    }

    .margin-md-top-2em {
        margin-top: 2em;
    }
}


/****************************************/


/* FLOAT */

.pull-right {
    float: right !important;
}

.pull-right-bottom {
    right: 15px;
    bottom: 15px;
    position: absolute;
}

.pull-left {
    float: left !important;
}

.float-none {
    float: none ! important;
}


/* MOBILE */

@media screen and (max-width: 767px) {
    .pull-xs-right {
        float: right !important;
    }

    .pull-xs-left {
        float: left !important;
    }
}


/* TABLET & DESKTOP */

@media screen and (min-width: 767px) {
    .pull-md-right {
        float: right !important;
    }

    .pull-md-left {
        float: left !important;
    }
}


/****************************************/


/* WIDTH */
.wide-auto {
    width: auto !important;
}

.wide100 {
    width: 100% !important;
}

.wide95 {
    width: 95% !important;
}

.wide90 {
    width: 90% !important;
}

.wide91 {
    width: 91% !important;
}

.wide-80 {
    width: 80%;
}

.wide85 {
    width: 85% !important;
}

.wide70 {
    width: 70% !important;
}

.wide75 {
    width: 75% !important;
}

.wide50 {
    width: 50% !important;
}

.width-58 {
    width: 58% !important;
}

.width-60 {
    width: 60% !important;
}

.width-65 {
    width: 65% !important;
}

.wide12 {
    width: 12% !important;
}

.wide-50px {
    width: 50px;
}

.wide-250px {
    width: 250px;
}

.width-35px {
    width: 35px;
}

.width-1 {
    width: 1em !important;
}

.width-2 {
    width: 2em !important;
}

.width-3 {
    width: 3em !important;
}

.width-3-75 {
    width: 3.75rem !important;
}

.width-4 {
    width: 4em !important;
}

.width-5 {
    width: 5em !important;
}

.width-6 {
    width: 6em !important;
}

.width-6-75 {
    width: 6.75em !important;
}

.width-8 {
    width: 8em !important;
}

.width-9 {
    width: 9em !important;
}

.width-10 {
    width: 10em !important;
}

.width-11 {
    width: 11em !important;
}

.width-12 {
    width: 12em !important;
}

.width-13 {
    width: 13em !important;
}

.width-14 {
    width: 14em !important;
}

.width-14-25 {
    width: 14.25em !important;
}

.width-14-5 {
    width: 14.5em !important;
}

.width-15 {
    width: 15em !important;
}

.width-17 {
    width: 17em !important;
}

.width-18 {
    width: 18em !important;
}

.width-19 {
    width: 19em !important;
}

.width-21 {
    width: 21em !important;
}

.width-25 {
    width: 25em !important;
}

.width-26-5 {
    width: 26.5em !important;
}

.width-28 {
    width: 28em !important;
}

.max-width-25 {
    max-width: 25em !important;
}

.wide-27-5rem {
    width: 27.5rem !important;
}

.wide-19rem {
    width: 19rem;
}

.width-17-rem {
    width: 17rem !important;
}

.width-18-rem {
    width: 18rem !important;
}

.width-64px {
    width: 64px !important;
}

.width-55px {
    width: 55px !important;
}

.width-48px {
    width: 48px !important;
}

.max-width-93 {
    max-width: 93%;
}

.width-auto {
    width: auto !important;
}

.width-webkit-fill {
    width: -webkit-fill-available;
}

.width-moz-available {
    width: -moz-available;
}

.wide-70px {
    width: 70px !important;
}

.wide-16em {
    width: 16em !important;
}

.wide-25px {
    width: 25px
}

.wide-35px {
    width: 35px
}

.wide-155px {
    width: 155px
}

.wide-110px {
    width: 110px !important;
}

.width-12-rem {
    width: 12rem;
}

.width-9-rem {
    width: 9rem;
}

.width-16-rem {
    width: 16rem;
}

.width26percent {
    width: 26% !important;
}

.w-40 {
    width: 40%;
}

.w-78 {
    width: 78%;
}

.width-228-px {
    min-width: 228px;
    max-width: 228px;
}


.height-width-59-49px {
    height: 59px;
    width: 49px;
}

.pad-left-20 {
    padding-left: 20px;
}

.pad-left-25 {
    padding-left: 25px !important;
}

.pad-right-25 {
    padding-right: 25px !important;
}


/* MOBILE */

@media screen and (max-width: 767px) {
    .wide-xs-100 {
        width: 100%;
    }

    .wide25-xs {
        width: 25% !important;
    }

    .wide33-xs {
        width: 33% !important;
    }

    .wide95-xs {
        width: 95% !important;
    }

    .width-xs-25-06em {
        width: 25.063em
    }
}


/* TABLET & DESKTOP */

@media screen and (min-width: 767px) {
    .wide49-md {
        width: 49% !important;
    }

    .wide10-md {
        width: 10% !important;
    }

    .wide5-md {
        width: 5% !important;
    }

    .wide35-md {
        width: 35% !important;
    }

    .wide78-md {
        width: 78% !important;
    }

    .wide-md-90px {
        width: 90px !important;
    }

    .width-md-32-12em {
        width: 32.125em;
    }

    .width-md-650p {
        width: 650px;
    }

    .width-md-36em {
        width: 36em;
    }

    .width-md-37-5em {
        width: 37.5em;
    }

    .flex-box-md {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
    }
}


/****************************************/


/* BORDERS */

.gray-border-bottom-1 {
    border-bottom: 1px solid $gray;
}

.gray-border-top-1 {
    border-top: 1px solid $gray;
}

.white-border-top-1 {
    border-top: 1px solid $white;
}

.gray-border-left-1 {
    border-left: 1px solid $gray;
}

.gray-border-right-1 {
    border-right: 1px solid $gray;
}

.gray-border-thick-bottom {
    border-bottom: 1px solid #AAAAAA;
}

.gray-border-1 {
    border: 1px solid $gray;
}

.light-gray-border-top {
    border-top: 1px solid #d3d3d3;
}

.light-gray-border-bottom {
    border-bottom: 1px solid #d3d3d3;
}

.border-none {
    border: none !important;
}

.border-top-none {
    border-top: none !important;
}

.border-top-none {
    border-top: none !important;
}

.border-black {
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.black-border-1 {
    border: 1px solid $black;
    border-radius: 2px;
}

.border-collapse {
    border-collapse: collapse;
}

/* MOBILE */

@media screen and (max-width: 767px) {
    .gray-xs-border-bottom-1 {
        border-bottom: 1px solid $gray;
    }

    .gray-xs-border-top-1 {
        border-top: 1px solid $gray;
    }

    .gray-xs-border-1 {
        border: 1px solid $gray;
    }
}


/************************************/


/* HEIGHT */

.height200 {
    height: 200px;
}

.height132 {
    height: 132px;
}

.height-64px {
    height: 64px !important;
}

.height-55px {
    height: 55px !important;
}

.height-48px {
    height: 48px !important;
}

.height-41-5px {
    height: 41.5px !important;
}

.height-100p {
    height: 100%;
}

.height-235px {
    height: 235px;
}


/* TABLET & DESKTOP */

@media screen and (min-width: $screen-mobile) {
    .tall-md-5 {
        height: 5em;
    }

    .tall-md-200-p {
        height: 200px !important;
    }

    .gray-border-left-md-1 {
        border-left: 1px solid $gray;
    }

    .gray-border-right-md-1 {
        border-right: 1px solid $gray;
    }
}

.min-height-140p {
    min-height: 140px !important;
}

.min-height-125p {
    min-height: 125px !important;
}

.min-height-65p {
    min-height: 65px !important;
}

.min-height-200p {
    min-height: 200px;
}

.min-height-300p {
    min-height: 300px;
}

.min-height-200p {
    min-height: 200px;
}

.min-height-800p {
    min-height: 800px;
}

.height-100px {
    height: 100px;
}

.height-55px {
    height: 55px;
}


/************************************/


/* IMAGE */

img.centered {
    display: block;
    margin: auto;
    position: static;
}


/************************************/


/* TEXT FORMAT - WHITESPACE */

.preformatted-text {
    white-space: pre;
}

.preformatted-text-wrap {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
}

h3[data-icon] {
    &:before {
        content: "";
        left: 10px;
        top: -5px;
        position: absolute;
    }

    padding-left: 2em;
    margin-bottom: 30px
}

.ellipsis-left {
    width: 40%;
    float: left;
}

.top-2 {
    top: 2em;
}

.top-1 {
    top: -1em;
}

.top-2em {
    top: -2em;
}

.top-113 {
    top: 113%;
}

.top-109 {
    top: 109%;
}

.top-12em {
    top: -1.2em !important;
}

.modal-layout-content {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    opacity: 0.6;
}

.modal-layout {
    z-index: 1001;
    display: none;
    position: fixed;
    top: 40%;
    left: 30%;
    width: 500px;
    height: 200px;
    border: 0px solid;
    border-width: 1px;
    background-color: rgba(255, 255, 255, 1);
    border-color: rgba(204, 204, 204, 1);
}

.po-modal-layout {
    width: 600px;
    height: auto !important;
}

.pad-top-bottom-adjust {
    padding-top: 0.5em !important;
    padding-bottom: 1em !important;
}

.modal-header-layout {
    padding: 10px 5px;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
    border: 1px solid;
    border-left: 0px;
    border-right: 0px;
    border-color: rgba(255, 204, 0, 1);
    background-color: rgba(254, 251, 244, 1);
}

.modal-header-msg {
    color: #666666;
}

.modal-text {
    color: #252525;
}

.modal-buttons {
    cursor: pointer;
    z-index: 1;
}

.modal-button-img {
    background: url(/static/img/blueprint/createprofile/button.png) no-repeat;
    background-size: 70px 40px;
    float: right;
    width: 70px;
    height: 40px;
    cursor: pointer;
    border: none;
}

.modal-button-xs-img {
    background: url(/static/img/blueprint/createprofile/mobilebutton.png) no-repeat;
    cursor: pointer;
    height: 40px;
    border-radius: 5px;
}

.modal-buttons-text {
    position: relative;
    color: #ffffff;
    text-align: center;
    top: 10px;
    left: 25px;
    font-size: 17px;
    font-weight: 600;
    // font-family: "open_sanssemibold", Arial, Helvetica, Sans-serif;
}

.normal-dropdown {
    border: 1px solid black !important;
    background: white !important;
    -webkit-appearance: menulist !important;
}

.onboarding-xs-box {
    margin-left: 30px;
    margin-right: 30px;
}

.onboarding-col-xs-5 {
    width: 20% !important;
    padding: 0px !important;
}

.margin-left-neg-10 {
    margin-left: -10px;
}

.margin-left-neg-20 {
    margin-left: -20px;
}

.margin-left-neg-13 {
    margin-left: -13px !important;
}

.text-color-blue {
    color: $link-color !important;
}

.text-font-16 {
    font-size: 16px
}

.text-font-36 {
    font-size: 36px !important;
}

.text-bold-600 {
    font-weight: 600;
}

.text-bold-400 {
    font-weight: 400;
}

.text-color-grey {
    color: #979798;
}

.text-color-gray-contrast {
    color: #737373;
}

.text-color-red-contrast {
    color: #D23A32;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-115 {
    margin-top: 115px;
}

.margin-top-8 {
    margin-top: 8px;
}

.offset-height-50 {
    height: 50px;
}

.margin-botton-20px {
    margin-bottom: 20px;
}

.margin-botton-30px {
    margin-bottom: 30px;
}

.display-inline-table {
    display: inline-table !important;
}

.warning-layout {
    border: 1px solid green;
    border-left-width: 0px;
    border-right-width: 0px;
    background-color: rgba(237, 247, 251, 1);
    border-color: rgba(17, 140, 205, 1);
    padding: 10px;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #666666;
}

.managee911-select-box {
    border: 1px solid;
    height: 120px;
    vertical-align: middle;
    line-height: 120px !important;
    box-shadow: none;
}

.text-disabled-color {
    color: rgb(151, 151, 152) !important;
}

.text-enabled-color {
    color: rgb(6, 121, 202) !important;
}

.background-disabled-color {
    background-color: rgba(218, 218, 220, 1);
}

.border-disabled-color {
    border-color: rgba(204, 204, 204, 1);
    pointer-events: none;
}

.border-enabled-color {
    border-color: rgba(154, 154, 154, 1);
    cursor: pointer;
}

.margin-left-30 {
    margin-left: 30px !important;
}

.font-size-20 {
    font-size: 20px;
}

.font-size-21 {
    font-size: 21px !important;
}

.margin-top-50 {
    margin-top: 50px !important;
}

.margin-top-20 {
    margin-top: 20px !important;
}

.margin-top-25 {
    margin-top: 25px !important;
}

.margin-top-mobile-25 {
    margin-top: 25px !important;
}

.margin-top-30 {
    margin-top: 30px !important;
}

.margin-top-32 {
    margin-top: 32px !important;
}

.margin-top-75 {
    margin-top: 75px !important;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-botton-30p-imp {
    margin-bottom: 30px !important;
}

.margin-right-25 {
    margin-right: 25px;
}

.pad-top-60 {
    padding-top: 60px;
}

.padding-top-27 {
    padding-top: 27px !important;
}

.margin-zero {
    margin: 0px !important;
}

.float-left {
    float: left !important;
}

.float-clear-both {
    clear: both;
}

.managee911-desktop-layout {
    display: table-cell;
    width: 215px;
    height: 170px;
}

.margin-bottom-2 {
    margin-bottom: 2px !important;
}

.margin-bottom-20 {
    margin-bottom: 20px !important;
}

.margin-bottom-20p {
    margin-bottom: 20%
}

.margin-bottom-22 {
    margin-bottom: 22px
}

.margin-bottom-25 {
    margin-bottom: 25px !important;
}

.margin-bottom-2375 {
    margin-bottom: 2.375rem;
}

.margin-bottom-60px {
    margin-bottom: 60px;
}

.margin-bottom-70px {
    margin-bottom: 70px;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-13 {
    margin-left: 13px !important;
}

.margin-left-14point5 {
    margin-left: 14.5px !important;
}

.margin-top-2 {
    margin-top: 2px !important
}

.display-table {
    display: table;
    vertical-align: middle;
}

.managee911-select-box:hover {
    background-color: #03406a;
    color: white;
}

.margin-left-20 {
    margin-left: 20px !important;
}

.margin-left-modify-20 {
    margin-left: 20px !important;
}

.margin-left-25 {
    margin-left: 25px !important
}

.margin-left-modify-74px {
    margin-left: 74px !important
}

.updatehelpicon {
    margin-top: 4px !important;
    margin-left: 0px !important;
}

.margin-left-4 {
    margin-left: 4px !important;
}

.margin-left-22 {
    margin-left: 22px !important;
}

.margin-left-12 {
    margin-left: 12px !important;
}

.margin-left-30 {
    margin-left: 30px;
}

.margin-left-31 {
    margin-left: 31px !important;
}

.margin-left-44px {
    margin-left: 44px !important;
}

.margin-left-neg-26em {
    margin-left: -26.2em !important;
}

.padding-30 {
    padding: 30px;
}

.margin-bottom-10 {
    margin-bottom: 10px !important;
}

.margin-bottom-16 {
    margin-bottom: 16px;
}

.margin-bottom-0 {
    margin-bottom: 0px;
}

.margin-top-10 {
    margin-top: 10px !important;
}

.margin-top-13imp {
    margin-top: 13px !important;
}

.border-color-lightgrey {
    border-color: rgb(169, 169, 169) !important;
    border-radius: 0px;
}

.p-margin-override {
    margin-bottom: 0px;
    margin-top: 0px;
}

.tr-top-text {
    vertical-align: text-bottom
}

.table-td-fixed-width {
    table-layout: fixed;
    width: 100%;
}

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

.pad-right-0 {
    padding-right: 0px !important;
}

.width-150 {
    width: 150px !important;
}

.width-120 {
    width: 120px !important;
}

.width-96 {
    width: 96% !important;
}

.width-320 {
    width: 320px !important;
}

.width-300 {
    width: 300px !important;
}

.width-150 {
    width: 150px !important;
}

.pad-top-bottom-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

table[onboard-table="yes"] {

    & th:nth-of-type(1),
    th:nth-of-type(5) {
        width: 50px !important;
    }

    & th:nth-of-type(4) {
        width: 9em !important;
    }
}

table[onboard-table-allaccounts="yes"] {

    & th:nth-of-type(1),
    th:nth-of-type(5) {
        width: 181px !important;
    }

    & th:nth-of-type(4) {
        width: 9em !important;
    }
}

span[tooltip-text]:hover:after {
    content: attr(tooltip-text);
    padding: 8px 8px;
    color: white;
    position: absolute;
    left: -60px;
    bottom: 120%;
    width: 275px;
    height: auto;
    background: $link-color;
    border-radius: 10px;
}

.help-tooltip:hover {
    position: relative;
}

.header-progress {
    background-image: url('/static/img/blueprint/createprofile/sprite-ob-icons.png');
    background-position-x: center !important;
    width: 100%;
    height: 120px;
    background-repeat: no-repeat;
    margin-top: 15px;
}

.xs-header-progress {
    height: 80px;
}

.md-ob-create-profile {
    background-position: 0px 0px;
}

.md-ob-add-account {
    background-position: 0px -375px;
}

.md-ob-create-profile-add-user {
    background-position: 0px -125px;
}

.md-ob-add-account-add-user {
    background-position: 0px -500px;
}

.md-ob-create-profile-setup-billing {
    background-position: 0px -250px;
}

.md-ob-add-account-setup-billing {
    background-position: 0px -628px;
}

.xs-ob-create-profile {
    background-position: 0px -748px;
}

.xs-ob-add-user {
    background-position: 0px -820px;
}

.xs-ob-setup-billing {
    background-position: 0px -900px;
}

.onboarding-main-header {
    background: linear-gradient(#045690, $link-color) rgba(0, 0, 0, 0);
    margin: 0;
    width: 200%;
    background-color: #045690;
    margin-left: -52%;
    color: #fff;
    height: 75px;
}

.word-wrap-break {
    word-wrap: break-word !important;
}

.hyper-link {
    cursor: pointer;
    color: $link-color;
    padding-top: 25px;
    padding-bottom: 10px;
    text-decoration: underline !important;
}

.link {
    color: $cyan-blue;
    cursor: pointer;
}

.modalbody {
    margin-top: -10px;
}

.tooltip-padding {
    padding-left: 0px;
    padding-top: 5px;
}

.border-width-0 {
    border-width: 0px !important;
}

#ppb-statement-list-table_data tr,
#ppb-statement-list-table_head th {
    border-top: 0px solid transparent !important;
    border-bottom: 0px solid transparent !important;
}

body {
    overflow-x: hidden;
}


/* TABLET & DESKTOP */

@media screen and (min-width: $screen-mobile) {
    .trucateradio911-md {
        width: 110%;
        float: left;
    }
}


/* TABLET & DESKTOP search bar*/

@media screen and (min-width: 875px) {
    .searchbard {
        margin-top: 22px;
        margin-left: 20px;
        width: 11em !important;
        margin-right: -550px;
        height: 40px;
        border: 1px solid #979798;
        background-color: #fff;
    }

    .margin-top-neg-75 {
        margin-top: -75px;
    }
}

@media screen and (max-width: $screen-xs) {
    .searchbarm {
        margin-bottom: -1px !important;
        margin-top: 10px;
        width: 96% !important;
        height: 40px;
        border: 1px solid #979798;
        background-color: #fff;
    }
}

@media screen and (min-width: 767px) {
    .manageaccounttable-md {
        width: 892px;
        table-layout: fixed;
    }
}

@media screen and (max-width: 767px) {
    .manageaccounttable-xs {
        width: 100%;
    }

    .pad-left-right-2-xs {
        padding-left: 2em;
        padding-right: 2em;
    }

    .pad-xs-left-p-15 {
        padding-left: 15px;
    }

    .pad-xs-top-10 {
        padding-top: 10px;
    }

    .pad-xs-top-20 {
        padding-top: 15px;
    }

    .margin-xs-right-p-2 {
        margin-right: 2px !important;
    }

    .margin-xs-right-p-5 {
        margin-right: 5px !important;
    }

    .mob-margin-top-12 {
        margin-top: 12px;
    }
}

.billingscreenshot {
    width: 400px;
    height: 345px;
    border: 1px solid #ccc;
}

.zoomicon {
    float: right;
    margin-right: -10px;
    margin-top: -60px;

    & img {
        width: 60px;
        height: 60px;
    }
}

.billingscreenshot-modal {
    text-align: center;

    & img {
        width: 560px;
        border: 1px solid #ccc;
    }
}

.zoominfo {
    padding-top: 1em;
    font-size: 16px;
    font-style: italic;
}

.pad-20px {
    padding: 20px 20px 0px 20px !important;
}

.voicetoolsbackground {
    background: #edf7fb;
    border-radius: 10px;
}

.vertical-align-top {
    vertical-align: top;
}

.vertical-align-text-top {
    vertical-align: text-top;
}

.vertical-align-sub {
    vertical-align: sub;
}

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-5 {
    padding-top: 5px;
}

.margin-top-p-1 {
    margin-top: 1px;
}

.bg-color-silver {
    background-color: #f2f2f2;
}

.margin-10p {
    margin: 10px !important;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.gotostyle {
    color: #000000;
    font-size: 14px;
}

.gotoinputstyle {
    width: 25% !important;
    height: 30px;
    margin-left: 4px;
    padding-left: 4px;
}

.gotodiv {
    float: right;
    margin-left: 15%;
    margin-top: 10px;
}

.word-break {
    word-break: break-all;
}

.h2 {
    font-family: inherit;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
    display: block;
    -webkit-margin-before: 0.83em;
    -webkit-margin-after: 0.83em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
}

.h1 {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
}

.ORstyle {
    font-size: .875em;
    text-align: center;
    color: #999;
    width: 40px;
    height: 40px;
    position: absolute;
    // left: 266px;
    // top: 48.5px;
    border: 2px solid #ddd;
    background-color: #ffffff;
    line-height: 33px;
    border-radius: 20px;
}

.hr {
    clear: both;
    width: 100%;
    background-color: #d8d8d0;
    height: 1px;
    padding-bottom: -2px;
    margin-bottom: 10px;
    border: none;
}

.assignstyle {
    margin-right: 8px;
    font-weight: bold;
    line-height: 1.8em !important;
}

.mobile_consent {
    margin-top: -2em;
    margin-bottom: 2em;
}

.clear_both {
    clear: both !important;
}

.gotoLink {
    color: $link-color;
    padding-left: 15px;
    // font-family: "open_sanssemibold", Arial, Helvetica, Sans-serif;
    font-size: 0.875em;
}

.addOnButton {
    border: solid 1px $black;
    margin: 0px 10px !important;
    display: inline;
    padding: 10px 16px 10px 16px !important;
    font-weight: 700;
    border-radius: 3px;
    color: $cyan-blue;
}

.border-radius-0px {
    border-radius: 0px;
}

.addOnIncrementButton {
    // font-family: "open_sanssemibold", Arial, Helvetica, Sans-serif;
    font-size: 1.063em;
    color: $white;
    text-align: center;
    display: inline-block;
    overflow: visible;
    border: 1px solid $nav-tab-color;
    border-radius: 0px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background-color: $gray-modal-header;
    cursor: pointer;
    width: 41px;
    height: 40px;
    margin: 1em 0.5em 1em 0.5em;

    &:focus {
        outline: 0 !important;
    }
}

.addOnBtn {
    border-radius: 0px 8px 8px 0px;
}

.minusBtn {
    border-radius: 8px 0px 0px 8px;
}

.float-right {
    float: right !important;
}

.margin-bottom-20px {
    margin-bottom: 20px !important;
}

.noWidgetstyle {
    color: #737373 !important;
    border-top: 1px solid $gray !important;
    width: 100%;
    margin-left: 15px;
    margin-right: 15px;
    border-bottom: 1px solid $gray;
    margin-bottom: 10px;
    padding: 10px;
    text-align: center;
}

.margin-left-neg-37 {
    margin-left: -37px !important;
}

.margin-left-neg-41 {
    margin-left: -41px !important;
}

.margin-left-neg-55 {
    margin-left: -55px !important;
}

.margin-xs-leftright25 {
    margin-left: 25px !important;
}

.margin-xs-left31 {
    margin-left: 31px !important;
}

.addOnImg {
    width: 12px;
}

@media (max-width:900px) {
    :host::ng-deep #addonsDatatable .datatable-header-cell {
        margin-top: -24px !important;
    }
}

@media screen and (max-width: 767px) {
    .margin-xs-leftright25 {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }

    .margin-xs-left31 {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }

    .margin-left-neg-37 {
        margin-left: 15px !important;
        margin-top: 8px !important;
        margin-right: 15px !important;
    }

    .margin-left-neg-41 {
        margin-left: 15px !important;
        margin-top: 8px !important;
        margin-right: 15px !important;
    }

    .margin-top-26px-mobile {
        margin-top: 26px !important;
    }

    .width-83percent {
        width: 83% !important;
    }

    .margin-neg-15-mobile {
        margin-top: -15px;
    }

    .margin-neg-4-mobile {
        margin-top: -4px !important;
    }

    .margin-left-modify-20 {
        margin-left: 0px !important;
    }

    .margin-left-modify-74px {
        margin-left: 0px !important;
    }

    .updatehelpicon {
        margin-top: -49px !important;
        margin-left: 390px !important;
    }

    .margin-top-mobile-25 {
        margin-bottom: -18px !important;
    }

    .margin-top-mobile-10px {
        margin-top: 10px !important;
    }

    .margin-xs-10px-bottom-0 {
        margin: 10px 10px 0px 10px;
    }
}

//Spinner//
@keyframes pulse {
    50% {
        background: rgb(0, 0, 0);
        ;
    }
}

.loading-pulse {
    position: relative;
    width: math.div(24px, 4);
    height: 24px;
    margin-left: 18px;
    background: rgba(rgb(0, 0, 0), 0.2);
    ;
    animation: pulse 750ms infinite;
    animation-delay: math.div(750ms, 3);

    &:before,
    &:after {
        content: '';
        position: absolute;
        display: block;
        height: math.div(24px, 1.5);
        width: math.div(24px, 4);
        background: rgba(rgb(0, 0, 0), 0.2);
        top: 50%;
        transform: translateY(-50%);
        animation: pulse 750ms infinite;
    }

    &:before {
        left: math.div(-24px, 2);
    }

    &:after {
        left: math.div(24px, 2);
        animation-delay: math.div(750ms, 1.5);
    }
}

.equipmentUnderlay {
    position: absolute;
    height: 15%;
    width: 35%;
    top: 54%;
    left: 32%;
    background: #ffffff url("~assets/images/blueprint/equipment/loading-whitebg.gif") no-repeat center;
    opacity: 0.8;
    filter: alpha(opacity=80);
    padding: 5px !important;
}

.headertext {
    font-size: 1.500em !important;
    line-height: 1.167em !important;
    margin-bottom: 8px !important;
}

.equipment_header {
    font-size: 2.500em !important;
    line-height: 1.100em !important;
    margin-bottom: 8px !important;
}

.spinnerWidget {
    background: url("~assets/images/blueprint/equipment/loading-whitebg.gif") no-repeat center;
    width: 36px !important;
    height: 39px !important;
}

.margin-0-auto {
    margin: 0 auto !important;
}

.margin-50 {
    margin: 50% 45% !important;
}

.margin-1 {
    margin: 1% 45% !important;
}

.margin-center-align {
    margin: 23% 45% !important;
}

.card-block {
    height: 12rem;
    width: 13rem;
}

.card.border-color {
    border-color: #DADADC;
}

.card-container {
    color: $link-color;
    background: white;
}

.card-container:hover {
    background: #204d74;
    color: white;
    cursor: pointer;
}

.card-container a:hover {
    background: #204d74;
    color: white
}

.card-container-anchor a:hover {
    background: #204d74;
    color: white;
    cursor: pointer;
}


/*** Common and Helper CSS Cleanup ***/

#c-container {
    padding-top: 0px;
    padding-bottom: 0px;
}

#c-container .bold {
    font-family: $default-font-family-bold;
}

#c-container p {
    line-height: 1.250em;
    margin-bottom: 20px;
    word-wrap: break-word !important;
}

a:hover {
    text-decoration: none !important;
}

@media screen and (min-width: $screen-md) {
    .constrain-grid {
        max-width: 940px;
        min-width: 940px;
        width: 940px;
    }

    .constrain-grid-full-screen {
        width: auto;
    }

    .constrain-grid-capp-996-px {
        max-width: 996px;
        min-width: 996px;
        width: 996px;
    }

    .hero-grid {
        max-width: 1260px;
        min-width: 1260px;
        width: 1260px;
    }
}

.container-fluid {
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
}


/* MOBILE */

@media (max-width: $screen-mobile) {
    .dropdown-width {
        width: 85% !important;
    }
}


/* TABLET */

@media screen and (min-width: $screen-desktop) and (max-width: $screen-tablet) {

    .constrain-grid,
    .hero-grid {
        width: 100%;
    }
}

#c-container .ph-stroke {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 70px;
}

#c-container .clearfix:before,
#c-container .clearfix:after {
    display: table;
    content: " ";
}

//not used
.cb-tabset>.nav-pills>.nav-item>.nav-link.active {
    color: $gray-link-color !important;
    font-weight: bold;
    border-bottom: 5px solid;
    border-radius: 0;
    background-color: transparent !important;
}

.text-inline {
    display: inline-block;
}

.display-flex {
    display: flex;
}

.display-grid {
    display: grid;
}

//not used
.cb-tab-title {
    color: #6d6d6d;
}

//not used
.cb-tab-content {
    background-color: $white;
}

//not used
.ngb-dp-weekday {
    color: #045690 !important;
    font-style: normal !important;
}

//not used
.ngb-dp-header {
    padding-bottom: 0.25rem;
}

//not used
.ngb-dp-navigation-select {
    height: 35px;
}

//check
.select2-selection {
    border: none !important;
    border-radius: 0px !important;
    background-color: transparent !important;
}

//check
.select2-results__option--highlighted {
    background-color: $link-color !important;
}

.cb-multi-class {
    color: $grey;

    .c-token {
        background: $navy-blue !important;
    }

    .pure-checkbox label::before {
        border-color: $link-focus-color !important;
    }

    .pure-checkbox input[type="checkbox"]+label:before {
        background-image: none !important;
    }

    .pure-checkbox input[type="checkbox"]:checked+label:before {
        background: $link-focus-color !important;
        background-image: none !important;
    }

    .pure-checkbox input[type="checkbox"]:checked+label:before {
        background: $link-focus-color !important;
    }

    input[type="checkbox"]+label:before {
        margin: 0px;
    }

    .pure-checkbox input[type="checkbox"]:checked+label:before {
        background: $link-color;
    }

    .selected-list .fa-angle-down,
    .selected-list .fa-angle-up {
        display: none;
    }

    .c-btn {
        background: url('~assets/images/blueprint/form-fields-biz.png') 0 -37px no-repeat, url('~assets/images/blueprint/form-fields-biz.png') 100% 0 no-repeat !important;

        &:focus {
            box-shadow: 0 0 5px $link-focus-color !important;
        }

        .selected-list .c-btn {
            padding: 7px 0px 8px 10px !important;
        }
    }
}

.pure-checkbox input[type=checkbox]+label {
    padding-left: 2.5em !important;
}

.selected-list .c-btn {
    box-shadow: none !important;
}

.c-btn {
    border: none !important;
    border-radius: 0px !important;
}

.cb-datepicker select.custom-select {
    background-image: none !important;
}

.ui-megamenu-root-list {
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: $white;
}

.ui-megamenu {
    background-color: $white;
}

.ui-menuitem-active .ui-menuitem-link:hover {
    background-color: $white;
}

.datatable-icon-up:before,
.datatable-icon-down:before {
    content: none;
}

.custom-icon {
    background: url('~assets/images/blueprint/help.png') no-repeat !important;
}

.ui-menuitem-text {
    color: rgb(47, 133, 204);
}

ngx-datatable .ui-widget-header {
    // restricted to datatable component only to prevent other component break
    border: 0px !important;
}

.ui-megamenu-submenu-header {
    padding: 0em !important;
}


/* enable absolute positioning */

.inner-addon {
    position: relative;
}


/* style icon */

.inner-addon .fa {
    position: absolute;
    padding: 10px;
    pointer-events: none;
}


/* align icon */

.left-addon .fa {
    left: 0px;
}

.right-addon .fa {
    right: 0px;
}


/* add padding  */

.left-addon input {
    padding-left: 30px;
}

.right-addon input {
    padding-right: 30px;
}

//Check
.cb-form-control {
    border-radius: 0;
    height: 35px;
}


/*--Not used--*/

.cb-control-label {
    font-weight: bold;
    padding-bottom: 10px;
}

.cb-btn-primary {
    background-color: #03406a;
    border-color: #03406a;
}

.cb-btn-secondary {
    background-color: $link-color;
    border-color: $link-color;
}

.text-cb-primary {
    color: $link-color;
}


/*--Not used--*/

.card-header {
    font-weight: bold;
    background-color: $dark-link-color;
    color: $white;
}

.text-cb-subheader {
    line-height: 1.250em;
    margin-bottom: 20px;
    word-wrap: break-word !important;
}

#c-container .breadcrumb ul li a span.target {
    color: $link;
}

#c-container .breadcrumb ul li a {
    padding-left: 5px;
    // font-family: "open_sanssemibold", Arial, Helvetica, Sans-serif;
    font-size: 0.875em;
}

#c-container .breadcrumb {
    text-align: left;
    background: none;
    margin-bottom: 0px;
}

#c-container .breadcrumb ul li a span.divider {
    color: $black;
}

.block-ui-template .cb-loader {
    margin-left: 49%;
}

.spinner {
    background: $white !important;
}

.spinner img {
    background: none !important;
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}

.hidden {
    visibility: hidden;
}

.visible {
    visibility: visible;
}

//Not used
.cb-fh {
    height: 50vh;
}

.empty-row {
    padding: 20px;
    height: 65px;
    background-color: rgba(0, 0, 0, 0.05);
}


/**Check**/

select.full {
    width: 100%;
}

#voice-container select {
    padding: 4px 35px 4px 8px !important;
    margin-top: 0px !important; //remove once container issue is fixed
    margin-bottom: 0px !important;
    border-radius: 3px;
}

#voice-container input[type="text"],
input[type="text"],
input[type="number"],
input[type="tel"],
input[type="password"] {
    margin-top: 0px !important; //remove once container issue is fixed
    line-height: 0px;
    border-radius: 3px;
}

.error-control {
    position: absolute;
    top: 4em;
}

.bs-timepicker-field {
    width: 50px !important;
    margin-bottom: 0px !important;
}

.datepicker {
    width: 55% !important;
}

.schedule-label {
    display: inline-block !important;
    margin-top: 10px !important;
    margin-right: 20px;
}

.schedule-label::before {
    margin-right: 0px !important;
}


/**--Check--**/

@include media-breakpoint-up(lg) {
    .sch-modal-lg .modal-dialog {
        max-width: 60% !important;

        &.expanded {
            max-width: 85% !important;
        }
    }

    .sch-modal-lg.modal-width .modal-dialog {
        max-width: 47.17% !important;
    }

    .sch-modal-lg.modal-phone-width .modal-dialog {
        max-width: 42% !important;
    }
}

.spm-modal-lg .modal-dialog,
.spm-modal-md .modal-dialog {
    max-width: 600px !important;

    @media screen and (min-width: 768px) and (max-width: 991px) {
        margin: 10%;
    }
}

.spm-modal-lg #addEditForm .form-group #state {
    padding: 4px 24px 4px 8px !important;
}

@include media-breakpoint-between(sm,
    lg) {
    .sch-modal-lg .modal-dialog {
        max-width: 99% !important;

        &.expanded {
            max-width: 100% !important;
        }
    }
}

@include media-breakpoint-up(lg) {
    .mal-modal-md .modal-dialog {
        max-width: 50% !important;
    }
}

@include media-breakpoint-between(sm,
    lg) {
    .mal-modal-md .modal-dialog {
        max-width: 99% !important;
    }
}

@include media-breakpoint-up(lg) {
    .modal-lg-wid45 .modal-dialog {
        max-width: 45% !important;
    }
}

@include media-breakpoint-between(sm,
    lg) {
    .modal-lg-wid45 .modal-dialog {
        max-width: 99% !important;
    }
}

@include media-breakpoint-up(lg) {
    .modal-lg-wid454px .modal-dialog {
        max-width: 454px !important;
    }
}

@include media-breakpoint-between(sm, lg) {
    .modal-lg-wid454px .modal-dialog {
        max-width: 454px !important;
    }
}

@include media-breakpoint-between(xs, sm) {
    .modal-lg-wid454px .modal-dialog {
        max-width: 320px !important;
        left: 1.5rem;
    }
}

@include media-breakpoint-up(lg) {
    .modal-lg-363px .modal-dialog {
        max-width: 363px !important;
    }
}

@include media-breakpoint-between(sm,
    lg) {
    .modal-lg-363px .modal-dialog {
        max-width: 99% !important;
    }
}

@include media-breakpoint-up(lg) {
    .modal-lg-786px .modal-dialog {
        max-width: 786px !important;
        max-height: 774px;
    }
}

@include media-breakpoint-between(sm,
    lg) {
    .modal-lg-786px .modal-dialog {
        max-width: 99% !important;
    }
}

/**additional class to have fixed width in payment modal**/

@include media-breakpoint-up(sm) {
    .modal-lg-wid .modal-dialog {
        max-width: 580px !important;
    }
}

@include media-breakpoint-up(sm) {
    .modal-lg-autopay .modal-dialog {
        max-width: 780px !important;
    }
}


/**Check**/

@include media-breakpoint-up(lg) {
    .fte-modal-lg .modal-dialog {
        width: 500px !important;
        max-width: 900px !important;
    }
}

@include media-breakpoint-between(sm,
    lg) {
    .fte-modal-lg .modal-dialog {
        width: 500px !important;
        max-width: 900px !important;
    }
}

@include media-breakpoint-up(lg) {
    .device-config-lg .modal-dialog {
        width: 600px !important;
        max-width: 900px !important;
    }
}

@include media-breakpoint-between(sm,
    lg) {
    .device-config-lg .modal-dialog {
        width: 600px !important;
        max-width: 900px !important;
    }
}

@include media-breakpoint-up(lg) {
    .notification-modal-lg .modal-dialog {
        margin: 1.75rem auto;
        max-width: 740px !important;
    }
}

@include media-breakpoint-up(lg) {
    .master-modal-lg .modal-dialog {
        max-width: 58.75rem !important;
    }
}

@include media-breakpoint-between(sm,
    lg) {
    .notification-modal-lg .modal-dialog {
        margin: 1.75rem auto;
        max-width: 740px !important;
    }
}


/**--Check--**/

.cb-feature-title {
    margin: 70px 0px 30px 0px;
}

.cb-title-desc {
    margin: 30px 0px 30px 0px;
}

.cb-title-rule {
    border-top: 2px solid $link-color-disabled;
    margin-bottom: 30px;
    border-bottom: none;
}

.cb-bold-title-rule {
    border: 3px solid $black;
    margin-bottom: 30px;
}

.cb-border-box {
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid $link-color-disabled;
}

.cb-section-rule {
    border: 1px solid $link-color-disabled;
    margin: 30px 0px 30px 0px;
}

.cb-action-container {
    margin: 30px 0px 40px 0px;
}

.cb-error-container {
    margin-top: 30px;
    margin-bottom: 20px;
    padding-top: 20px; //added for complete Success alert visibility
}

div#breadcrumbs {
    margin-bottom: 10px;
}

#breadcrumbs ul li {
    display: inline;
    list-style-type: none;
}

.breadcrumb ul li:not(:first-child):last-child a span {
    color: $black !important;
}

.title-secondary {
    margin-top: 30px !important;
}

sup {
    font-size: 50% !important;
}

.title {
    margin-top: 0px !important;
    margin-bottom: 30px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.25em;
}

.title-mb20 {
    margin-top: 0px !important;
    margin-bottom: 20px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.25em;
}

.row-text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media screen and (max-width: 767px) {
    .title-xs {
        margin-top: 10px !important;
        line-height: 1.25em;
        word-break: break-word;
    }
}

.voicetoolsbackground {
    background: $error-blue-bg;
    border-radius: 10px;
}

.quicktools {
    ul {
        list-style: none;
        overflow: hidden;

        li {
            float: left;
            display: block;
            padding-right: 10px;

            div {
                padding-top: 1em;
            }
        }
    }

    .callforwarding {
        background: url('~assets/images/cbtools.png') no-repeat -415px -394px;
        width: 49px;
        height: 49px;
        display: inline-block;
    }

    .vmsetting {
        background: url('~assets/images/cbtools.png') no-repeat -148px -524px;
        width: 49px;
        height: 49px;
        display: inline-block;
    }

    .vmtools {
        background-image: url('~assets/images/blueprint/icons/voicemanager-tools.png');
        width: 49px;
        height: 49px;
        display: inline-block;
    }
}

a:-webkit-any-link {
    text-decoration: none;
}

//Not used
@media (min-width: 576px) {
    #aharModal .modal-dialog {
        max-width: 700px !important;
    }
}

.fa-question-circle:before {
    content: " ";
}

//Not used
.fa-cb-info-recur {
    color: $link-color
}

#aharModal .modal-dialog {
    max-width: 700px !important;
}

.form-error {
    display: inline-block;
    margin-bottom: 10px;
}

#c-container {
    .pad-0 {
        padding: 0px;
    }
}


/************** Prime ng table css start ************/

// p-table {
//   font-family: open_sansregular, Arial, Helvetica, sans-serif;
// }
// .ui-table-wrapper {
//   font-family: open_sansregular, Arial, Helvetica, sans-serif;
// }
.ui-table-caption {
    font-weight: normal;
    border: 0 none;
    background: none;
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 20px;
}

.ui-table-caption .ui-helper-clearfix div div p {
    margin-bottom: 0px !important;
    padding-top: 10px;
}

.ui-table-wrapper {
    border-top: 5px solid $gray-link-color;
    margin-bottom: 20px;
}

.ui-table-tbody {
    border-bottom: 2px solid $black;
}

.ui-table .ui-table-thead>tr>th {
    padding: .75em;
    vertical-align: bottom;
    border-bottom: 1px solid $whited1;
    color: $link-color;
    // font-family: "open_sansregular", Arial, Helvetica, Sans-serif;
    background: none;
    line-height: 1.8;
}

.ui-table .ui-table-tbody>tr>td:not(:last-child),
.ui-table .ui-table-tbody>tr>th:not(:last-child) {
    border: none;
    border-right: 1px solid $light-gray;
    padding: 0.6em;
    line-height: 2.625;
}

.ui-table .ui-table-thead>tr>th,
.ui-table .ui-table-tfoot>tr>td,
.ui-table .ui-table-tbody>tr>td {
    border-color: transparent;
    border: none;
}

.ui-table .ui-table-tbody>tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0);
}

.ui-table .ui-table-tbody>tr:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

.ui-table .ui-sortable-column.ui-state-highlight {
    background-color: rgba(0, 0, 0, 0);
    color: $link-color;
}

.ui-table .ui-sortable-column:not(.ui-state-highlight):hover {
    background: none;
    border-color: none;
    color: $link-color;
}

.ui-paginator {
    text-align: left;
    background: transparent;
    font-weight: bold;
    height: 50px;
    padding: 0.7em 0 0.7em 0;
    border: none;
}

.ui-paginator a.ui-state-disabled {
    background-color: $datatable-disable-color;
}

.ui-corner-all {
    border-radius: 0;
}

.ui-sortable-column-icon.fa.fa-fw.fa-sort.fa-sort-asc {
    background: url('~assets/images/blueprint/icons/chevron-up-blue.png') no-repeat;
    height: 10px;
    width: 20px;
}

.ui-sortable-column-icon.fa.fa-fw.fa-sort.fa-sort-asc.fa-sort-desc {
    background: url('~assets/images/blueprint/icons/chevron-down-blue.png') no-repeat;
    height: 10px;
    width: 20px
}

.ui-sortable-column-icon.fa-fw.fa.fa-sort {
    background: url('~assets/images/blueprint/icons/chevron-down-blue.png') no-repeat;
    height: 10px;
    width: 20px
}

.fa-sort-up:before,
.fa-sort-asc:before {
    content: none;
}

.fa-sort-down:before,
.fa-sort-desc:before {
    content: none;
}

.fa-unsorted:before,
.fa-sort:before {
    content: none;
}

.ui-sortable-column-icon.fa-fw.fa.fa-sort {
    background: url('~assets/images/blueprint/icons/chevron-down-blue.png') no-repeat;
    height: 10px;
    width: 20px
}

.fa-sort-up:before,
.fa-sort-asc:before {
    content: none;
}

.fa-sort-down:before,
.fa-sort-desc:before {
    content: none;
}

.fa-unsorted:before,
.fa-sort:before {
    content: none;
}

.fa-sort {
    float: right;
    margin-top: 8px;
}

.ui-table .ui-table-wrapper table thead tr th:not(:last-child) {
    border-right: 1px solid $light-gray;
}

.ui-table .ui-sortable-column:not(.ui-state-highlight):hover {
    border-right: 1px solid $light-gray;
}

.check-margin {
    margin-top: -7px !important;
}

timepicker .has-error input.is-invalid {
    background-color: $error-bg-red;
}

.modal-inner-right {
    height: auto;
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 17px;
}

.btnmodal {
    background-image: url("~assets/images/blueprint/icons/modal-close-btn.png");
    display: inline-block;
    height: 40px;
    width: 82px;
}

.buttonmodal {
    width: 82px;
    height: 40px;
}

.modal-content {
    border-radius: 0;
}

ngb-timepicker .form-control {
    border-radius: 0;
    width: 3.4em !important;
}

.sch-datepicker {
    width: 9.063em;
}

.width-3-5em {
    width: 3.5em !important;
}

.ngb-tp-meridian {
    display: none !important;
}

.schedule-meridian {
    margin-left: -80px;
    padding-top: 1px;
}

@include media-breakpoint-down(xs) {
    .schedule-meridian {
        margin-left: -10px;
        padding-top: 1px;
    }
}

@include media-breakpoint-down(sm) {
    .schedule-meridian {
        margin-left: -10px;
        padding-top: 1px;
    }
}

@include media-breakpoint-down(md) {
    .schedule-meridian {
        margin-left: -10px;
        padding-top: 1px;
    }
}

@include media-breakpoint-down(lg) {
    .schedule-meridian {
        margin-left: -10px;
        padding-top: 1px;
    }
}

.align-bottom {
    vertical-align: bottom;
}

.seqManage {
    padding-left: 15px;
}


/* Tablet Fix */

#voice-container .row:before,
#voice-container .row:after,
#voice-landing-container .row:before,
#voice-landing-container .row:after,
#voice-home-container .row:before,
#voice-home-container .row:after,
#c-container .row:before,
#c-container .row:after {
    content: none !important;
}

.modal-body .row:before,
.modal-body .row:after {
    content: none !important;
}

a.button[role='button']:hover {
    color: $white !important;
}

.sort-btn {
    position: absolute;
    top: 13px;
    right: 6px;
}

#outsideGroupValue select {
    margin-top: -5px !important;
    margin-bottom: 0px !important;
}

#groupSettingsDatatable select {
    margin-top: -5px !important;
}

.matchcard h3 {
    line-height: 1.4 !important;
}

.eula {
    overflow-y: scroll;
    height: 300px;
    border: 1px solid $link-color-disabled;
    padding: 15px 30px;
}

.overflow-y-auto {
    overflow-y: auto;
}

.repectionist-console {
    background: url('~assets/images/receptionist_console.png') no-repeat;
    width: 100%;
    height: 400px;
}

.reorderingPicklist .ui-picklist-target-controls {
    display: inline !important;
}

.reorderingPicklist .ui-picklist-target-controls.ui-picklist-buttons {
    display: inline !important;
    position: absolute !important;
    top: 347px !important;
    left: 68% !important;
}

.reorderingPicklist.accordion-picklist .ui-picklist-target-controls.ui-picklist-buttons {
    left: 580px !important;
}

.reorderingPicklist .ui-picklist .ui-picklist-target-controls .ui-picklist-buttons-cell {
    display: flex !important;
    margin: 20px !important;
}

.reorderPickListMargin {
    margin-top: 65px;
}

// .justify-content-start {
//   border-bottom: 4px solid $link-color-disabled;
// }
// .nav-pills .nav-link.active,
// .nav-pills .show > .nav-link{
// font-size:17px;
// color: $black21;
// background-color: $white;
// border-bottom: 4px solid $black;
// padding: 6px 25px;
// border-radius: 0 !important;
// }
// .nav-pills .nav-link {
// font-size:17px;
// color:$black;
// }
// .nav-item{
// padding-right: 30px;
// }
.welcome-text-min-height {
    min-height: 68px;
}

.disableBorder {
    border: 1px solid $tone-bone-white !important;
}

@include media-breakpoint-up(lg) {
    .dect-headsets-repeaters .modal-dialog {
        max-width: 60% !important;
    }
}

@include media-breakpoint-between(sm,
    lg) {
    .dect-headsets-repeaters .modal-dialog {
        max-width: 98% !important;
    }
}

#lpbusiness-icon a {
    text-decoration: none !important;
    cursor: pointer !important;
    color: $link-color !important;
}


/* a tag css that not href it get common for all changes*/

a:not([href]):not([tabindex], ._pendo-text-link) {
    color: $link !important;
    text-decoration: none !important;
}

a:not([href]):not([tabindex], ._pendo-text-link):hover,
a:not([href]):not([tabindex], ._pendo-text-link):focus {
    color: $blue00 !important;
    text-decoration: none !important;
}

@media (max-width: $screen-mobile) {
    .xs-table-cell-border {
        border: 1px solid $link-color-disabled;
        margin: 0px;
    }
}

.modal-backdrop {
    z-index: 1040 !important;
}

.modal {
    z-index: 1050 !important;
}

.search-overlap {
    z-index: 100002;
}

.datatable-header .datatable-header-inner .datatable-row-center {
    width: 100% !important;
}

.ucappLogo {
    background-image: url('~assets/images/ucappLogo.png');
    //background-image: url('~assets/images/blueprint/icons/voicemanager-tools.png');
    width: 87px;
    height: 37px;
    display: inline-block;
}

.ucappTxt {
    font-size: 12px;
}

.accountNumber {
    width: 175px !important;
}

.ringtoNumber {
    width: 165px !important;
}

.userlist-name {
    margin-top: -15px;
    margin-bottom: 20px;
}

.pushtotalklist .ui-picklist .ui-picklist-list li {
    margin: 0;
    height: auto;
    padding: 0 10px;
}

.hn-logout-link {
    color: $black !important;
    font-weight: bold !important;
}

.hn-logout-link:hover {
    text-decoration: underline !important;
}

.text-decoration-underline {
    text-decoration: underline;
}

.text-decoration-line-through {
    text-decoration: line-through;
}

::ng-deep .ngx-datatable.bootstrap.smallFooter .datatable-footer .datatable-pager ul li.pages {
    margin-right: 4px;
    padding: 5px 0px 5px 0px;
}


/**Modal Header**/

.schedule-modal-header {
    border-bottom: none !important;
    margin-top: -15px;
    background-color: #ebeff0;
    padding: 15px 0 20px 25px;
    margin-left: -25px;
    margin-right: -25px;
}

.schedule-modal-title {
    padding: 15px 0px;
    margin-left: -14px;
}

.pad-left-p-5 {
    padding-left: 5px
}

.sch-modal-footer {
    margin-left: -30px;
    color: darkgrey;
    margin-right: -30px;
    margin-top: 20px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.mediumfont {
    font-size: 16px !important;
}

.mob-max-width {
    max-width: 330px;
}

.dropdown-max-width {
    max-width: 300px;
}

.w-900 {
    width: 900px;
}

.w-20 {
    width: 20%;
}

.margin-left-230 {
    margin-left: 230px;
}

.margin-top-38-px {
    margin-top: 0em !important;
}

.margin-left-ng-17-em {
    margin-left: -17em;
}

.margin-left-10-px {
    margin-left: 10px;
}

.margin-left-ng-15-5-em {
    margin-left: -15.5em;
}

.margin-left-25em {
    margin-left: 25em !important;
}

.margin-left-23em {
    margin-left: 23em !important;
}

.mb-0-1-rem {
    margin-bottom: 0.1rem;
}

.vertical-align-text-top {
    vertical-align: text-top;
}

.font-style-italic {
    font-style: italic;
}

.img-size {
    width: 32px;
}

.img-lg-size {
    width: 50px;
}


/* Datatool Image Style */

.datatool-img-size {
    height: 75%;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .ie-img-class {
        height: 55px;
    }
}

.ie-icon-size {
    width: 32px;
    height: 50px
}


/* Account Widget Style */

.accTable {
    width: 53.75rem;
    margin-left: 2.5em;
}

.selected {
    color: $link;
}


/**AOMS Cleanup common classes**/

.clear-search {
    margin-top: 0.625rem;
    margin-left: -1.5rem;
}

.word-wrap {
    word-wrap: break-word;
}

.upload-result {
    margin-left: -0.3rem;
}

.modal-close-align {
    float: right;
    top: -25px;
}

.pad-top-bottom-25 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}

.hr_modal {
    height: 1px;
    background-color: $modal-footer;
}


/*Usermanagement-Lib changes*/

.hr-style {
    border: 1px solid #dadadc;
}

.border-right-line {
    border-right: 1px solid $border;
}

.width-5 {
    width: 5rem;
}

.picklist-div {
    font-size: 14px;
    margin: 15px 5px 0 0
}

.pad-top-11 {
    padding-top: 11px;
}

.ul-style {
    list-style-type: none;
}

.border-right {
    border-right: 1px solid $link-color-disabled;
}

.letter-spacing-0-imp {
    letter-spacing: 0 !important;
}

.letter-spacing-0-5px {
    letter-spacing: 0.5px !important;
}

.letter-spacing-neg-0-3px {
    letter-spacing: -0.3px;
}

.word-space-0-imp {
    word-spacing: 0 !important;
}

.word-space-1 {
    word-spacing: 1px;
}

.word-spacing-25 {
    word-spacing: 25px;
}

.margin-bottom-5px {
    margin-bottom: 5px !important;
}

.padding-10px {
    padding: 10px;
}

.gray-border-5 {
    border: 10px solid $gray-modal-header;
}

.list-style-disc {
    list-style-type: disc;
}

//Focus indicator
a:focus {
    outline: 1px solid $link-color;
}

span {
    &[role="button"] {
        &:focus {
            outline: 1px solid $link-color;
        }

        &:hover {
            cursor: pointer;
        }
    }
}

.div-focus-visible {
    &:focus-visible {
        outline: 1px solid $link-color;
    }
}

.Checkboxdatatable {
    position: relative;
    bottom: 10px;
}

.pos-rel-bottom-6 {
    position: relative;
    bottom: 6px;
}

.bottom-20px {
    bottom: 20px;
}

.margin-left-20px {
    margin-left: 20px !important;
}

.height-60 {
    height: 60px;
}

.pad-top-5 {
    padding-top: 5px !important;
}

.pad-left-p-20 {
    padding-left: 20px;
}

.pf-header-business-hn {
    background: linear-gradient(90deg, #00AAF4 30%, #002f87 95%, #002f87 100%) !important;
    height: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

@include media-breakpoint-down(sm) {
    .btn-mobile-class {
        display: block;
        width: 100%;
    }
}

.asterisk:after {
    content: " *";
    color: $alertErrorLeft;
}

#redesignForms {
    label.asterisk:after {
        content: "* ";
    }

    .text-danger {
        padding-left: 3px;
    }
}

.search-icon {
    background: url('~assets/images/blueprint/magnifying-glass.svg') no-repeat 98% 50%;
}

.labelRedColor {
    color: $alertErrorLeft;
}

.pad-left-5em {
    padding-left: 5em;
}

.pad-left-2-8em {
    padding-left: 2.8em;
}

.bottom-3px {
    bottom: 3px;
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    .ul-style {
        padding-left: 0px;
    }

    .modalheader {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 1rem;
        border-bottom: 1px solid #e9ecef;
        border-top-left-radius: .3rem;
        border-top-right-radius: .3rem;
        flex: 0 1 auto;

        >div {
            margin: 0 !important;
        }

        +row {
            >div.margin-bottom-20 {
                margin-bottom: 0 !important;
            }
        }
    }

    .modal-body {
        +hr {
            +div {
                display: flex;
                display: -webkit-box;
                display: -ms-flexbox;
                flex-wrap: wrap;
                flex: 0 1 auto;

                div {
                    width: 100%;
                }
            }
        }
    }

    .accordion-trigger-standalone-table {
        flex: 0 1 auto;
    }

    .popover {
        .popover-body {
            hr.mb-1 {
                margin-bottom: 0px !important;
                margin-top: 0px !important;
            }
        }
    }
}

.phone-equipment-header {
    color: $cyan-blue;
}

.page-title {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
}

.pad-bottom-1rem {
    padding-bottom: 1rem !important;
}

.pad-top-0-8rem {
    padding-top: 0.8rem !important;
}

.pad-top-1em {
    padding-top: 1em !important;
}

.pad-bottom-075em {
    padding-bottom: 0.75em !important;
}

.pad-0375rem-075rem {
    padding: 0.375rem 0.75rem !important;
}

.pad-2-5rem-3-25rem {
    padding: 2.5rem 3.25rem 2.5rem 3.25rem !important;
}

.pad-top-025em {
    padding-top: 0.25em;
}

.padding-top-15 {
    padding-top: 15px;
}

//Onboarding and EmailDomain
div.decisionContent {
    border: 1px solid $gray;

    &:hover,
    &.clickContent {
        cursor: pointer;
        border-image: linear-gradient(to right, $hover-button, $header-right) 4% 1% 4% 28%;
        background-color: white;
        border-width: 4px;
        border-style: solid;

        img {
            cursor: text;
        }
    }
}

.min-height-300 {
    min-height: 300px;
}

.left50 {
    left: 50%;
}

input.equipName {
    height: 100%;
    width: 63% !important;
    outline: none;
    border: none;
    background: transparent;
    background-color: none;
     -webkit-box-shadow: none;
    box-shadow: none;

    &:hover,
    &:focus {
        border: 0px !important;
        box-shadow: none;
    }
}

.chooseFileButton {
    background-color: $white-smoke;
    padding: 3px 6px;
    border-style: outset;
    border-color: rgb(118, 118, 118);
}

.uploadButton input[type="file"] {
    font-size: 0px !important;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    padding: 13%;
}

.deviceName {
    max-width: 83%;
    display: inline-block;
    word-break: break-all;
}

.deviceName+a {
    vertical-align: top;
}

.verticalLine {
    display: inline;
    height: 25px;
    border: 1px solid;
    z-index: -1;
    color: $gray-modal-header;
    background-color: $gray-modal-header;
}

@media screen and (max-width: 767px) {
    .gray-border-left-xs-1 {
        border-left: none;
        border-top: 1px solid $gray;
        padding-top: 2em;
        margin-top: 2em;
    }

    .gray-border-right-xs-1 {
        border-left: none;
        border-top: 1px solid $gray;
        padding-top: 2em;
        margin-top: 2em;
    }
}

.borderstyle-none {
    border-style: none !important;
}

//Focus indicator for account widget
div#multi-col-2,
div#multi-col-1 {
    div.account:focus {
        outline: 1px solid $link-color;
    }
}

.label-size-25 {
    color: $cyan-blue;
    font-size: 25px;
}

.label-size-23 {
    color: $cyan-blue;
    font-size: 23px;
}

.flatDevice {
    +img {
        position: absolute;
        margin-left: -0.3em;
        margin-top: 2em;
    }
}

.standingDevice {
    +img {
        position: absolute;
        margin-left: -5.3em;
        margin-top: 0.5em;
    }
}

.img-equipment {
    width: 135px;
}

//Account Recovery
div.recoveryOption {
    border: 2px solid $gray;
    border-radius: 0.5em;

    &:hover,
    &:focus {
        cursor: pointer;
        border-image: linear-gradient(to right, $hover-button, $header-right) 4% 1% 4% 28%;
        background-color: white;
        border-width: 4px;
        border-radius: 0.5em;
        border-style: solid;
    }
}

div.resetRecoveryOption {
    border: 2px solid $gray;
    border-radius: 0.5em;
    width: 8.438rem;
    height: 7.563rem;

    &:hover,
    &:focus {
        cursor: pointer;
        border-radius: 8px;
        border: 2px solid $cyan-blue;
    }
}

#__tealiumGDPRcpPrefs {
    .switch {
        position: relative;
        display: inline-block;
        width: 41px;
        height: 24px;
        margin-bottom: 0.5rem;
    }

    .cmBtnPrimary,
    input.cmBtnPrimary[type=submit] {
        background: $button-color;
        font-family: $default-font-family;
        text-align: center;
        border: 3px solid $button-color;
        color: $white;
        border-radius: 0px;
        font-size: $default-font-size;
        padding: .5rem 1.875rem;

        &:hover {
            box-shadow: 0 3px 5px rgba(0, 0, 0, .3);
        }
    }
}

// ManageUsers - AccountRecovery
div.chooseRecovery {
    border: 1px solid $gray;
    border-radius: 0.25em;

    &:hover {
        cursor: pointer;
    }
}

.bg-color-gray {
    background-color: $gray-modal-header;
}

.loading-text {
    margin-top: 8em;
    width: 200px;
}

.m-left-23 {
    margin-left: 23.3em !important;
}

.m-left-8-5 {
    margin-left: 8.5em;
}

.m-left-31-7 {
    margin-left: 31.7em;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-space-between {
    justify-content: space-between;
}

.justify-end {
    justify-content: flex-end;
}

.flex-wrap {
    flex-wrap: wrap;
}

.margin-top-3-em {
    margin-top: 0.3rem !important;
}

.help_text {
    font-size: 0.8em;
}

.scrollEffectMobile {
    -webkit-mask-image: linear-gradient(to right, black 85%, transparent 100%);
    mask-image: linear-gradient(to right, black 85%, transparent 100%);
}

.color-transparent {
    color: transparent !important;
}

.countDownTimer {
    text-align: center;
    font-size: 3em;
    margin-bottom: 1em;
    margin-top: 0.75em;
    color: #009ae0;
}

.rebootCountDownText {
    text-align: center;
    margin: 0 3em 0 3em;
}

.rebootCountDownAlert {
    margin-top: 20px;
    margin-bottom: 30px;
}

.rebootCountDownAlert .msg-alert {
    background-position-y: 35px;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-wrap {
    overflow-wrap: anywhere;
}

.padding-top-25 {
    padding-top: 25px;
}

.padding-top-14 {
    padding-top: 14px;
}

.pad-left-6px {
    padding-left: 6px;
}

.device-unknown {
    border: 5.4px solid $disabled-gray-color;
}

.device-connected {
    border: 5.4px solid $alertSuccessLeft;
}

.device-notconnected {
    border: 5.4px solid $alertErrorLeft;
}

.device-connectioninterpreted {
    border: 5.4px solid $amber;
}

.device-nostatus {
    border: 5.4px solid $tooltip-color;
}

.device-connectedvia-lte {
    border: 5.4px solid $hover-button;
}

.device-unplugged {
    border: 5.4px solid $disabled-gray-color;
}

.other-equipment-carousel .carousel-tile-highlight .equipment-name {
    background-color: $tooltip-color;
    border-radius: 4px 4px 0px 0px;
    color: $white;
}

.other-equipment-carousel .carousel-tile:hover .equipment-name {
    background-color: $tooltip-color;
    border-radius: 4px 4px 0px 0px;
    color: $white;
}

.other-equipment-carousel .carousel-tile {
    height: 225px;
    border-radius: 5px;
    box-shadow: 0px 2px 3px $dark-gray;
}

.other-equipment-carousel .carousel-tile:hover {
    box-shadow: 0px 2px 5px;
}

.other-equipment-carousel .carousel-tile.carousel-tile-highlight {
    box-shadow: 0px 2px 5px !important;
}

.h-20 {
    height: 20rem;
}

.margin-top-6-bottom-3em {
    margin-top: 6em;
    margin-bottom: 3em;
}

.border-top-gray {
    border-top: 0.5px solid $disabled-gray-color;
}

.border-bottom-gray {
    border-bottom: 0.5px solid $disabled-gray-color;
}

.z-index-2 {
    z-index: 2;
}

app-navigation {
    width: 100%;
}

.justify-content-end {
    justify-content: end;
}

.mobile-description-section {
    width: 300px;
    margin: auto;
}

.errorMsg {
    background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 54.1 (76490) - https://sketchapp.com --%3E%3Ctitle%3E25FA00C4-A4E3-4FAB-9FC3-EF17FD6B26CD%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='My-Account-Image-Library' transform='translate(-16.000000, -215.000000)' fill='%23C63C34'%3E%3Cg id='alert_error-' transform='translate(16.000000, 215.000000)'%3E%3Cpath d='M10.8,14.4 L13.2,14.4 L13.2,6 L10.8,6 L10.8,14.4 Z M10.8,19.2 L13.2,19.2 L13.2,16.8 L10.8,16.8 L10.8,19.2 Z M12,0 C5.376,0 0,5.376 0,12 C0,18.624 5.376,24 12,24 C18.624,24 24,18.624 24,12 C24,5.376 18.624,0 12,0 L12,0 Z' id='Fill-1'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") $error-bg-red no-repeat 15px 15px;
    padding: 0.6em 0px 0.6em 3em;
    border-width: 0px 0px 0px 10px;
    border-left-color: $alertErrorLeft;
    background-position-y: center;
    padding-bottom: 0.6em;
    border-style: solid;

    .appearance-set {
        appearance: auto;
        -webkit-appearance: auto;
    }
}

.height-18 {
    height: 18px;
}

.padding-top-17px {
    padding-top: 17px;
}

.p-calendar .p-inputtext:read-only {
    color: $gray-link-color !important;
    border: 1px solid !important;

    &:enabled {
        &:hover {
            border-color: $p-text-hover-color !important;
            -webkit-box-shadow: 0 0 5px $hover-color !important;
            box-shadow: 0 0 5px $hover-color !important;
        }

        &:focus {
            border-color: $p-text-focus-color !important;
            -webkit-box-shadow: 0 0 0 1px $p-text-box-shadow-color !important;
            box-shadow: 0 0 0 1px $p-text-box-shadow-color !important;
        }
    }
}

.right-2-75-em {
    right: 2.75rem !important;
}

.left-46 {
    left: 46% !important;
}

.pad-35-20 {
    padding: 35px 20px !important;
}

.pad-0-30px {
    padding: 0 30px;
}

.height-7-5em {
    height: 7.5em;
}

.right-1-rem {
    right: 1rem !important;
}

.width-4-5-em {
    width: 4.5em !important;
}

.min-width-50 {
    min-width: 50% !important;
}

#pagination select {
    padding: 4px 8px !important;
}

.max-width-3-5 {
    max-width: 3.5em !important;
}

.max-width-3 {
    max-width: 3em !important;
}

.refreshImgWidth {
    width: 1.25rem;
}

.pagehero {
    font-size: medium;
    display: inline-block;
    vertical-align: middle;
}

.page-hero-title {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
}

.link-hover {

    &:hover,
    &:focus {
        border: 1px solid $link-color;
    }
}

.margin-top-35 {
    margin-top: 35px;
}

.margin-left-neg-4-5 {
    margin-left: -4.5em;
}

.margin-left-3-75em {
    margin-left: 3.75em;
}

.margin-left-2-3em {
    margin-left: 2.3em;
}

.pad-right-2-6em {
    padding-right: 2.6em !important;
}

.fit-content {
    width: fit-content;
}

.oneColumnGrid {
    display: grid;
    grid-template-columns: 1fr;
}

.twoColumnGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.padding-right-10-percent {
    padding-right: 10% !important;
}

.padding-left-10-percent {
    padding-left: 10% !important;
}

.padding-left-15-percent {
    padding-left: 15% !important;
}

.padding-left-5-percent {
    padding-left: 5% !important;
}

.padding-right-5-percent {
    padding-right: 5% !important;
}

.padding-left-3-percent {
    padding-left: 3% !important;
}

.padding-right-3-percent {
    padding-right: 3% !important;
}

.max-width-20px {
    max-width: 20px;
}

.upload-input-box {
    width: 300px !important;
    text-align: left;
    border: 1px solid $black;
    border-radius: 2px;
    margin-right: 40px !important;
    background-color: $white;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #999999;

    &:hover {
        cursor: text;
        color: $black;
    }

    &:focus {
        outline: none;
        cursor: text;
        color: $black;
    }
}

.input-file-error {
    border: 1px solid $alertErrorLeft;
    background-color: $error-bg-red;
    outline: 1px solid $alertErrorLeft;
}

/*styles related to google maps*/
.gm-style .gm-style-iw {
    background-color: $button-color !important;
    top: 0 !important;
    left: 0 !important;
    max-width: 305px !important;
    min-height: 60px;
    padding: 15px !important;
    display: block !important;
    border-radius: 3px !important;

    .gm-ui-hover-effect {
        display: none !important;
    }
}

.gm-style-iw-d {
    overflow: auto !important;
    color: $white;
    font-family: $default-font-family;
    font-size: 16px;
    line-height: 22px;
}

.gm-style-iw-tc {
    &::after {
        background: $button-color !important;
    }
}

.gm-control-active.gm-fullscreen-control {
    //border-radius: 20px !important;
    height: 33px !important;
    width: 115px !important;
    background-color: $button-color !important;
    background-image: url("~assets/images/blueprint/digitalprofile/map-expand-dark.svg") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;

    img {
        display: none !important;

    }
}

.gmnoprint {
    div {
        button {
            width: 45px !important;

            img {
                display: none !important;
            }
        }

        div {
            box-shadow: none !important;
            margin: 0px !important;
            width: 45px !important;
            background-color: transparent !important;
            background-image: url("~assets/images/blueprint/digitalprofile/mapZoom-dark.svg") !important;
            background-position: -2px -1px !important;

            .gm-control-active {
                background: none !important;
            }
        }
    }
}

.gm-style-cc {
    display: none !important;
}

.pad-bottom-2-7-rem {
    padding-bottom: 2.7rem !important;
}

.pad-bottom-2-8-rem {
    padding-bottom: 2.8rem !important;
}

.left-0-rem {
    left: 0rem !important;
}

.place-content {
    place-content: center;
}

.width-45 {
    width: 45px;
}

.width-42 {
    width: 42px;
}

.text-decoration-underline-imp {
    text-decoration: underline !important;
}

.margin-bottom-2em {
    margin-bottom: 2em !important;
}

.display-inline-flex {
    display: inline-flex;
}

.line-height-4-8 {
    line-height: 4.875em !important;
}

.width-30 {
    width: 30px;
}

.margin-bottom-4px {
    margin-bottom: 4px !important;
}

.margin-right-neg-20 {
    margin-right: -20px;
}

.padding-top-0-9 {
    padding-top: 0.9em !important;
}

.margin-bottom-1px {
    margin-bottom: 1px !important;
}

.margin-bottom-neg-2-5 {
    margin-bottom: -2.5em !important;
}

.margin-left-6em {
    margin-left: 6em !important;
}

.min-height-180p {
    min-height: 180px;
}

.margin-left-7em {
    margin-left: 7em !important;
}

.margin-right-4rem {
    margin-right: 4rem;
}

.errorBlock {
    padding-left: 5px;
    color: $red;
    font-weight: 600;
}

.width-60px {
    width: 60px;
}

.margin-bottom-neg-7px {
    margin-bottom: -7px !important;
}

.height-30 {
    height: 30px !important;
}


.margin-0px {
    margin: 0px;
}

.pl-3px {
    padding-left: 3px;
}

.pr-3px {
    padding-right: 3px;
}

.margin-right-3-5em {
    margin-right: 3.5em;
}

.width-170-px {
    min-width: 170px;
    max-width: 170px;
}

.margin-right-6em {
    margin-right: 6em !important;
}

.margin-right-6-5em {
    margin-right: 6.5em !important;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;

}

.margin-left-9_5rem {
    margin-left: 9.5rem;
}

.margin-left-10_5em {
    margin-left: 10.5em;
}

.opacity-0-7 {
    opacity: 0.7;
}

.padding-right-3-3 {
    padding-right: 3.3em;
}

.padding-right-7 {
    padding-right: 7px
}

.font-size-p-16 {
    font-size: 16px !important;
}

.pad-right-5em {
    padding-right: 5em;
}

.text-align-end {
    text-align: end
}

.width-190 {
    width: 190px !important;
}

.width-130 {
    width: 130px !important;
}

.pad-right-12 {
    padding-right: 12px !important
}

.pad-top-11px {
    padding-top: 11px !important;
}

.pad-left-2-5rem-imp{
    padding-left: 2.5rem !important;
}

.margin-left-50per {
    margin-left: 50%;
}

.white-bg-imp {
    background-color: $white !important;
}

.margin-left-neg-2px {
    margin-left: -2px !important;
}

.pad-top-8 {
    padding-top: 8px;
}

.justify-self-end{
    justify-self: end;
}

.line-height-16{
    line-height: 16px;
}

.height-40px {
    height: 40px !important;
 }

.height-44px {
    height: 44px;
}

 .preformat-text {
    white-space: pre-line;
 }

 .pad-5-10 {
    padding: 5px 10px;
 }

 .border-left-line {
    border-left: 1px solid $border;
}