span {
  &.new-attachment {
    &::before {
      background: url("~assets/images/blueprint/icons/confirmation.svg") no-repeat;
      content: "";
      height: 38px;
      position: absolute;
      width: 35px;
      margin-left: -35px;
    }
    padding-left: 38px;
  }
  &.uploading {
    padding-left: 38px;
  }
  &.truncate-60 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 60%;
    display: block;
    margin-top: 5px;
    float: left;
  }
  &.truncate-inline {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 90%;
    display: block;
    float: left;
    line-height: normal;
  }
  &.uploading {
    line-height: 35px;
    float: left;
    &::before {
      background: $white url('~assets/images/blueprint/loading-whitebg.gif') no-repeat center;
      content: "";
      height: 38px;
      position: absolute;
      width: 35px;
      margin-left: -35px;
    }
  }
}

/* TABLET & DESKTOP */
@media screen and (min-width: $screen-mobile) {
  .searchbar {
    float: right;
    input[type=text] {
      margin-left: 1em;
      margin-right: 1em;
      width: 11em !important;
    }
  }
}

@media screen and (min-width: $screen-mobile) {
  .searchbar-w-18 {
    float: right;
    input[type=text] {
      margin-left: 1em;
      margin-right: 1em;
      width: 18em !important;
    }
  }
}

/* TABLET */
@media screen and (min-width: $screen-desktop) and (max-width: $screen-tablet) {
  .searchbar {
    float: right;
    input[type=text] {
      margin-left: 1em;
      margin-right: 1em;
      width: 11em !important;
    }
  }
}

@media screen and (min-width: $screen-desktop) and (max-width: $screen-tablet) {
  .searchbar-w-18 {
    float: right;
    input[type=text] {
      margin-left: 1em;
      margin-right: 1em;
      width: 18em !important;
    }
  }
}

span.error-icon {
    background: url("~assets/images/blueprint/error.svg") no-repeat scroll 14px 12px;
    padding: 0.5em 0em 0em 02.5em;
}

span.round-error-icon {
  background: url("~assets/images/blueprint/round-alert.svg") no-repeat scroll 14px 12px;
  padding: 0.7em 0em 0.5em 02.5em;
}

span.pt {
  padding: .65em 1.5em 0.5em 2.5em !important;
  margin-left: -2.75em;
}

.bank {
  background: url("~assets/images/blueprint/billing/bank-icon.svg") no-repeat scroll 14px 12px $white !important;
}

.ach{
  background: url("~assets/images/blueprint/billing/bank-icon.svg") no-repeat scroll 14px 12px $white !important;
}

.visa {
  background: url("~assets/images/blueprint/billing/visa-logo.svg") no-repeat scroll 14px 12px $white !important;
}

.discover {
  background: url("~assets/images/blueprint/billing/discover-logo.svg") no-repeat scroll 14px 12px $white !important;
}

.mastercard {
  background: url("~assets/images/blueprint/billing/mc-logo.svg") no-repeat scroll 14px 12px $white !important;
}

.amex, .americanexpress {
  background: url("~assets/images/blueprint/billing/amex-logo.svg") no-repeat scroll 14px 12px $white !important;
}

.atm {
  background: url("~assets/images/blueprint/billing/atm-logo.svg") no-repeat scroll 14px 12px $white !important;
}


.bg-icon-right-center {
  background-position: 98% 50% !important;
}

.cvv {
  background: url("~assets/images/blueprint/billing/cvvimage.svg") no-repeat scroll 65px 4px $white !important;
}
