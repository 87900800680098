// change are made as globally
ui-switch {
    .switch{
        border: 1px solid #dfdfdf;
        position: relative;
        display: inline-block;
        box-sizing: content-box;
        padding: 0;
        margin: 0;
        cursor: pointer;
        box-shadow: #dfdfdf 0 0 0 0 inset;
        transition: .3s ease-out all;
        -webkit-transition: .3s ease-out all;
        white-space: nowrap;
    }
    .switch small{
        background: #fff;
        background-color: #002F87 !important;
        border-radius: 100%;
        box-shadow: 0 1px 3px rgb(0 0 0 / 40%);
        position: relative;
        top: 0;
        right: calc(100% - 30px);
        transition: .3s ease-out all;
        -webkit-transition: .3s ease-out all;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 6px;
    }
     button.switch.switch-medium, button.switch.switch-small {
    width: 45px !important;
    height: 26px !important;
    border-radius: 30px;
    background-color: $white;
    border: 1px solid $pebble-grey;
    outline: 0 !important;
    &:focus,&:hover{
        border-color: $button-color !important;
         }
         &.disabled  {
            border-color: $disabled-gray-color !important;
            background-color: $pebble-grey;
			cursor: not-allowed;
            &.checked{
                background-color: $disabled-gray-color !important;
            }
            &.checked small:hover, small:focus {
                background-color: $disabled-gray-color !important;
                }
            &.checked small:after {
            color: $disabled-gray-color !important;
            }
        }
        &.checked {
            background-color:$button-color !important;
            small {
            left: 21px !important;
            margin-right: 1em;
            &:hover,&:focus{
                background: $button-color !important;
            }
        &:after {
            top: -1px !important;
            left: 2px !important;
            font-family: "FontAwesome";
            content: "\2713" !important;
            position: absolute;
            width: 17px !important;
            height: 17px !important;
            text-align: center !important;
            font-weight:bold;
            background: $white !important;
            border-radius: 50%;
            color: $blue35 !important;
            outline: 0 !important;
			display: flex !important;
            justify-content: center !important;
            align-items: center !important;
            &:hover,&:focus{
                background: $white !important;
            }
        }
        }
    }
    small {
        right: auto !important;
        width: 15px !important;
        height: 15px !important;
        display: flex;
        position: relative;
        border-radius: 50%;
        background-color: $button-color !important;
        outline: 0 !important;
        &:focus,&:hover{
            background-color: $light-black !important;
        }
    }
    }
}
ui-switch{
    button.switch.switch-medium.disabled > small, button.switch.switch-small.disabled > small {
    background-color: $white !important;
	cursor: not-allowed;
    }
}
