/* Layout containers */

.gray-container {
  background: $light-gray;
  padding: 10px;
  margin: 0;
}

/* Layout containers */

/***************/
/* TABLET & DESKTOP */
@media (min-width: $screen-desktop) {
  .matchcard {
    min-height: 340px;
  }
}

@media (min-width: $screen-desktop) {
  .matchcard.min-height-empty-tag {
    min-height: 100px;
  }
}

.matchcard {
  border: 1px solid #ccc;
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 10px;
  position: relative;
}

@media (min-width: $screen-desktop) {
  .matchcardradio {
    min-height: 115px;
  }
}

.matchcardradio {
  border: 1px solid #ccc;
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 10px;
  position: relative;
}

.pull-bottom {
  position: absolute;
  bottom: 0;
}

.overflow {
  overflow: hidden;
}

.gray-border-container {
  padding: 10px;
  border: 1px solid $gray-3;
}

/* MOBILE */
@media (max-width: 767px) {
  .gray-border-container {
    padding: 10px;
    border: none;
  }
}

/***************/
/* TABLET & DESKTOP */
@media (min-width: $screen-desktop) {
  .thin-wrapper {
    margin-bottom: 20px;
    padding: 10px;
    border: $gray-3 1px solid;
    max-height: 250px;
    overflow-y: scroll;
  }
}

/* MOBILE */
@media (max-width: $screen-mobile) {
  .thin-wrapper {
    word-break: break-all;
  }
}
