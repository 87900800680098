a {
  &.redirect {
    // font-family: "open_sanssemibold", Arial, Helvetica, Sans-serif;
    font-size: 1.063em;
    text-align: center;
    display: inline-block;
    color: $white !important;
    overflow: visible;
    margin-bottom: 1em;
    margin-top: 1em;
    width: auto;
    border: 1px solid $link-color;
    @include box-radius(4px);
    padding: 10px 19px 10px 18px;
    @include box-shadow(none);
    background-color: $link-color;
    text-decoration: none;
    cursor: pointer;
    // FIX ME
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    &.green {
      border: 1px solid $green;
      background-color: $green;
    }
    &.dark-blue {
      border: 1px solid $dark-link-color;
      background-color: $dark-link-color;
    }
  }
  &:focus { 
     outline: 1px solid $link-color;
  }
}
span, ui-switch {
  &[role="button"]:focus {
  outline: 1px solid $link-color;
  }
}
.card {
  &[role="link"]:focus {
    outline: 1px solid $link-color;
  }
}
/* MOBILE */
@media (max-width: 767px) {
  a {
    &.redirect {
      margin-bottom: 10px;
      margin-top: 10px;
      width: 100%;
    }
  }
}

