#c-container input, textarea, .search_input,.modal-body input {
  font-size: 1em;// "open_sansregular", Arial, Helvetica, Sans-serif;
  color: $gray-link-color;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  /* SAFARI FIX */
  -webkit-appearance: none;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  &:-webkit-autofill, &:-internal-autofill-selected{
    -webkit-text-fill-color: $gray-link-color !important;
    -webkit-box-shadow: 0 0 0px 1000px $white inset;
  }
}

input {
  &.has-help {
    width: calc(100% - 30px);
    + span[data-aria-tooltip="has-help"] {
      width: 24px;
      height: 24px;
      display: inline-block;
      img {
        padding-top: 0.25em;
        padding-left: 0.25em;
        position: absolute;
      }
    }
  }
  &[type="text"], &[type="number"], &[type="tel"], &[type="password"] {
    height: 46px;
    line-height: 31px;
    border: 1px solid $black;
    background-color: $white;
    color: $gray-link-color;

    &::placeholder {
      color: $disabled-grey;
     }
  }
  &[type="text"]:focus, &[type="number"]:focus, &[type="tel"]:focus, &[type="password"]:focus {
    border: 1px solid $focus-color;
    box-shadow: 0 0 5px $focus-color;
    color: $gray-link-color;
    
    &::placeholder {
      color: $accordion-black;
    }
  }
  &[type="text"]:hover, &[type="number"]:hover, &[type="tel"]:hover, &[type="password"]:hover {
    border: 1px solid $hover-color;
    box-shadow: 0 0 5px $hover-color;
    color: $gray-link-color;
    
    &::placeholder {
      color: $accordion-black;
     }
  }
  &[type=number] {
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    -moz-appearance: textfield;
  }
  &.has-prefix {
    width: 75% !important;
    margin-left: 0.75em;
  }
  &::placeholder {
    color: $disabled-grey;
   }
   &[type=text]::-ms-clear, &[type=number]::-ms-clear, &[type=tel]::-ms-clear, &[type="password"]::-ms-clear {
    display: none;
  }
  &[type="text"]:hover, &[type="number"]:hover, &[type="tel"]:hover, &[type="password"]:hover,
  &[type="text"]:focus, &[type="number"]:focus, &[type="tel"]:focus, &[type="password"]:focus,
  &[type="text"], &[type="number"], &[type="tel"], &[type="password"] {
    &:read-only {
      border: 1px solid $disabled-gray-color !important;
      box-shadow: none;
      color: $pebble-grey !important;
    }
  }
}

textarea {
  border: 1px solid $black;
  background-color: $white;
  height: 7em;
  resize: none !important;
  &:focus {
    border: 1px solid $focus-color;
    box-shadow: 0 0 5px $focus-color;
  }
  &:hover {
    border: 1px solid $hover-color;
    box-shadow: 0 0 5px $hover-color;
  }
  &::placeholder {
    color: $placeholder-color;
  }
}

/* HIDE SPINNER ON TYPE IS NUMBER , HIDE CLEAR IN IE 11 */

label + {
  input, textarea {
    margin-top: 20px;
  }
}

/* DISABLED */

input, textarea {
  &:disabled {
    border: 1px solid $disabled-gray-color !important;
    background-color: $white !important;
    color: $pebble-grey !important;
    cursor: default !important;
    &::placeholder {
     color: $placeholder-color;
    }
    &:hover {
      box-shadow: none;
      border: 1px solid $disabled-gray-color !important;
    }
  }
}

/* ERROR */

input {
  &[type="text"].error, &[type="number"].error, &[type="tel"].error, &[type="password"].error,&[type="email"].error {
    background-color: $error-bg-red;
    border: 1px solid $alertErrorLeft;
    &::-webkit-input-placeholder, &::-moz-placeholder, &:-moz-placeholder, &:-ms-input-placeholder {
      color: $disabled-grey;
    }
    & + .text-danger{
      p, span{
        display: inline-block;
        color: $alertErrorLeft !important;
      }
      &:before{
        content: "\0021";
        background: $alertErrorLeft;
        color: $white;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        margin-right: 5px;
        line-height: 18px;
      }
    }
  }
  &[type="text"].error:focus, &[type="number"].error:focus, &[type="tel"].error:focus, &[type="password"].error:focus, &[type="email"].error:focus {
    border: 1px solid $alertErrorLeft;
    box-shadow: 0 0 0px $alertErrorLeft;
    &::-webkit-input-placeholder, &::-moz-placeholder, &:-moz-placeholder, &:-ms-input-placeholder {
      color: $accordion-black;
    }
  }
  &[type="text"].error:hover, &[type="number"].error:hover, &[type="tel"].error:hover, &[type="password"].error:hover, &[type="email"].error:hover {
    &::-webkit-input-placeholder, &::-moz-placeholder, &:-moz-placeholder, &:-ms-input-placeholder {
      color: $accordion-black;
    }
  }

}

textarea{
  &.error {
    background-color: $error-bg-red;
    border: 1px solid $alertErrorLeft;
    & + .text-danger{
      p, span{
        display: inline-block;
        color: $alertErrorLeft !important;
      }
      &:before{
        content: "\0021";
        background: $alertErrorLeft;
        color: $white;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        margin-right: 5px;
        line-height: 18px;
      }
    }
  }
  &.error:focus {
    border: 1px solid $alertErrorLeft;
    box-shadow: 0 0 0px $alertErrorLeft;
  }
}

.text-danger {  
  color: $alertErrorLeft !important;
  &.form-error{
    p, span{
      display: inline-block;
    }
    &:before{
      content: "\0021";
      background: $alertErrorLeft;
      color: $white;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      margin-right: 5px;
      line-height: 18px;
    }
  }
}

/* MOBILE */
@media screen and (max-width: $screen-mobile) {
  input {
    &[type="text"], &[type="number"], &[type="tel"], &[type="password"] {
      width: 100%;
    }
  }
  textarea {
    width: 100%;
  }
  /* decrease field width to fit the help icon */
  .has-help {
    width: calc(100% - 30px) !important;
  }
  .accordion-trigger-xs.accordion-trigger-open-xs {
    input {
      &[type="text"]:disabled, &[type="number"]:disabled, &[type="tel"]:disabled, &[type="password"]:disabled {
        border: 1px solid $disabled-gray-color !important;
      }
    }
    textarea:disabled {
      border: 1px solid $disabled-gray-color !important;
    }
  }
}
