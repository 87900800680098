#multiaccount, #hugeaccount {
  padding-bottom: 20px;
}

@media (max-width: $screen-mobile) {
  #multiaccount div div span, #hugeaccount div div span {
    padding-bottom: 10px;
  }
  #hugeaccountautocompletediv #hugeaccautocomplete, #multiaccount #multiaccountdropdown {
    padding-top: -10px;
    margin-left: 15px;
  }
  #hugeaccountautocompletediv input[type="text"] {
    width: 97.15%;
  }
  #hugeaccount div.col-xs-12.col-md-12 {
    padding-left: 0px;
  }
  #multiaccount {
    div.col-xs-12.col-md-12 {
      padding-left: 0px;
    }
    #multiaccountdropdowndiv select {
      width: 97.15%;
    }
  }
  #accountdatapannel .pull-right div span {
    padding: 0;
  }
}

@media (min-width: $screen-desktop) {
  span#accountdatapanneltxt3 {
    position: relative;
  }
  select#multiaccountdropdown {
    width: 32%;
    margin-bottom: 20px;
  }
  input#hugeaccautocomplete {
    width: 87%;
  }
}

/* SELECTED ACCOUNT */

#accountdatapannel {
  padding-bottom: 20px;
  line-height: 2em;
  div {
    background: $white;
  }
}

.account {
  background: $white;
}

#accountdatapannel div #selectaccount {
  background: $disabled-gray none repeat scroll 0 0;
  padding-left: 10px;
  color: $white;
}

.account {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  cursor: pointer;
  margin-bottom: 20px;
  -webkit-box-shadow: inset 0px 0px 0px 1px $disabled-gray;
  -moz-box-shadow: inset 0px 0px 0px 1px $disabled-gray;
  box-shadow: inset 0px 0px 0px 1px $disabled-gray;
  &.selected {
    -webkit-box-shadow: inset 0px 0px 0px 3px $link-color;
    -moz-box-shadow: inset 0px 0px 0px 3px $link-color;
    box-shadow: inset 0px 0px 0px 3px $link-color;
  }
}

#accountdatapannel div span, .account div span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

/* MOBILE */
@media screen and (max-width: $screen-mobile) {
  #accountdatapannel div {
    background: $light-gray;
    #selectaccount {
      border: 1px solid $black;
    }
  }
}

/***********************************/

/* MOBILE */
@media screen and (max-width: $screen-mobile) {
  .accountlookup .radio-box.centered {
    width: inherit;
  }
}

/* FIX FOR G & P CUTTING OFF */

.accountlookup {
  .account span {
    line-height: normal;
  }
  div#pagingWrapper:not(:empty) {
    padding-bottom: 20px;
  }
}

/* CUSTOMIZE */
.selected .underlay {
  background: $link-color !important;
  display: block !important;
}

#returnlink2to6 {
  font: 12pt/1.125em;// "open_sansregular",Arial,Helvetica,sans-serif
}

.accountLookup {
  margin-left: 34%;
  margin-top: 1em;
}