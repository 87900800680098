/* INFO TOOLTIP */

#tooltip {
  text-align: left;
  color: $white;
  // background: $link-color;
  background: $tooltip-color;
  position: absolute;
  z-index: 100;
  padding: 15px;
  border-radius: 5px;
  &:after {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    // border-top: 10px solid $link-color;
    border-top: 10px solid $tooltip-color;
    content: '';
    position: absolute;
    left: 50%;
    bottom: -10px;
    margin-left: -10px;
  }
  &.top:after {
    border-top-color: transparent;
    // border-bottom: 10px solid $link-color;
    border-bottom: 10px solid $tooltip-color;
    top: -20px;
    bottom: auto;
  }
  &.left:after {
    left: 10px;
    margin: 0;
  }
  &.right:after {
    right: 10px;
    left: auto;
    margin: 0;
  }
}


.CBTooltip { 
  .tooltip-inner {
  // font-family: open_sansregular;
  // background-color: $link-color;
  background-color: $tooltip-color !important;
  color: $white;
  text-align: left !important;
  border-radius: 0px;
 }
// .tooltip-arrow::before, .tooltip-arrow {
//   border-right-color: $tooltip-color !important;
//   border-left-color: $tooltip-color !important;
// }
&.show{
  opacity: 1 !important;
  margin-top: -8px;
}
}
.wide-tooltip .tooltip-inner {
  // background-color: $link-color;
  background-color: $tooltip-color;
  color: $white;
  min-width: 700px;
}


/*******************************/

/* ERROR TOOLTIP */

#tooltip.alert {
  background: $red;
  &:after {
    @include border-top(10px,solid,$red);
  }
  &.top:after {
    @include border-bottom(10px,solid,$red);
  }
}
/**************************************/


/* ERROR */

div.error {
  color: $black;
  position: relative;
  background: $error-bg-red;
  border: 1px solid $alertErrorLeft;
  margin-bottom: 20px;
  padding: 10px;
  overflow: visible !important;
  /* HANDLE MODAL OVERFLOW HIDDEN */
  float: left;
  width: 100%;
  &:after, &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(255, 239, 240, 0);
    border-bottom-color: $error-bg-red;
    border-width: 9px;
    margin-left: -9px;
  }
  &:before {
    border-color: rgba(245, 0, 0, 0);
    border-bottom-color: $alertErrorLeft;
    border-width: 10px;
    margin-left: -10px;
  }
  p {
    background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='30px' height='30px' viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 54.1 (76490) - https://sketchapp.com --%3E%3Ctitle%3E8EB0A3B6-2D4E-47DA-8076-63DAD0E8D79D%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='My-Account-Image-Library' transform='translate(-16.000000, -490.000000)' fill-rule='nonzero'%3E%3Cg id='Default-Tooltip' transform='translate(16.000000, 490.000000)'%3E%3Cg id='Icon'%3E%3Cpath d='M15,30 C6.71875,30 -1.45848246e-13,23.28125 -0,15 C-1.45848246e-13,6.71875 6.71875,0 15,0 C23.28125,0 30,6.71875 30,15 C30,23.28125 23.28125,30 15,30 Z' id='Shape' fill='%23285A93'%3E%3C/path%3E%3Cg id='%3F' transform='translate(10.000000, 8.000000)' fill='%23FFFFFF'%3E%3Cpolygon id='Rectangle-path' points='3.76566667 12.0785185 6.25533333 12.0785185 6.25533333 14.4927407 3.76566667 14.4927407'%3E%3C/polygon%3E%3Cpath d='M5.01111111,0.00859259259 C2.25988889,0.00859259259 0.033,2.16918519 0.033,4.83585185 L2.52266667,4.83585185 C2.52266667,3.50844444 3.64222222,2.42162963 5.01233333,2.42162963 C6.38122222,2.42162963 7.502,3.50725926 7.502,4.83585185 C7.502,7.25007407 3.76688889,6.94785185 3.76688889,10.8708148 L6.25655556,10.8708148 C6.25655556,8.15555556 9.99044444,7.85333333 9.99044444,4.83585185 C9.98922222,2.16918519 7.76233333,0.00859259259 5.01111111,0.00859259259 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") 0 0 no-repeat;
    padding: 0 5px 0 31px;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
}

.text-right div.error {
  float:right
}

.has-prefix + div.error {
  width: 90%
}

// Billing due date tooltip
.due-date-alert .tooltip-inner  {
  background-color: $due-date-alert;
  color: $white;
}

.due-date-alert .tooltip-arrow::before, .tooltip-arrow {
  border-right-color: $due-date-alert !important;
  border-left-color: $due-date-alert !important;
}

.due-date-alert.bs-tooltip-right .tooltip-arrow::before, .tooltip-arrow {
  border-right-color: $due-date-alert !important;
  border-left-color: $due-date-alert !important;
}


ngb-tooltip-window {
  div.arrow::before {
    border-top-color: $tooltip-color;
  }
  div.tooltip-inner {
    background-color: $tooltip-color !important;
    // font-family: open_sansregular;
    color: $white;
    text-align: left !important;
    border-radius: 0px;
    }
  }
  .bs-tooltip-top .arrow {
    margin-left:0px !important;
  }
  .bs-tooltip-top .arrow::before {
    border-top-color: $tooltip-color !important;
  }
  .bs-tooltip-right .arrow::before{
    border-right-color: $tooltip-color !important;
  }
  .bs-tooltip-left .arrow::before{
    border-left-color: $tooltip-color !important;
  }
  .bs-tooltip-bottom .arrow::before{
    border-bottom-color: $tooltip-color !important;
  }
.fa-cb-info {
  color: $link-color;
  font-size: 1.5rem;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='24px' height='24px' viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 54.1 (76490) - https://sketchapp.com --%3E%3Ctitle%3E8EB0A3B6-2D4E-47DA-8076-63DAD0E8D79D%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='CBMA-UI' transform='translate(-16.000000, -419.000000)' fill-rule='nonzero'%3E%3Cg id='Default-Tooltip' transform='translate(16.000000, 419.000000)'%3E%3Cg id='Icon'%3E%3Cpath d='M15,30 C6.71875,30 -1.45848246e-13,23.28125 -0,15 C-1.45848246e-13,6.71875 6.71875,0 15,0 C23.28125,0 30,6.71875 30,15 C30,23.28125 23.28125,30 15,30 Z' id='Shape' fill='%23285A93'%3E%3C/path%3E%3Cg id='%3F' transform='translate(10.000000, 8.000000)' fill='%23FFFFFF'%3E%3Cpolygon id='Rectangle-path' points='3.76566667 12.0785185 6.25533333 12.0785185 6.25533333 14.4927407 3.76566667 14.4927407'%3E%3C/polygon%3E%3Cpath d='M5.01111111,0.00859259259 C2.25988889,0.00859259259 0.033,2.16918519 0.033,4.83585185 L2.52266667,4.83585185 C2.52266667,3.50844444 3.64222222,2.42162963 5.01233333,2.42162963 C6.38122222,2.42162963 7.502,3.50725926 7.502,4.83585185 C7.502,7.25007407 3.76688889,6.94785185 3.76688889,10.8708148 L6.25655556,10.8708148 C6.25655556,8.15555556 9.99044444,7.85333333 9.99044444,4.83585185 C9.98922222,2.16918519 7.76233333,0.00859259259 5.01111111,0.00859259259 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
}

.tooltip-inner {
  font-family: $default-font-family;
}

.cb-tooltip-close-btn {
  background-image: url("~assets/images/close_white.svg");
  // background-repeat:  no-repeat;
  // background-position: 0 0;
  // display: inline-block;
  // position: relative;
  // top: 5px;
  padding: 12px;
}
