// WHITE
$white: #ffffff;

// BONE WHITE
$bone-white: #c3c3c3;
$contrast-bone-white: #e6e7e8;
$tone-bone-white: #cecece;

// BLACK
$black: #000;
$accordion-black: #202020;
$card-text-black: #455051;

// NICE GRAY
$gray: #BDBDBD;
$dark-gray: #869199;
$dark-gray-capp: #828E96;
$platinum-gray: #797979;
$light-blackish-gray:#0000000d;

// LIGHT GRAY
$light-gray: #f4f4f4;
$white-smoke: #EFEFEF;
$american-silver: #d4d4ca;
$pebble-grey :#6C7880;
$very-light-gray: #f2f2f2;

// DISABLED GRAY
$disabled-gray: #979798;
$disabled-gray-color: #b9c9d2;
$diabled-gray-faded: rgba(185, 201, 210, 0.15);

// BORDER-GRAY
$gray-3: #9a9a9a;
$gray-10: #bbbbbb;
$gray-15: #F5F3F0;
$border-gray: #979797;
$border: #b9c9d2;

// GRAY-20
$gray-20: #333333;

// PICKLIST
$picklist-header: #828e96;
$picklist-text: #FFFFFF;
$picklist-border: #828e96;
$gray-picklist: #d5d5d5;
$text-color: #222;
$bg-picklist: #eee;
$highlight-bg: #186ba0;

// ACCORDION DISABLED COLOR
$disabled-accordion-color: #767676;
$disabled-accordion-gray: #454F51;

$bg-accordion-open: #E5E5E5;
$dbtp-accordion: #313131;

// HYPER LINK COLOR
$link-color: #0679ca;
$gray-link-color: #252525;
$link-color-disabled: #dadadc;

$dark-link-color: #03406a;

$link-focus-color: #146ea6;
$focus-color: #028399;
$hover-color: #0F155B;
$placeholder-color: #b3b3b3;

//RADIO
$light-black : #455051;
$checked-color : #0F155B;
$disabled-grey : #cad6dd;

// ERROR RED
$red: #bf1b1b;
// $error-red: #d8544c;
$error-bg-red: #fbeeed;
$error-border: #E8A19C;
$equipment-red: #FF0000;

$due-date-alert: #bd170f;

// GREEN
$green: #00892d;
$error-green-bg: #e5f3ea;
$ocean-green: #027F94;

// BLUE
$blue: #4bb2d9;
$error-blue-bg: #edf7fb;
$cyan-blue: #285A93;
$light-blue: #7eb3d4;
$dark-blue: #0E669C;
$pale-blue: #F2F9FA;
$light-skyblue: #80D5F9;
$deep-skyblue: #0076AB;
$deep-skyblue-shadow: rgba(0, 118, 171, 0.05);
$sapphire-blue: #0065b6;
$endeavour-blue: #0051a5;
$dark-cyan-blue: #003e94;
$light-grayish-blue:#d5f1fd;
$north-star-blue: #4C5295;
$light-grayish-blue:#d5f1fd;
$alice-blue: #F3F7FA;

// YELLOW
$yellow: #e5af28;
$yellow-error-bg: #fefbf4;
$button-color: #002F87;
$disabled-grey: #B9C9D2;
$sec-btn-gradient-from-color: #1ab3f0;
$amber: #FFBE00;

//TAB
$tab-underline-color: #0063cb;
$nav-tab-color: #828e96;
$tab-font: #202020;
$link: #285A93;

//Light Gray MODAL HEADER
$gray-modal-header: #EBEFF0;
$dark-grey: #E4E4E4;
$light-grey: #e0dcdc;
$vertical-line-color: #d4d1d1;
$hr-color: #D3D3D3;
$modal-shadow: rgba(0, 0, 0, 0.5);

// TABLE
$secondary-dataTable-border-top: #e0dacb;
$datatable-disable-color: #ececec;
$table-header-color: #285A93;
$datatable-pager-disabled: #6C6C6D;
$datatable-row-border-bottom: #dee2e6;
$datatable-row-bottom: #c1cdd5;
$datatable-row-even-background-color: #6C6C6D;

$alertErrorLeft: #C63C34;
$alertSuccessLeft: #017F58;
$alertSuccessBorder: #EEF8F5;
$alertWarningLeft: #FF9E25;
$alertWarning: #FFF8ED;
$tab-font: #202020;
$tooltip-color: #002F87;
$infoalertWarning: #009AE0;
$infoalertBgColor: #EDF8FD;

//$error-red: #FCF4F3;
$grey: #ccc; //grey
$grey_background: #F4F8F9; //grey-background
//navy-blue
$navy-blue: #045690;
$alertInfoBorder: #009AE0;
$deepSkyBlue: #0076AB;
$alertInfo: #EDF8FD;

// White
$whited1: #d1d4d7;

$black21: #212529;

$blue00 : #004b91;
$button-shadow: rgba(0, 0, 0, 0.3);
$whitee2 :#e2dfd7;
$blue35 :#356499;

$greenBgColor :#00892d;

// theme colors
$b-theme-primary-color: $link-color;
$r-theme-primary-color: $cyan-blue;

$modal-footer: #d8d8d0;
$red-brick: #dc3545;
$silver: #C0C0C0;

//headergrdient colors
$header-left: #009EF4;
$header-right: #002F87;
$hover-button: #00AAF4;
$grey80: #808080;

// Default Header
$default-header-grey: #F2F6F7;
$text-gray-color: #7b7b7d;

$p-text-hover-color: #3f4b5b;
$p-text-focus-color: #8dd0ff;
$p-text-box-shadow-color: #e3f3fe;