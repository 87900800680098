@import "assets/styles/blueprint/components_7.1/components/shared/variables";
@import "assets/styles/blueprint/components_7.1/components/shared/colors";
#okta-sign-in {
    overflow: hidden;
    min-height: 303px;
    .button, .button.link-button {
        border-radius: 0px;
    }

    .default-custom-button {
        background: none;
        font-family: $default-font-family;
    }
    .default-custom-button.link-button {
        border: none;
        color: $blue00;
        cursor: pointer;
        line-height: 3.3 !important;
        font-weight: bolder;

        &:focus, &:focus-visible {
            border: 1px solid $button-color;
            background-color: transparent;
        }

        &:hover {
            background: none;
        }
    }

    // MFA widget changes started
    // Main Div contains okta widget
    .o-form-theme {
        padding: 0px 14px 0px;
        margin-bottom: 14px;
    }

    // Style for the icon Images
    .enroll-factor-row .enroll-factor-icon-container {
        border: none;
        position: absolute;
    }

    // Enrollf factor Label
    .enroll-factor-row .enroll-factor-label {
        margin-left: 32px;
    }

    // horizontal line after each factor
    .enroll-factor-row {
        border-bottom: 1px solid !important;
        border-color: $hr-color !important;
        padding-bottom: 0.75rem;
        margin-bottom: 1rem;
    }

    //Sizing Factor Icons
    .enroll-factor-row .enroll-factor-icon {
        width: 23px;
        height: 23px;
        border: none;
        border-radius: 0%;
        background-size: 23px 23px;
    }

    .form-divider {
        margin-bottom: 8px;
        padding-top: 8px;
    }

    .enroll-factor-row .success-16-green {
        margin-right: 0.75rem;
    }

	.o-form-explain {
        padding-bottom: 0.5rem;
        border-bottom: 1px solid !important;
        border-color: $hr-color !important;

        &.okta-form-input-error {
            border-bottom: 0px !important;
        }
    }
    .beacon-container {
        margin-bottom: 3.55rem;

        .factors-dropdown-wrap  {
            .dropdown.more-actions {
                .options-wrap {
                    max-height: 205px;
                    left: -202px;
                    width: 297px;
                }
            }
        }
    }

    .auth-beacon {
        border-radius: 0%;
    }

    .auth-beacon-border {
        border: none;
    }

    .factor-icon {
        border: none;
        border-radius: 0%;
    }

    .enroll-factor-list .list-title {
        display: none;
    }

	.enroll-factor-list {
        margin-bottom: 1rem;
    }
	
    .enroll-factor-row .mfa-okta-sms {
        background-image: url('~assets/images/blueprint/icons/sms.svg') !important;
    }
    .mfa-okta-sms {
        background-image: url('~assets/images/blueprint/icons/sms.svg') !important;
    }
    .mfa-sms-30 {
        background-image: url('~assets/images/blueprint/icons/sms.svg') !important;
    }
    .enroll-factor-row .mfa-okta-call {
        background-image: url('~assets/images/blueprint/icons/call.svg') !important;
    }
    .mfa-okta-call {
        background-image: url('~assets/images/blueprint/icons/call.svg') !important;
    }
    .mfa-call-30 {
        background-image: url('~assets/images/blueprint/icons/call.svg') !important;
    }
    .auth-container .mfa-okta-email {
        background-image: url('~assets/images/blueprint/icons/email.svg') !important;
    }
    .mfa-okta-email {
        background-image: url('~assets/images/blueprint/icons/email.svg') !important;
    }
    .mfa-email-30 {
        background-image: url('~assets/images/blueprint/icons/email.svg') !important;
    }

    .enroll-factor-description {
        width: 100%;
    }

    .enroll-factor-description > p {
        display: none;
    }


    .auth-footer {
        margin-bottom: 16px;
        padding-left: 14px;
    }
    .auth-footer .link,
    .auth-footer .link:hover,
    .auth-footer .link:active,
    .auth-footer .link:link,
    .auth-footer .link:visited {
        color: $link-color;
        text-decoration: underline;
        font-weight: bolder;
        font-size: larger;
    }

    .enroll-factor-description {
        position: relative;
        
    }

    // Setup Button styling
    .enroll-factor-row .enroll-factor-button .button {
        background: $button-color;
        border-radius: 0;
        border: solid 3px $button-color;
        width: 100%;
        color: $white;
        font-weight: bold;
        text-align: center;
        padding: 0.25rem 1rem;
        height: 40px;
    }

    //SMS Phone No styling
    .enroll-sms .enroll-sms-phone {
        width: 100%;
        float: none;
    }

    //Send Code/Call Button styling
    .enroll-sms .sms-request-button {
        float: none;
        width: 100%;
        padding: 0;
    }

    //Call Phone No styling
    .enroll-call .enroll-call-phone {
        width: 100%;
        float: none;
    }

    //Call Extension styling
    .enroll-call .enroll-call-extension {
        float: none;
        width: 100%;
        margin-top: 0;
        padding: 0;
    }

    //SMS Enter Code
    .mfa-verify-passcode .auth-passcode {
        float: none;
        width: 100%;
        margin-top: 5.5em;
    }        

    .auth-container .button {
        background: $button-color;
        border-radius: 0;
        border: solid 3px $button-color;
        width: 100%;
        background: $button-color;
        color: $white !important;
        font-weight: bold;
        text-align: center;
        padding: 0.5rem 1rem;
        height: auto;
    }

    .link-button-disabled {
        cursor: not-allowed !important;
        opacity: .35;
        box-shadow: none !important;
    }
    

    .mfa-verify-passcode .sms-request-button, .mfa-verify-passcode .call-request-button, .mfa-verify-passcode .email-request-button {
            position: absolute;
            bottom: 16em;
            width: 91%;
        } 

        .mfa-verify-passcode { 
            .o-form-error-container {
                + .o-form-fieldset-container {
                    margin-top: 4em !important;
                }
            }  
        }

    .sms-request-button, .email-request-button {
        display: inline-block;
        background: -webkit-gradient(linear,left top, left bottom,from($button-color),to($button-color)) !important;
        color: $white !important;
        height: 40px;
        line-height: 36px;
        padding: 0 15px;
        border: 1px solid;
        // border-radius: 3px;
        font-size: 13px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        float: none !important;
        text-align: center;
        margin-top: 0px !important;
        align-items: center !important;
        justify-content: center !important;
    }

    .button-primary {
        background: -webkit-gradient(linear,left top, left bottom,from($button-color),to($button-color)) !important;
        color: $white !important;
    }

    .button-primary[value = "Done"] {
        color: $button-color !important;
        border: 3px solid;
        border-image-slice: 1;
        background: $white !important;
        background-color: transparent !important;
        border-image-source: linear-gradient(90deg,$sec-btn-gradient-from-color,$button-color);
        font-weight: bolder;    }

    //Set up Email Authentication, Send me the code button
    .o-form-button-bar {
        padding: 0px 14px;
        &.focused-input{
            box-shadow: none;
        }
    }

    .o-form-button-bar .button {
        height: 40px;
        border-radius: 0px;
    }

    a .link .help .js-back {
        display: inline-block;
        /* height: 40px; */
        line-height: 36px;
        padding: 0 15px;
        border: 1px solid;
        // border-radius: 3px;
        font-size: 13px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        float: none !important;
        width: 96%;
        text-align: center;
    }

    .call-request-button {
        display: inline-block;
        background: -webkit-gradient(linear,left top, left bottom,from($button-color),to($button-color)) !important;
        height: 40px;
        line-height: 36px;
        padding: 0 15px;
        border: 1px solid;
        // border-radius: 3px;
        font-size: 13px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        float: none !important;
        text-align: center;
        margin-top: 0px !important;
        align-items: center !important;
        justify-content: center !important;
        color: $white !important;
    }
    input[type=tel] {
        border: 1px solid !important;
		font-family: $default-font-family !important;
		font-size: 13px !important;
    }

    .mfa-verify-passcode .link-button {
        margin-top: 0px;
        margin-bottom: 10px;
        color: $white !important;
    }

    .o-form {        
        .infobox.login-timeout-warning, .resend-email-infobox .infobox.infobox-warning {
            background-color: $alertInfo;
            @include border-top(1px, solid, $alertInfo);
            @include border-bottom(1px, solid, $alertInfo);
            border-left-width: 10px;
            border-left-color: $alertInfoBorder;
            border-right: 0;  
            margin-top: 2.5em;

            >.icon {
                background:  url("~assets/images/blueprint/icons/info.png") no-repeat;
                width: 40px;
                height: 40px;
                left: 1em;
                top: 1.3em;   
            }
          }
          
          .infobox.login-timeout-warning>.icon:before , .resend-email-infobox .infobox.infobox-warning>.icon:before {
            display: none;
        }

        .infobox.login-timeout-warning:before, .resend-email-infobox .infobox.infobox-warning:before {
            display: none;
        }

        .infobox-warning.login-timeout-warning:before {
            padding-left: 3em;
            padding-top:0.9em;
            padding-bottom: 0.9em;
        
        }

        .o-form-fieldset.margin-btm-0 {
            margin-top:-9px;
        }
    }

}
#sign-in-widget {
    .custom-checkbox {
        input[type="checkbox"] + label:before {
            background-image: none;
            width: 5px;
            height: 0;
            margin: 0;
            border:none;
        }   
        box-shadow: none;
        font-weight: 700;
        font-size: $default-font-size;
        font-family: $default-font-family;
    }
    #okta-sign-in {
        .o-form {
            .custom-checkbox {
                label {
                    font-size: $default-font-size;
                    font-family:  $default-font-family;
                }
            }
        }
    }
} 
#sign-in-widget {
    #okta-sign-in {
      width: auto;  
      border: none;
      margin-top: 0px;
      padding-left: 1px;
      box-shadow: none;
      .margin-btm-30 {
        margin-bottom: 20px !important;
    }
      .margin-btm-5 {
        margin-bottom: 30px !important;
      }
      .auth-content{
          padding: 0;
          max-width: none;
      }
      .help-links li {
          margin-top: 0px !important;
      }
      #okta-signin-submit{
        background:$button-color;
        width: 93.7px;
        height: 46.99px;
        font-family: $default-font-family;
        text-align: center;
        border: 3px solid $button-color;
        color: $white;
        border-radius: 0px;
        font-size: $default-font-size;
        margin-left: 60px;
        &:hover {
            box-shadow: 0 3px 5px rgba(0,0,0,.3);
        }
      }
      .o-form {
          .input-fix {
            border-radius: 0px;
            border: none;
            input[type=text], input[type=password] {
                border: 1px solid $black;
                font-family: $default-font-family;
                &:hover {
                    border: 1px solid $hover-color;
                    -webkit-box-shadow: 0 0 5px $hover-color;
                    box-shadow: 0 0 5px $hover-color;
                }
                &:focus {
                    border: 1px solid $focus-color;
                    -webkit-box-shadow: 0 0 5px $focus-color;
                    box-shadow: 0 0 5px $focus-color;
                }
            }
          }
    }
    .o-form-label label{
          font-size: $default-font-size;
          font-family: $default-font-family;
          font-weight: bolder;
    }
    .auth-divider {
        display: none;
    }
    .primary-auth-container {
        position: absolute;
        margin-left: 190px;
        margin-top: -102px;
        font-weight: bolder;
    }
    .js-help-links {
        display: block !important;
    }
    .js-forgot-password, .js-help-link {
        display: none !important;
    }
    .js-custom {
        color: $gray-link-color;
        cursor: none;
        text-decoration: none;
        left: 111px;
        position: relative;
        top: -86px;
    }
    .visibility-16:before, .visibility-off-16:before {
        color: $black;
    }
    .btn-Register {
        margin-top: -64px;
        font-family: $default-font-family;
        font-size: $default-font-size;
        margin-left: 16px;
        margin-bottom: 8px;
    }
    .btn-customOr {
        color: $gray-link-color;
        margin-left: -36px;;
        font-size: $default-font-size;
        margin-top: 36px;
        margin-bottom: 16px;
        pointer-events: none;
    }
    .btn-ForgotPassword {
        font-weight: bolder;
        font-family: $default-font-family;
        font-size: $default-font-size;
        margin-left: 10px;
	    padding-top: 1.5rem;
    }
    .password-expired .o-form-button-bar {
        margin-right: 0%;
        padding: 0 13px 0 13px;
    }
    .password-expired .o-form-button-bar input.button.button-primary{
        background-image: linear-gradient(#002F87, #002F87);
    }
    input::-ms-clear, input::-ms-reveal {
        display: none;
    }
    .visibility-16:before {
        font-size: 22px;
     }
     .visibility-off-16:before {
         font-size: 22px;
     }
     .o-form .input-fix .password-toggle .eyeicon {
      top: 40%;
     }
     input[type="checkbox"]  {
         + label {
            &:before {
                border: 2px solid $gray-link-color;
                margin: -3px 12px 8px 0;
                height: 24px;
                width: 24px;
                border-radius: 3px;
                background-color: $white;
            }
        }
        &:hover {
            &+ label {
                &:before {
                    border-color: $cyan-blue;
                }
            }
        }
        &:checked {
            &:hover {
                &+ label {
                    &:before {
                        border-color: $cyan-blue;
                    }
                }
            }
            + label {
                &:before {
                    background-image: url("~assets/images/confirmation_check_white.svg");
                    background-position: center;
                    background-color: $checked-color;
                    color: $white;
                    border-color: $gray-link-color;
                    padding-top: 2px;
                }
            }
        }
    }

    .custom-checkbox label {
         padding: 7px 10px 7px 0px;
     }
     &.no-beacon {
        .okta-sign-in-header {
            display: none;
        }
     }
    }
}

//this media query is explicitly for xs devices
@media screen and (max-width: 370px) {
    #sign-in-widget {
        #okta-sign-in {
            .auth-content{
                width: 85%;
                margin: 0;
            }
            .primary-auth-container{
                margin-left: 140px !important;
            }
            #okta-signin-submit{
                margin-left: 15px;
            }
        }
    }
}