/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
@import "../node_modules/bootstrap/scss/_functions";
@import "../node_modules/bootstrap/scss/_variables";
@import "./assets/styles/blueprint/components_7.1/components/shared/colors";
@import "./assets/styles/blueprint/components_7.1/components/shared/variables";
@import "./assets/styles/blueprint/components/shared/mixins/vendor-prefixes";
// Theme customization
@import "./assets/styles/blueprint/components_7.1/components/fonts/theme-fonts";
@import "theme/theme-variables";
@import "./assets/styles/blueprint/components_7.1/components/shared/functions";
@import "./assets/styles/blueprint/components_7.1/components/shared/mixins/utilities";
@import "./assets/styles/blueprint/components_7.1/components/input/checkbox";
@import "./assets/styles/blueprint/components_7.1/components/input/text";
@import "./assets/styles/blueprint/components_7.1/components/login/login";
@import "./assets/styles/blueprint/components_7.1/components/input/select";
@import "./assets/styles/blueprint/components_7.1/components/input/radio";
@import "./assets/styles/blueprint/components_7.1/components/input/toggle";
@import "./assets/styles/blueprint/components_7.1/components/modal/modal";
@import "./assets/styles/blueprint/components_7.1/components/ngx-datatable/ngx-datatable";
@import "./assets/styles/blueprint/components_7.1/components/bannercarousel/bannercarousel";
@import "./assets/styles/blueprint/components_7.1/components/datepicker/datepicker";
@import "./assets/styles/blueprint/components_7.1/components/messaging/messages";
@import "./assets/styles/blueprint/components_7.1/components/miscellaneous/common";
@import "./assets/styles/blueprint/components_7.1/components/button/buttons";
@import "./assets/styles/blueprint/components_7.1/components/tooltip/tooltip";
@import "./assets/styles/blueprint/components_7.1/components/accordion/accordion";
@import "./assets/styles/blueprint/components_7.1/components/tabs/tabs";
@import "./assets/styles/blueprint/components_7.1/components/tabs/mat-tab";
@import "./assets/styles/blueprint/components/input/radioboxes";
@import "./assets/styles/blueprint/components/accountlookup/accountlookup";
@import "./assets/styles/blueprint/components/table/table";
@import "./assets/styles/blueprint/components/grid/container";
@import "./assets/styles/blueprint/components/grid/grid";
@import "./assets/styles/blueprint/components/miscellaneous/hyperlink";
@import "./assets/styles/blueprint/components_7.1/components/base/general";
@import "./assets/styles/blueprint/components/miscellaneous/glyphicons";
@import "./assets/styles/blueprint/components_7.1/components/cards/card";
@import "./assets/styles/blueprint/components_7.1/components/multiselect-dropdown/multiselect-dropdown";
@import "./assets/styles/blueprint/components_7.1/components/picklist/p-picklist";
@import "./assets/styles/blueprint/components_7.1/components/circular-spinner/circular-spinner";
// 3rd party libraries
@import "../node_modules/bootstrap/scss/bootstrap";
// @import "~font-awesome/scss/font-awesome.scss";
@import '../node_modules/@swimlane/ngx-datatable/index.css';
@import '../node_modules/@swimlane/ngx-datatable/assets/icons.css';
@import '../node_modules/@swimlane/ngx-datatable/themes/bootstrap.css';
@import '../node_modules/@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
@import "theme/theme";
@import "./assets/styles/blueprint/components_7.1/components/miscellaneous/helper";
@import "./assets/styles/blueprint/components_7.1/components/miscellaneous/redesignhelper";
@import "./assets/styles/blueprint/components_7.1/components/miscellaneous/pagination";
@import "./assets/styles/blueprint/components_7.1/components/miscellaneous/installationSteps";
.ellipsealign {
    vertical-align: middle;
}

.borderBottom {
    border-bottom: 4px solid #dadadc;
    width: 50%;
}

.highlightClass {
    color: #252525 !important;
    text-decoration: underline !important;
    pointer-events: none;
}

.pipeColor {
    color: #252525;
}

.p-mg-8 {
    margin: 8px 0 8px 0 !important;
}

.mg-bt-0 {
    margin-bottom: 0px !important;
}

.pad-rl-0 {
    padding-right: 0px;
    padding-left: 0px;
}

.paymentbackground {
    background-color: $light-gray;
    margin-left: 42px !important;
    margin-right: 0px !important;
}

.lightgraybackground {
    background-color: $light-gray;
}

.whitebackground {
    background-color: white !important;
}

.billing-sectiondivider {
    border-top: 1px solid $datatable-row-bottom;
}

.otp-step3divider {
    position: relative;
    bottom: 13px;
}

.ui-datepicker-prev .fa-angle-left {
    content: url('https://api.iconify.design/fa:angle-left.svg?color=%23FFFFFF&height=15&inline=true');
    vertical-align: -0.143em;
}

.ui-datepicker-next .fa-angle-right {
    content: url('https://api.iconify.design/fa:angle-right.svg?color=%23FFFFFF&height=15&inline=true');
    vertical-align: -0.143em;
}

// #CoxPFHeader {
//     display: none;
// }
