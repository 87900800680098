/*
 Position and hide the real checkboxes and radio buttons.
 The inputs are made transparent instead of completely hidden to preserve
 clickability in browsers that don't have clickable labels.
 */

 input[type="radio"] {
  position: absolute;
  /* Match the image dimensions */
  //display: none;
  width: 24px !important;
  height: 24px !important;
  /* Reset anything that could peek out or interfere with dimensions */
  overflow: hidden;
  margin: -3px 0 0;
  padding: 0;
  outline: 0;
  opacity: 0;
  + label {
    margin-right: 20px;
    &:before {
      display: inline-block;
      margin: -3px 12px 8px 0px;
      /* negative margin to account for focus outer glow */
      background-position: 0 0;
      background-repeat: no-repeat;
      content: " ";
      vertical-align: top;
    }
  }
  &:focus + label:before, &:hover + label:before {
    /* unchecked - focused */
    border: 3px solid $cyan-blue;
  }
  &:checked, &[checked*="checked"] {
    + label:before {
      /* checked */
    background-color: $white;
    border: 8px solid $checked-color;
    }
  //   &:focus + label:before {
  //     /* checked - focused */
  //   border: 3px solid $cyan-blue;
  //   }
  }
  + label:before {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    display: inline-block;
    border: 3px solid $light-black;
    background-color: $white;
  }
  &:disabled {
    + label:before {
      /* unchecked */
      border: 3px solid $disabled-grey;
    }
    &:checked, &[checked*="checked"] {
      + label:before {
        /* checked */
      border: 8px solid $disabled-grey;
      }
      }
    + label:before {
      height: 24px;
      width: 24px;
      border-radius: 50%;
      display: inline-block;
      border: 3px solid $disabled-grey;
    }
  }
  &.error {
    + label:before {
      /* unchecked */
      border: 3px solid $alertErrorLeft;
    }
    &:focus + label:before, &:hover + label:before {
      /* unchecked - focused */
      border: 3px solid $cyan-blue;
    }
    &:checked, &[checked*="checked"] {
      + label:before {
        /* checked */
        background-color: $white;
        border: 8px solid $alertErrorLeft;
      }
      &:focus + label:before {
        /* checked - focused */
        border: 3px solid $cyan-blue;
      }
    }
    + label:before {
      height: 24px;
      width: 24px;
      border-radius: 50%;
      display: inline-block;
      border: 3px solid $alertErrorLeft;
    }
  }
}

/*
 Insert a pseudo element inside each label and give it a background
 image that will become the custom checkbox or radio button.
 Using inline-block lets you use vertical-align to adjust it vertically
 as needed.
 */

/* DISABLED */

/******************/

/* ERROR */

/****************************/

/* 3 RADIO BOXES, NOTE: Text-Overflow is not accounted for */

.radio {
  margin-top: 20px;
  overflow: visible !important;
  input[type="radio"] + label {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
    float: left;
    display: table-cell;
  }
}

/* ERROR */

.default3 input[type="radio"].error + label {
  color: $alertErrorLeft;
}
