ngb-pagination ul.pagination li {
    padding-right: 12px;

    &.page-item a.page-link {
        color: $button-color;
        text-decoration: underline;
        font-weight: bold;
        border: none;
    }

    &.page-item.active a.page-link {
        border: 1px solid $button-color;
        background-color: $button-color;
        color: $white;
    }

    &.page-item.disabled a.page-link {
        color: $datatable-pager-disabled;
        opacity: 0.5;
    }
}


ngb-pagination ul.pagination {
    li.page-item {
        &:first-child {
            display: flex;
            align-items: baseline;
            font-weight: bold;
            a.page-link {
            background-color: $datatable-disable-color;
            border: solid 1px $gray;
            border-radius: 5px 0 0 5px;
            text-decoration: none;
            }

            &::after {
                content: '...';
                color: black;
                padding-left: 5px;
            }
        }

        &:last-child {
            display: flex;
            align-items: baseline;
            font-weight: bold;
            a.page-link {
            background-color: $datatable-disable-color;
            border: solid 1px $gray;
            border-radius: 0 5px 5px 0;
            text-decoration: none;
            }

            &::before {
                content: '...';
                color: black;
                padding-right: 5px;
            }
        }
        a:focus {
            outline: 1px solid $link-color !important;
        }
    }
}


/**Ngx pagination hover & tooltip css*/
@mixin ellipsissize{
    height: 17px;
    width: 33.5px;
}
@mixin table-directions {
    background-color: $datatable-disable-color;
    border: solid 1px $gray;
    padding:5px 0px 3px 0px;
    width: 30px;
    text-align: center;
    text-decoration: none;
}

@mixin no-padding-margin{
    padding: 0 !important;
    margin: 0 !important;
}

@mixin tooltip-alligments{
    .tooltipped {
        position: relative;
      }
      .tooltipped .tooltiptext::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color:  $button-color transparent transparent transparent;
      }
      
      .tooltipped .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: $button-color;
        color: $white;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        left: -45px;
        font-size: 12px;
        bottom: 35px;
      }
      
      .tooltipped:hover .tooltiptext,.tooltipped:focus .tooltiptext {
        visibility: visible;
      }
}

@mixin hide-ellipsis {
    background-size: 20px !important;
            height: 20px;
            width: 33px;
            content: '' !important;
            .tooltipped .tooltiptext {
                visibility: visible;
              }
              span.hideellipse{
                  display: none;
              }
}

ul.ngx-pagination {
    li {
        color:  $black;
        font-weight: 700;
    }
    padding-left: 0px;
    a:hover {
        background-color: $white;
    }

    .current {
        background: $button-color  !important;
    }

    .pagination-previous {
        padding: 0.1875rem 0.625rem 0.1875rem 0rem;
        &.disabled::before {
            margin-right: 0 !important;
            content: url('~assets/images/blueprint/arrow-left-small-blue.svg') !important;
            cursor: not-allowed;
            border-radius: 5px 0 0 5px;
            @include table-directions;
        }

        a {
            @include no-padding-margin;
            &::before {
                margin-right: 0 !important;
                content: url('~assets/images/blueprint/arrow-left-small-blue.svg') !important;
                border-radius: 5px 0 0 5px;
                @include table-directions;
            }
        }
    }

    .pagination-next {
        padding: 0.1875rem 0rem 0.1875rem 0.625rem;
        &.disabled::after {
            margin-left:  0 !important;
            content: url('~assets/images/blueprint/arrow-right-small-blue.svg') !important;
            cursor: not-allowed;
            border-radius: 0 5px 5px 0;
            @include table-directions;
        }

        a{
            @include no-padding-margin;
            &::after {
            margin-left: 0 !important;
            content: url('~assets/images/blueprint/arrow-right-small-blue.svg') !important;
            border-radius: 0 5px 5px 0;
            @include table-directions;
        }
    }
    }

    li:nth-child(4).ellipsis {
        @include ellipsissize;
        a:hover,a:focus {
            background: url('~assets/images/blueprint/jump-backward.svg') 8px 0px no-repeat !important;
            @include hide-ellipsis;
        }
        @include tooltip-alligments;
    }

    li:nth-last-child(3).ellipsis{
        @include ellipsissize;
        a:hover,a:focus{
            background: url('~assets/images/blueprint/jump-forward.svg')  8px 0px no-repeat !important;
            @include hide-ellipsis;
        }
        @include tooltip-alligments;
    }
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager.client-side-pagination ul li {
    margin-left: 0px !important;
    margin-right: 0px !important;
    background-color: transparent;
    border: none;

    &.disabled {
        a {
            opacity: 1;
            color: $black;

            .datatable-icon-left, .datatable-icon-right {
                opacity: 0.2;
            }
        }
    }

    &:hover {
        background-color: transparent;
        border: none;
    }
    &.pages {
        margin: 0px 10px 0px 10px;
        &.active {
            border: 1px solid $button-color;
            background-color: $button-color;
            a {
                color: $white !important;
                border: 1px solid $button-color;
                background-color: $button-color;
            }
        }
        a {
            color: $black;
            display: block;
            border-radius: 0;
            text-decoration: none;
        }
    }

    .datatable-icon-right {
        padding: 0px !important;
        &::before {
            content: '...';
            color: $black;
            padding-left: 5px;
            font-weight: 700 !important;
        }
    }

    .datatable-icon-left {
        padding: 0px !important;
        &::before {
            content: '...';
            color: $black;
            padding-right: 5px;
            font-weight: 700 !important;
        }
    }

    .datatable-icon-skip::before {
        margin-left: 0 !important;
        content: url('~assets/images/blueprint/arrow-right-small-blue.svg') !important;
        border-radius: 0 5px 5px 0;
        padding:8px 7px 8px 7px !important;
        @include table-directions;
    }

    .datatable-icon-prev::before {
        margin-right: 0 !important;
        content: url('~assets/images/blueprint/arrow-left-small-blue.svg') !important;
        border-radius: 5px 0 0 5px;
        padding:8px 7px 8px 7px !important;
        @include table-directions;
    }
}

