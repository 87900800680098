.card{
    @each $theme, $map in $themes {
      .#{$theme} & {
            padding: $default-spacing;
            position: relative;
            @include card(
                element-type($map, default-card, 'background-color'),
                element-type($map, default-card, 'border-radius'),
                element-type($map, default-card, 'border'),
                element-type($map, default-card, 'min-height')
            );
            @each $card-name, $card in $card-types {
                &.#{$card-name}{
                    @include card(
                        element-type($card-types, $card-name, 'background-color'),
                        element-type($card-types, $card-name, 'border-radius'),
                        element-type($card-types, $card-name, 'border'),
                        element-type($card-types, $card-name, 'min-height')
                    );     
                } 
            }
            // overriding bootstrap default styling
            .card-header{
                padding: 0;
                background: transparent;
                color: inherit;
                border: 0;
                h3{
                    margin-bottom:0;
                }
            }
        }
    }
}
