    .justify-content-center {
        justify-content: center;
    }

    .defocus-content {
        position: relative;
        opacity: 0.20;
    }

    .justify-content-flexEnd {
        justify-content: flex-end;
    }

    .linkUnderline {
        text-decoration: underline !important;
    }

    .padding-right-0-25-px {
        padding-right: 0.25px;
    }

    .pad-right-7-5-imp {
        padding-right: 7.5em !important;
    }

    .pad-left-9-imp {
        padding-left: 9em !important;
    }

    .pad-left-7-5-imp {
        padding-left: 7.5em !important;
    }

    .pad-left-12-imp {
        padding-left: 12em !important;
    }

    .pad-left-12-imp {
        padding-left: 12em !important;
    }

    .rounded-0-5-rem {
        border-radius: 0.5rem;
    }

    .justify-content-spaceBtwn {
        justify-content: space-between;
    }

    .margin-left-neg-7-25em {
        margin-left: -7.25em;
    }

    .margin-left-neg-6em {
        margin-left: -6em;
    }

    .margin-left-20em {
        margin-left: 20em;
    }

    .margin-left-7-5em {
        margin-left: 7.5em;
    }

    .margin-bottom-5rem {
        margin-bottom: 5rem;
    }

    .margin-top-0-75rem {
        margin-top: 0.75rem !important;
    }

    .margin-bottom-0-75rem {
        margin-bottom: 0.75rem;
    }

    .margin-right-0-438rem {
        margin-right: 0.438rem;
    }

    .margin-top-neg-0-1rem {
        margin-top: -0.1rem;
    }

    // Stepper Design
    .currentStepperHighlight {
        width: 1em;
        height: 1em;
        background: url('~assets/images/blueprint/admin_redesign/currentstep_indicator.svg') no-repeat center;
        ;
        position: relative;
    }

    .nextStepper {
        width: 0.75em;
        height: 0.75em;
        background: url('~assets/images/blueprint/admin_redesign/nextstep_indicator.svg') no-repeat center;
        ;
        position: relative;
    }

    .completedStepper {
        background: url('~assets/images/blueprint/admin_redesign/success_indicator.svg') no-repeat center;
        ;
        position: relative;
        width: 0.75em;
        height: 0.75em;
    }

    .checkIcon {
        background-position-x: left;
        background-image: url("~assets/images/blueprint/icons/confirmation.svg");
        background-position-y: center;
        background-repeat: no-repeat;
        padding-left: 46px;
    }

    @mixin divider-style($color,
        $border,
        $width,
        $margin-top) {
        width: $width;
        border-style: $border;
        background: $white;
        border-bottom: none;
        border-right: none;
        border-left: none;
        color: $color;
        margin-top: $margin-top;
        border-width: 0.1em;
    }

    .divider {
        @include divider-style($cyan-blue,
            dashed,
            13em,
            0.375em)
    }

    .divider-solid-gray {
        @include divider-style($disabled-gray,
            solid,
            13em,
            0.1em)
    }

    .divider-solid-green {
        @include divider-style($green,
            solid,
            13em,
            0.1em)
    }

    .divider-dashed-green {
        @include divider-style($green,
            dashed,
            13em,
            0.1em)
    }

    .grayText {
        color: $disabled-accordion-gray;
    }

    .line-height-30px {
        line-height: 30px;
    }

    .box {
        display: flex;
        flex-wrap: wrap;
        // height: 14rem;
        align-content: space-between;
    }

    //Striped table
    .stripedTable {
        table-layout: fixed;

        tr:nth-child(even) {
            background-color: $light-gray;
        }

        tr {
            td {
                border-width: 2px 0px;
                border-style: solid;
                border-color: $disabled-gray-color;
            }
        }
    }

    .notifyMessage {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .fontSize {
        font-size: 1rem !important;
    }

    .toggleButtons {
        .fontSize {
            &:before {
                background: none;
            }
        }

        .col-sm-6,
        .col-xs-6 {
            width: 50%;
        }
    }

    .flex-nowrap {
        flex-wrap: nowrap;
    }

    .hr.black-bg {
        background-color: $black;
        height: 0.15rem;
    }

    //Styles for when radio button disabled is set to true conditionally
    input[type=radio].enableRadioBtn:disabled+label:before {
        border: 3px solid $light-black;
    }

    input[type=radio].enableRadioBtn:disabled:checked+label:before,
    input[type=radio].enableRadioBtn:disabled[checked*=checked]+label:before {
        border: 8px solid $checked-color;
    }

    @media screen and (min-width: $screen-mobile) {
        .position-md-absolute {
            position: absolute;
        }
    }

    // MOBILE View
    @media screen and (max-width: $screen-mobile) {
        .box {
            flex-wrap: wrap;
            // height: 20rem;
            align-content: space-between;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 3.5rem 0rem;
        }

        .box.edit {
            flex-wrap: wrap;
            // height: 35rem;
            align-content: space-between;
            display: grid;
            grid-template-columns: none;
            grid-gap: 3.5rem 0rem;
            padding-bottom: 3rem;
        }

        .stepperFont {
            font-size: x-small;
        }

        .footer-gradientline {
            margin-top: 1rem;
        }
    }

    @media screen and (max-width: $screen-mobile) {
        .boxForm {
            flex-wrap: wrap;
            -ms-flex-line-pack: justify;
            align-content: space-between;
            display: grid;
            grid-template-columns: 1fr 1fr;

            &.edit {
                grid-template-columns: none;
            }
        }

        .hide-mobile-view {
            display: none;
        }

        .footer-gradientline {
            margin-top: 1rem !important;
        }
    }

    // Tablet View
    @media screen and (min-width: $screen-desktop) and (max-width: $screen-tablet) {
        .line-height-md {
            line-height: 1.5em;
        }

        .hide-tablet-view {
            display: none;
        }
    }

    // Datatable row - On hover color
    .showLink {
        display: none;
    }

    .quickAction {
        background-color: white;
        width: 10rem;
        border-radius: 0.25em;
        height: auto;
        display: block;
        padding: 12px;
        position: absolute;
        z-index: 999;
        box-shadow: 4px 5px 5px rgba(0, 0, 0, 0.5);
        font-family: 'cerapro-regular', Arial, Helvetica, sans-serif;
    }

    .arrowBox:after,
    .arrowBox:before {
        bottom: 3.35rem;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .arrowBox:after {
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: white;
        border-width: 0.6rem;
        margin-left: -1.5rem;
    }

    // input[type=text]:disabled,
    // input[type=number]:disabled,
    // input[type=tel]:disabled,
    // input[type=email]:disabled,
    // textarea:disabled {
    //   color: $gray-link-color !important;
    //   border: none !important;
    //   padding: 0 !important;
    //   height: auto;
    //   margin-top: 0;
    // }
    a:not([href]).blueLink {
        color: $cyan-blue;
    }

    a:not([href]).disabled.blueLink {
        color: $link-color-disabled;
        pointer-events: none;
        cursor: default;
    }

    .listCounter {
        background-color: $alertErrorLeft;
        line-height: 0.1rem;
        text-align: center;
        color: $white;
        border-radius: 0.39rem;
        position: absolute;
        z-index: 2;
        margin-left: -15px;
        font-size: 58%;
        height: 0.65rem;
        width: 1.25rem;
        margin-top: -0.2rem;
        font-family: $default-font-family-bold;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    @media (max-device-width: $screen-mobile) {
        textarea:disabled {
            height: 7rem;
            overflow: hidden;
        }
    }

    @media screen and (max-device-width: $screen-mobile) {
        .mfa-box {
            display: none !important;
        }
    }

    ngx-datatable.redesignTableHeader {
        datatable-header {
            div.datatable-header-inner {
                div.datatable-row-center {
                    datatable-header-cell {
                        div.datatable-header-cell-template-wrap {
                            span.datatable-header-cell-wrapper {
                                span {
                                    font-family: "cerapro-regular", Arial, Helvetica, sans-serif !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .amber-button {
        background-color: $amber !important;
        border-radius: 0 !important;
        border: solid 3px $amber !important;

        &:disabled {
            color: $black !important;
        }
    }

    .fontsize-3em {
        font-size: 3em;
    }

    #navFix {
        background: url('~assets/images/blueprint/admin_redesign/navigate_back.svg') no-repeat 0%;
    }

    //P-calendar
    .calendarWrapper {
        .p-calendar-w-btn {
            border-bottom: 1.5px solid;
            height: 33px;

            .p-inputtext {
                border: none !important;
                padding-bottom: 0;
                padding-top: 0;
                height: 30px;
                padding-left: 1.5rem !important;
                padding-right: 1rem !important;

                &:hover,
                &:focus {
                    border: none !important;
                }
            }

            .p-button {
                padding: 0;
                height: 1.2em;
                margin-right: 1rem !important;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .homecard {
            width: unset;
            min-height: 340px;
            padding-top: 20px;
            margin-bottom: 20px;
            padding-left: 1em;
            padding-right: 1em;
        }
    }

    //****** styles for cards in modals*******//
    .service-tile {
        cursor: pointer;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        color: $black;
        text-align: center;
        background-color: $white;
        border: 2.5px solid $disabled-gray-color;
        margin: 0.5em 0;
        height: 6rem;

        &:hover,
        &:focus {
            @extend .cardSelected;
        }
    }

    .cardSelected {
        cursor: pointer;
        border-image: linear-gradient(to right, $hover-button, $header-right) 4% 1% 4% 28%;
        background-color: $white;
        border-width: 3px;
        border-radius: 4px;
        border-style: solid;
    }

    .tile-text {
        text-align: center;
        line-height: 15px;
        margin-top: 3px;
    }

    .tile-container {
        display: flex;
        justify-content: flex-start;
    }

    .skip {
        position: absolute !important;
        width: 1px;
        height: 1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;

        &:active,
        &:focus {
            top: 0;
            left: 80%;
            width: auto;
            height: auto;
            overflow: visible;
            margin: 0;
            clip: auto;
            z-index: 99;
        }
    }

    .header-gradientline {
        background: linear-gradient(90deg, #00AAF4 30%, #002f87 95%, #002f87 100%) !important;
        height: 0.4375rem !important;
    }

    .footer-gradientline {
        background: linear-gradient(90deg, #00AAF4 30%, #002F87 95%, #002F87 100%) !important;
        height: 0.4375rem !important;
        margin-top: 13rem;
    }

    .triangle-error {
        background: url('~assets/images/blueprint/admin_redesign/triangle-error.svg') no-repeat 0%;
        height: 1.5em;
        width: 2em;
    }

    .round-success {
        background: url('~assets/images/blueprint/round-success.svg') no-repeat 0%;
        height: 1.5em;
        width: 2em;
    }

    .cursor-notAllowed {
        cursor: not-allowed !important;
    }

    .grayed-out {
        opacity: 0.5;
    }

    .mop-blue {
        color: $blue00;
    }

    .mop-totalPay {
        background-color: $button-color;
        border-radius: 4px;
        border: solid 3px $button-color;
        color: $white;
    }

    .padding-top-40px {
        padding-top: 40px;
    }

    .padding-left-7em {
        padding-left: 7em;
    }

    .margin-left-4em {
        margin-left: 4em;
    }

    .width-83 {
        width: 83%;
    }

    .width-60 {
        width: 60%
    }

    .font-cerapro-regular {
        font-family: $default-font-family;
    }

    .font-cerapro-regular-imp {
        font-family: $default-font-family !important;
    }

    .modal-lg-wid45 {
        .shortStepper {
            .steps.milestone {
                .step {
                    .label {
                        //width: 8rem !important;
                        top: -4.5rem !important; //for resolution fix top: -3.5rem !important;
                        left: -3em !important;
                        text-align: center;

                        &.green {
                            span {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    .icon-grey-border {
        border: 5.4px solid $disabled-gray-color;
        border-radius: 50%;
    }

    .print-icon {
        align-self: center;
        position: absolute;
    }

    .padding-left-13px {
        padding-left: 13px;
    }

    .padding-bottom-13px {
        padding-bottom: 13px;
    }

    .width-5px {
        width: 5px;
    }

    .padding-left-4-5em {
        padding-left: 4.5em;
    }

    .grey-banner-bg {
        border-style: solid;
        border-left-width: 0.625em;
        border-top-width: 0px;
        border-bottom-width: 0px;
        border-right-width: 0px;
        height: 2.5rem;
        padding-top: 0.5em;
        border-left-color: $picklist-header;
        background-color: $gray-modal-header;
        border-radius: 0.3rem;
    }

    .width-20px {
        width: 20px;
    }

    .text-disabled-color-828E96 {
        color: #828E96 !important;
    }

    .width-5px {
        width: 5px;
    }

    .toggle {
        display: flex;
        justify-content: space-between;
    }

    // Redesign Modal 
    @mixin redesign-modal-size($width, $max-width) {
        width: $width;
        max-width: $max-width;
    }

    //Modal 2
    .redesign-modal-lg {
        .modal-dialog {
            @include redesign-modal-size(100%, 744px);
        }
    }

    .redesign-modal-lg2 {
        .modal-dialog {
            @include redesign-modal-size(100%, 781px);
        }
    }

    .redesign-modal-lg3 {
        .modal-dialog {
            @include redesign-modal-size(94%, 720px);
        }
    }

    .redesign-modal-lg3 {
        .modal-dialog {
            width: 94%;
            max-width: 720px;
        }
    }

    //Modal 1
    .redesign-modal-md {
        .modal-dialog {
            @include redesign-modal-size(100%, 600px);
        }
    }

    .redesign-modal-md2 {
        .modal-dialog {
            @include redesign-modal-size(100%, 555px);
        }
    }

    //Modal 3
    .redesign-modal-sm {
        .modal-dialog {
            @include redesign-modal-size(100%, 440px);
        }
    }

    .redesign-modal-sm2 {
        .modal-dialog {
            @include redesign-modal-size(100%, 485px);
        }
    }

    .redesign-modal-sm3 {
        .modal-dialog {
            @include redesign-modal-size(100%, 370px);
        }
    }

    .redesign-modal-xs {
        .modal-dialog {
            @include redesign-modal-size(300px, 300px);
            margin-left: auto;
            margin-right: auto;
        }
    }

    .redesign-modal-xs-2 {
        .modal-dialog {
            @include redesign-modal-size(94%, auto);
            margin-left: auto;
            margin-right: auto;
        }
    }

    //Zoom 500%
    @media (max-width:256px) {
        .redesign-modal-xs {
            .modal-dialog {
                @include redesign-modal-size(100%, auto);
            }
        }
    }

    .ceraPro-medium {
        font-family: $default-font-family-medium;
    }

    //OTP calendar footer
    .paymentFooter {
        font-size: 0.8em;

        .payDepict {
            width: 0.2em;
            height: 1em;
            border-radius: 0.2em;
            border: 0.15em solid darkblue;
            margin-left: 3em;
            margin-top: 0.5em;
            padding: 0 0.4em !important;
        }

        .dueDepict {
            width: 1em;
            height: 1em;
            border-radius: 0.2em;
            background-color: green;
            margin-top: 0.5em;
            padding: 0 0.55em !important;
        }
    }

    .requiredField {
        border: 2px solid green;
        border-radius: 0.8em;
        background: green;
        color: white;
        width: 4em;
        height: 4em;
        text-align: center;
        padding-top: 0.85em;
        text-decoration: underline;

        &:hover {
            border: 2px solid green !important;
            background: green !important;
        }
    }

    .redesign-modal-sm2 {
        .modal-dialog {
            width: 100%;
            max-width: 485px;
        }
    }


    .font-size-10px {
        font-size: 10px;
    }

    .padding-right-20e {
        padding-right: 20em;
    }

    .verticalalign_subscript {
        vertical-align: sub !important;
    }

    .align_baseline {
        align-items: baseline !important;
    }

    .pad-right-4-6-em {
        padding-right: 4.6em;
    }

    /**slide in text on image(can refer new phone equip)**/
    span#slideText {
        display: none;
        transition: linear;
        color: $button-color;
    }

    .slideTextParent:hover #slideText {
        display: inline;
    }


    .margin-left-15em {
        margin-left: 15em;
    }

    .font-size-8px {
        font-size: 8px;
    }

    .order-2 {
        order: 2;
    }

    .height-18 {
        height: 18px;
    }

    .padding-top-17px {
        padding-top: 17px;
    }

    .left-3em {
        left: 3em;
    }

    .left-15 {
        left: 15%;
    }

    .onFocusOutline:focus {
        outline: 1px solid $link-color;
    }

    .padding-15 {
        padding: 15px;
    }

    .inlineSuccessImg {
        background: url("~assets/images/blueprint/icons/alert_success.svg") no-repeat;
        background-size: cover;
        padding: 0.5em;
    }

    .inlineErrorImg {
        background: url("~assets/images/blueprint/icons/alert_error_icon.svg") no-repeat;
        background-size: cover;
        padding: 0.5em;
    }

    .inlineWarningImg {
        background: url("~assets/images/blueprint/icons/alert_warning.svg") no-repeat;
        background-size: cover;
        padding: 0.5em;
    }

    .deletebin {
        height: 16px;
        width: 14px;
    }

    .flexWrap {
        flex-wrap: wrap;
    }

    .width-310 {
        width: 310px;
    }

    // DSM based website grid for phase 3

    // X-Small Breakpoint (Mobile)
    @media screen and (min-width: 320px) and (max-width: 575px) {
        .dsm-grid {
            max-width: 575px;
        }

        .redesign-modal-md {
            .modal-dialog {
                margin-left: 0;
            }
        }
    }

    // Small Breakpoint (Mobile)
    @media screen and (min-width: 576px) and (max-width: 768px) {
        .dsm-grid {
            max-width: 667px;
        }
    }

    // Medium Breakpoint (Tablet)
    @media screen and (min-width: 768px) and (max-width: 996px) {
        .dsm-grid {
            max-width: 894px;
        }
    }

    // Large Breakpoint (Desktop)
    @media screen and (min-width: 996px) and (max-width: 1199px) {
        .dsm-grid {
            max-width: 1098px;
        }
    }

    // X-Large Breakpoint (Desktop)
    @media screen and (min-width: 1200px) {
        .dsm-grid {
            max-width: 1600px;
        }
    }

    .top-30percent {
        top: 30%
    }

    .top-20percent {
        top: 20%
    }

    .button-leavesite-modal {
        width: 270px;
    }

    .redesign-modal-md-formobile {
        .modal-dialog {
            max-width: 600px;
        }
    }

    .redesign-modal-sm-formobile {
        width: 94%;
        left: 0.875rem;
    }


    .expandAllArrow {
        background: url('~assets/images/blueprint/CBMA UI/expand-all.svg') no-repeat;
    }

    .collpaseAllArrow {
        background: url('~assets/images/blueprint/CBMA UI/collapse-all.svg') no-repeat;
    }

    .favorite-icon {
        margin-left: 7rem;
        max-height: 4px;

        &:focus {

            img#inactiveImg,
            img#activeImg {
                border: 1px solid $link-color;
            }
        }
    }

    .padding-bottom-5rem {
        padding-bottom: 5rem !important;
    }

    #timeSlotBox {
        width: 114px;
        height: 40px;
        padding: 7px 2px 2px 2px;
        border-radius: 2px;
        box-sizing: border-box;
        text-align: center;
        line-height: normal;
        border: 1px solid $disabled-gray-color;
        background-color: $white;
        color: $tooltip-color;
        display: block;

        &:hover,
        &:focus {
            border: 2px solid $alertInfoBorder;
            box-shadow: none !important;
        }
    }

    .disable-background {
        background-color: $gray-modal-header !important;
        color: $card-text-black !important;
    }

    .strikedOut {
        border: 1px solid $platinum-gray !important;
        background-color: $gray-modal-header !important;
        color: $dark-gray-capp !important;
        pointer-events: none;
    }

    .originalHighlighted {
        border: 1px solid $deepSkyBlue !important;
        background-color: $deepSkyBlue !important;
        color: $white !important;
    }

    @mixin multi-line-ellipsis($line) {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: $line;
        -webkit-box-orient: vertical;
        overflow-wrap: break-word;
    }

    .two-line-ellipsis {
        @include multi-line-ellipsis(2);
    }

    .three-line-ellipsis {
        @include multi-line-ellipsis(3);
    }

    .four-line-ellipsis {
        @include multi-line-ellipsis(4);
    }

    .six-line-ellipsis {
        @include multi-line-ellipsis(6);
    }

    .text-blue-colour {
        color: $link;
    }

    .serviceApptBlue {
        background-image: radial-gradient(circle at -32% 139%, $sapphire-blue 38%, $endeavour-blue 38% 50%, $dark-cyan-blue 50% 61%, $button-color 61% 100%);
    }

    .serviceApptBorder {
        border: 0.5px solid $button-color;
        border-radius: 8px;
    }

    .serviceApptBlue {
        background-image: radial-gradient(circle at -32% 139%, $sapphire-blue 38%, $endeavour-blue 38% 50%, $dark-cyan-blue 50% 61%, $button-color 61% 100%);
    }

    .serviceApptBorder {
        border: 0.5px solid $button-color;
        border-radius: 8px;
    }

    .text-blue-colour {
        color: $link;
    }

    .gradient-rectangle-header {
        background: linear-gradient(145.72deg, $alertInfoBorder 0%, $header-right 100%);
        height: 0.25em;
    }

    .margin-left-auto {
        margin-left: auto;
    }

    .modal-height-max {
        max-height: 22em;
        overflow-y: scroll;
        overflow-x: hidden;
        display: grid;
    }

    div.bcFloat {

        &:focus-within,
        &:hover {
            border: 1px solid $focus-color;
        }

        &.bcFloat-accessibility {
            position: static !important;
            float: right !important;
        }
    }

    @mixin multi-line-ellipsis($line) {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: $line;
        -webkit-box-orient: vertical;
        overflow-wrap: break-word;
    }

    .two-line-ellipsis {
        @include multi-line-ellipsis(2);
    }

    .three-line-ellipsis {
        @include multi-line-ellipsis(3);
    }

    .four-line-ellipsis {
        @include multi-line-ellipsis(4);
    }

    .six-line-ellipsis {
        @include multi-line-ellipsis(6);
    }

    .scrollbox {
        overflow-y: scroll;
        overflow-x: hidden;
        display: grid;
    }

    div.bcFloat {

        &:focus-within,
        &:hover {
            border: 1px solid $focus-color;
        }

        &.bcFloat-accessibility {
            position: static !important;
            float: right !important;
        }
    }

    .loading-spinner {
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 3px solid $button-color;
        border-radius: 50%;
        border-top-color: $light-gray;
        animation: spin 1s ease-in-out infinite;
    }

    @keyframes spin {
        to {
            -webkit-transform: rotate(360deg);
        }
    }

    .scrollbox {
        overflow-y: scroll;
        max-height: calc(100vh - 250px);
        display: grid;
    }

    .bulkAccTileBox {
        width: 212px;
        min-height: 153px;
        box-sizing: border-box;
        border: 1px solid $disabled-gray-color;

        .titleBox {
            height: 30px;
            color: white !important;
        }

        .countBox {
            min-height: 100px;
        }
    }

    .font-size-xLarge {
        font-size: larger;
    }

    .bg-color {
        background-color: $checked-color;
    }

    .border-bottom-skyblue {
        border-bottom: 1px solid $light-skyblue;
    }

    .border-radius-pt2em {
        border-radius: 0.2rem;
    }

    .light-skyblue:hover {
        color: $light-skyblue;
    }

    .lh-1 {
        line-height: 1em;
    }

    .lh-14 {
        line-height: 14px;
    }

    .lh-16 {
        line-height: 16px
    }

    .pb-11 {
        padding-bottom: 11px;
    }

    .lh-20 {
        line-height: 20px;
    }

    .lh-22 {
        line-height: 22px;
    }

    .font-weight-5 {
        font-weight: 500;
    }

    .pr-5px {
        padding-right: 5px;
    }

    .mouse-link:focus {
        outline: 1px solid $white;
    }

    .bold-header {
        font-family: "cerapro-bold", Helvetica, sans-serif !important;
    }

    .padding-4-35em {
        padding-left: 4.35em !important;
        padding-right: 4.35em !important;
    }

    .pad-top-bottom-1-25em {
        padding-top: 1.25rem !important;
        padding-bottom: 1.25rem !important;
    }

    .gray-border {
        border: 1px solid $disabled-grey;
    }

    /*Overflow Property*/

    .overflow-scroll-x {
        overflow-x: scroll;
    }

    /*Chat bot bill form styles*/

    .method-dialog__form-container-chatbot input {
        background: $white;
    }

    .method-dialog__form-container-chatbot label+input {
        margin-top: 0;
    }

    .customer-container .save-payment-method input.toggle-save-payment-method {
        color-scheme: normal;
    }

    button.method-dialog__tablink {
        color: $black;
    }

    .offer-banner {
        border-radius: 3px;
        background: rgba(128, 213, 249, 0.20);
    }

    .gradient-space {
        background: linear-gradient(180deg, $checked-color 45.94%, $hover-button 116.62%);
    }

    @media screen and (min-width: $xs-screen-min) and (max-width: $tablet-screen-max) {
        .gradient-space {
            background: linear-gradient(180deg, $checked-color 0%, $hover-button 173.49%);
            height: 300px !important;
        }
    }

    .create-profile-bg {
        background-image: radial-gradient(circle at 86% 126%, $white 38% 50%, $diabled-gray-faded 50% 61%, $white 61% 100%);
        display: flex;
        flex-direction: column;
    }

    @media screen and (min-width: 1599px) {
        .create-profile-bg {
            background-image: radial-gradient(circle at 76% 143%, $white 38% 50%, $diabled-gray-faded 4% 63%, $white 61% 100%);
        }
    }
    
    iframe#web-messenger-container.chatFloat-accessibility{
        float: right !important;
        position: static !important;
    }
    
    .container-padding {
        padding-left: 4.5rem;
        padding-right: 4.5rem;
    }
    
    .blurry-text {
        color: transparent;
        text-shadow: 0 0 5px rgba(0,0,0,0.5);
        -webkit-user-select: none;
        user-select: none;
    }