/* global button styles */

.button, .button-secondary, .button-payment {
  // padding: 0.5rem 1rem;
  margin-bottom: 1em;
  margin-top: 1em;
  font-size: 1.25rem;
  line-height: 1.5;
  cursor: pointer;
  width: auto;
}

/* MOBILE */
@media (max-width: 767px) {
  .button, .button-secondary, .button-payment  {
    margin-bottom: 10px;
    margin-top: 10px;
    width: 100%;
  }
}

.button::-moz-focus-inner, .button-secondary::-moz-focus-inner, .button-payment::-moz-focus-inner  {
  /* removes extra padding in firefox */
  border: 0;
  padding: 0;
}
.btn-lg {    padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0px !important;
}

.button {
  &:focus {
    border: 1px solid $link-focus-color;
    outline: none;
    @include box-shadow(0 0 5px $link-focus-color);
  }
  &:hover {
    @include box-shadow( 0px 3px 5px $button-shadow);
  }
  // font-family: "open_sanssemibold", Arial, Helvetica, Sans-serif;
  font-size: 1.063em;
  /* 17px*/
  text-align: center;
  border: 1px solid $dark-link-color;
  color: $white;
  border-color: $button-color;
  background: $button-color;
  background-size: 24px 24px;
  a {
    color: $white;
  }
  &:active{
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    border-color: $button-color;
    background: $button-color ;
    background-size: 24px 24px;
  }
  &:disabled {
      border: 2px solid $disabled-grey;
      background: $disabled-grey;
      color: $white !important;
      cursor: not-allowed; 
      &:focus{
         text-decoration: none;
           box-shadow: none; 
      }
      @include box-shadow(none !important);
  }
}

.button.disabled-btn:not(.button-secondary) {
  &:disabled {
      background: $pebble-grey !important;
      opacity: 1 !important;
      border: 2px solid $pebble-grey !important;
  }
}

.button-payment {
  &:focus {
    border: 1px solid $green;

    @include box-shadow(0 0 5px $green);
  }
  // font-family: "open_sanssemibold", Arial, Helvetica, Sans-serif;
  font-size: 1.063em;
  /* 17px*/
  text-align: center;
  border: 1px solid $green;
  color: $white;
  display: inline-block;
  overflow: visible;

  @include box-radius(4px);

  background: $green;
  a {
    color: $white;
  }
  &:disabled {
    border: 1px solid $tone-bone-white !important;
    background: $contrast-bone-white !important;
    color: $bone-white !important;
    cursor: default !important;
  }
}

/******************************/

/* primary button styles */

/* this fixes the custom styles in the Rich Text Editor in Adobe which adds the class as a span wrapping the anchor tag */

.button-secondary {
  &:disabled {
    border: 1px solid $tone-bone-white !important;
    background: $contrast-bone-white !important;
    color: $bone-white !important;
    cursor: default !important;
    // font-family: "open_sanssemibold", Arial, Helvetica, Sans-serif;
    font-size: 1.25rem;
    /* 17px*/
    text-align: center;
    display: inline-block;
    overflow: visible;
    /* removes padding in IE7 input button */
    border: 1px solid $disabled-gray;

    /* Opera 10.5, IE9, FF4+, Chrome 10+ */
    background-color: $link-color-disabled;

    //@include box-radius(4px);
    @include box-shadow(none !important);
  }
  &:hover{
    @include box-shadow( 0px 3px 5px $button-shadow);
  }
  &:focus{
    box-shadow: none;
    outline: none;
    color: $button-color;
    @include box-shadow( 0px 3px 5px $button-shadow);
  }
  // font-family: "open_sanssemibold", Arial, Helvetica, Sans-serif;
  font-size: 1.063em;
  /* 17px*/
  background-color: #fff;

  color: $button-color;
  text-align: center;
  display: inline-block;
  overflow: visible;
 border: 2px solid !important;
 border-image-slice: 1 !important;
  border-image-source:radial-gradient(circle at top left, #00AAF4, #002F87) !important;
  min-height: 44px; 

  &.btn-secondary-disabled{
    border: 2px solid $pebble-grey !important;
    background: $white !important;
    color: $pebble-grey !important;
    cursor: not-allowed !important;
    font-size: 1.063em !important;
  }
}

/******************************/

/* secondary button styles */


  .button-secondary:hover, input.button-secondary:hover[type="submit"] {
    color: #002F87;
    /*border: 2px double $transparentish;*/
    background: $white ;
    background-size: 24px 24px; }
    .button-secondary:focus, input.button-secondary:focus[type="submit"] {
    box-shadow: none;
   }

/* secondary disabled button styles */

/* focus style */

// a {
//   &.button:focus, &.button-secondary:focus {
//     text-decoration: underline;
//   }
// }

.button-secondary:disabled:focus {
  text-decoration: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;

  @include box-shadow(none !important);
}

/*********************/

/* BUTTON AJAX */

.loading-wrapper-active {
  width: 75px !important;
  pointer-events: none !important;
  background-position: center center !important;
  color: transparent !important;
}

.button.loading-wrapper-active {
  &:disabled {
    border: 1px solid $dark-link-color !important;
    cursor: default !important;
    color: transparent !important;
    background: $dark-link-color url('~assets/images/blueprint/loading-bluebg.gif') no-repeat 50% 50% !important;
  }
  border: 1px solid $dark-link-color !important;
  cursor: default !important;
  color: transparent !important;
  background: $dark-link-color url('~assets/images/blueprint/loading-bluebg.gif') no-repeat 50% 50% !important;
}

.button-secondary.loading-wrapper-active {
  &:disabled {
    border: 1px solid $link-color !important;
    cursor: default !important;
    color: transparent !important;
    background: $link-color url('~assets/images/blueprint/loading-bluebg.gif') no-repeat 50% 50% !important;
  }
  border: 1px solid $link-color !important;
  cursor: default !important;
  color: transparent !important;
  background: $link-color url('~assets/images/blueprint/loading-bluebg.gif') no-repeat 50% 50% !important;
}

/* MOBILE */
@media (max-width: $screen-mobile) {
  .loading-wrapper-active {
    width: 100% !important;
  }
}

/*****************/

/* BUTTON SPACING */

/* DESKTOP */
@media screen and (min-width: $screen-desktop) {
  /* BUTTON NEXT TO SECONDARY BUTTON OTHER */
  .button + .button-secondary {
    margin-left: 20px;
  }
  /* SECONDARY BUTTON NEXT TO BUTTON OTHER */
  .button-secondary + {
    .button {
      margin-right: 20px;
    }
    .button-secondary {
      margin-left: 20px;
    }
  }
  /* SECONDARY BUTTONS NEXT TO EACH OTHER */
  /* BUTTONS ON RIGHT */
  .button.pull-right + .button-secondary {
    &.pull-right {
      margin-right: 20px;
    }
    &.pull-left, &.pull-right + .button-secondary.pull-left {
      margin-left: 0;
    }
  }
  /* BUTTONS ON OPPOSITE SIDES */
  /* WIZARD TRIPLE BUTTONS */
}

/********************************/

/*Selection Buttons*/

.selection-button {
  position: relative !important;
  min-height: 10em !important;
  text-align: center;
  border: 1px solid $gray;
}

.selection-button-lp {
  &:hover {
    background-color: #03406a;
    p {
      color: white;
    }
  }
  p {
    position: absolute !important;
    top: 41%;
    color: #0679ca;
  }
}

.selection-button-decision {

  position: relative !important;
  text-align: center;
  border: 1px solid $gray;
   @media screen and (max-width: $screen-xs) {
    min-height: 10em !important;
   }
   @media screen and (min-width: $screen-mobile) {
       min-height: 20em !important;
      }
   &:hover {
      cursor: pointer;
      background-color: $dark-link-color;
      color: $white;
      img {
        cursor: text;
       }
    }

   color: $dark-link-color;
 }

.selection-button-disabled {
  background-color: #dadadc !important;
  &:hover {
    background-color: #dadadc !important;
  }
  p {
    color: #979798 !important;
  }
}

.selection-option {
  position: absolute;
  top: 34%;
  left: 47.5%;
  border: 2px solid #BDBDBD;
  height: 3em;
  border-radius: 50%;
  width: 3em;
  z-index: 1;
  background: url("~assets/images/blueprint/circle-img.png") no-repeat -3px -7px;
  p {
    text-align: center;
    line-height: 12px !important;
        padding-top: 15px;
  }
}

@media screen and (max-width: 767px) {
  .selection-option {
    top: 43%;
    left: 45%;
  }
}

/********************************/

/* global button styles */

%default-button{
  color: $white;
  padding: 0.5rem 3.75rem;
  margin-bottom: 1em;
  margin-top: 1em;
  line-height: 1.5;
  cursor: pointer;
  font-family: $default-font-family-medium;
}

%border-gradient-button{
  border: 10px solid;
  border-image-slice: 1;
  border-width: 3px;
  background-color: transparent;
}

.button, .button-secondary, .button-payment {
  @extend %default-button;
}

button:focus {
  border: 1px solid $link-focus-color !important;
  box-shadow: 0 0 5px $link-focus-color !important;
}

.button{
  @each $theme, $map in $themes {
    .#{$theme} & {
      @include button(map-get($map: $map, $key: theme-color), map-get($map: $map, $key: border-radius));
      @each $color-name, $color in $button-colors {
        &.#{$color-name}{      
          @include button($color, map-get($map: $map, $key: border-radius));
        }
      }
      &.button-secondary{
        @extend %border-gradient-button;
        color: map-get($map: $map, $key: theme-color);
        border-image-source: linear-gradient(90deg, element-type($map, default-gradient, 'color1'),element-type($map, default-gradient, 'color2'));
        &:hover,&:focus{
          background-color: transparent;
        }
      }
      &.btn-long-text {
        padding: 0.5rem 1.875rem;
      }
    }
  }
}


// .button-secondary{
//   border-image-source:radial-gradient(circle at top left, #00AAF4, #002F87) !important;
// }


/* MOBILE */
// @media (max-width: 767px) {
//   .button, .button-secondary, .button-payment  {
//     margin-bottom: 10px;
//     margin-top: 10px;
//     width: 100%;
//   }
// }

// .button::-moz-focus-inner, .button-secondary::-moz-focus-inner, .button-payment::-moz-focus-inner  {
//   /* removes extra padding in firefox */
//   border: 0;
//   padding: 0;
// }
// .btn-lg {    padding: 0.5rem 1rem;
//   font-size: 1.25rem;
//   line-height: 1.5;
//   border-radius: 0px !important;
// }

// .button {
//   &:focus {
//     border: 1px solid $link-focus-color;
//     outline: none;
//     @include box-shadow(0 0 5px $link-focus-color);
//   }
//   &:hover {
//     @include box-shadow( 0px 3px 5px $button-shadow);
//   }
//   font-family: "open_sanssemibold", Arial, Helvetica, Sans-serif;
//   font-size: 1.063em;
//   /* 17px*/
//   text-align: center;
//   border: 1px solid $dark-link-color;
//   color: $white;
//   border: 2px solid;
//   border-color: $button-color;
//   background: $button-color;
//   background-size: 24px 24px;
//   a {
//     color: $white;
//   }
//   &:active{
//     box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
//     border-color: $button-color;
//     background: $button-color ;
//     background-size: 24px 24px;
//     box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
//     border-color: $button-color;
//   }
//   &:disabled {
//       border: 2px solid $disabled-grey;
//       background: $disabled-grey;
//       color: $white !important;
//       cursor: not-allowed; 
//       &:focus{
//          text-decoration: none;
//            box-shadow: none; 
//       }
//       @include box-shadow(none !important);
//   }
// }

// .button-payment {
//   &:focus {
//     border: 1px solid $green;

//     @include box-shadow(0 0 5px $green);
//   }
//   font-family: "open_sanssemibold", Arial, Helvetica, Sans-serif;
//   font-size: 1.063em;
//   /* 17px*/
//   text-align: center;
//   border: 1px solid $green;
//   color: $white;
//   display: inline-block;
//   overflow: visible;

//   @include box-radius(4px);

//   background: $green;
//   a {
//     color: $white;
//   }
//   &:disabled {
//     border: 1px solid $tone-bone-white !important;
//     background: $contrast-bone-white !important;
//     color: $bone-white !important;
//     cursor: default !important;
//   }
// }

// /******************************/

// /* primary button styles */

// /* this fixes the custom styles in the Rich Text Editor in Adobe which adds the class as a span wrapping the anchor tag */

// .button-secondary {
//   &:disabled {
//     border: 1px solid $tone-bone-white !important;
//     background: $contrast-bone-white !important;
//     color: $bone-white !important;
//     cursor: default !important;
//     font-family: "open_sanssemibold", Arial, Helvetica, Sans-serif;
//     font-size: 1.25rem;
//     /* 17px*/
//     color: $disabled-gray;
//     cursor: default;
//     text-align: center;
//     display: inline-block;
//     overflow: visible;
//     /* removes padding in IE7 input button */
//     border: 1px solid $disabled-gray;

//     /* Opera 10.5, IE9, FF4+, Chrome 10+ */
//     background-color: $link-color-disabled;

//     //@include box-radius(4px);
//     @include box-shadow(none !important);
//   }
//   &:hover{
//     @include box-shadow( 0px 3px 5px $button-shadow);
//   }
//   &:focus{
//     box-shadow: none;
//     outline: none;
//     color: $button-color;
//     @include box-shadow( 0px 3px 5px $button-shadow);
//   }
//   font-family: "open_sanssemibold", Arial, Helvetica, Sans-serif;
//   font-size: 1.063em;
//   /* 17px*/
//   background-color: #fff;

//   color: $button-color;
//   text-align: center;
//   display: inline-block;
//   overflow: visible;
//  border: 2px solid !important;
//  border-image-slice: 1 !important;
//   border-image-source:radial-gradient(circle at top left, #00AAF4, #002F87) !important;
//   min-height: 44px; 
// }

// /******************************/

// /* secondary button styles */


//   .button-secondary:hover, input.button-secondary:hover[type="submit"] {
//     color: #002F87;
//     /*border: 2px double $transparentish;*/
//     background: $white ;
//     background-size: 24px 24px; }
//     .button-secondary:focus, input.button-secondary:focus[type="submit"] {
//     box-shadow: none;
//    }

// /* secondary disabled button styles */

// /* focus style */

// a {
//   &.button:focus, &.button-secondary:focus {
//     text-decoration: underline;
//   }
// }

// .button-secondary:disabled:focus {
//   text-decoration: none !important;
//   box-shadow: none !important;
//   -webkit-box-shadow: none !important;
//   -moz-box-shadow: none !important;

//   @include box-shadow(none !important);
// }

// /*********************/

// /* BUTTON AJAX */

// .loading-wrapper-active {
//   width: 75px !important;
//   pointer-events: none !important;
//   background-position: center center !important;
//   color: transparent !important;
// }

// .button.loading-wrapper-active {
//   &:disabled {
//     border: 1px solid $dark-link-color !important;
//     cursor: default !important;
//     color: transparent !important;
//     background: $dark-link-color url('~assets/images/blueprint/loading-bluebg.gif') no-repeat 50% 50% !important;
//   }
//   border: 1px solid $dark-link-color !important;
//   cursor: default !important;
//   color: transparent !important;
//   background: $dark-link-color url('~assets/images/blueprint/loading-bluebg.gif') no-repeat 50% 50% !important;
// }

// .button-secondary.loading-wrapper-active {
//   &:disabled {
//     border: 1px solid $link-color !important;
//     cursor: default !important;
//     color: transparent !important;
//     background: $link-color url('~assets/images/blueprint/loading-bluebg.gif') no-repeat 50% 50% !important;
//   }
//   border: 1px solid $link-color !important;
//   cursor: default !important;
//   color: transparent !important;
//   background: $link-color url('~assets/images/blueprint/loading-bluebg.gif') no-repeat 50% 50% !important;
// }

/* MOBILE */
@media (max-width: $screen-mobile) {
  .loading-wrapper-active {
    width: 100% !important;
  }
}

/*****************/

/* BUTTON SPACING */

/* DESKTOP */
@media screen and (min-width: $screen-desktop) {
  /* BUTTON NEXT TO SECONDARY BUTTON OTHER */
  .button + .button-secondary {
    margin-left: 20px;
  }
  /* SECONDARY BUTTON NEXT TO BUTTON OTHER */
  .button-secondary + {
    .button {
      margin-right: 20px;
    }
    .button-secondary {
      margin-left: 20px;
    }
  }
  /* SECONDARY BUTTONS NEXT TO EACH OTHER */
  /* BUTTONS ON RIGHT */
  .button.pull-right + .button-secondary {
    &.pull-right {
      margin-right: 20px;
    }
    &.pull-left, &.pull-right + .button-secondary.pull-left {
      margin-left: 0;
    }
  }
  /* BUTTONS ON OPPOSITE SIDES */
  /* WIZARD TRIPLE BUTTONS */
}

/********************************/

/*Selection Buttons*/

.selection-button {
  position: relative !important;
  min-height: 10em !important;
  text-align: center;
  border: 1px solid $gray;
}

.selection-button-lp {
  &:hover {
    background-color: #03406a;
    p {
      color: white;
    }
  }
  p {
    position: absolute !important;
    top: 41%;
    color: #0679ca;
  }
}

.selection-button-decision {

  position: relative !important;
  text-align: center;
  border: 1px solid $gray;
   @media screen and (max-width: $screen-xs) {
    min-height: 10em !important;
   }
   @media screen and (min-width: $screen-mobile) {
       min-height: 20em !important;
      }
   &:hover {
      cursor: pointer;
      background-color: $dark-link-color;
      color: $white;
      img {
        cursor: text;
       }
    }

   color: $dark-link-color;
 }

.selection-button-disabled {
  background-color: #dadadc !important;
  &:hover {
    background-color: #dadadc !important;
  }
  p {
    color: #979798 !important;
  }
}

.selection-option {
  position: absolute;
  top: 34%;
  left: 47.5%;
  border: 2px solid #BDBDBD;
  height: 3em;
  border-radius: 50%;
  width: 3em;
  z-index: 1;
  background: url("~assets/images/blueprint/circle-img.png") no-repeat -3px -7px;
  p {
    text-align: center;
    line-height: 12px !important;
        padding-top: 15px;
  }
}

@media screen and (max-width: 767px) {
  .selection-option {
    top: 43%;
    left: 45%;
  }
}

.submit-and-pay.button:disabled {
  color: $black !important;
}

/********************************/


