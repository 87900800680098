select {
  -webkit-border-radius: 0.2em;
  border-radius: 0.2em;
  /* ipad display fix */
  cursor: pointer;
  margin-bottom: 20px;
  overflow: hidden;
  width: 12em;
  color: $gray-link-color;
  font-size: 1em; //"open_sansregular", Arial, Helvetica, sans-serif;
  option {
    color: $gray-link-color;
    font-size: 1em; //"open_sansregular", Arial, Helvetica, sans-serif;
  }
  &:not([multiple="multiple"]) {
    height: 46px;
    background: url('~assets/images/blueprint/grey-color-downarrow.png') no-repeat !important;
    background-size: 15px !important;
    background-position: right 5% bottom 47% !important;
    background-color: $white !important ;
    padding: 4px 38px 4px 8px !important;
    border: 1px solid $black !important;
    outline: 0;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    line-height: normal;
  }
  &:focus {
    border: 1px solid $focus-color !important;
    box-shadow: 0 0 5px $focus-color !important;
  }
  &:hover {
    border: 1px solid $hover-color !important;
    box-shadow: 0 0 5px $hover-color !important;
}
  &::placeholder {
  color: $placeholder-color;
  }
  &.form-control:not([size]):not([multiple]) {
     height: 46px !important;
  }
}
.select2 {
  -webkit-border-radius: 0.2em;
  border-radius: 0.2em;
  /* ipad display fix */
  cursor: pointer;
  margin-bottom: 20px;
  overflow: hidden;
  color: $gray-link-color;
  font-size: 1em; //"open_sansregular", Arial, Helvetica, sans-serif;
  option {
    color: $gray-link-color;
    font-size: 1em; //"open_sansregular", Arial, Helvetica, sans-serif;
  }
  &:not([multiple="multiple"]) {
    height: 46px;
    background: url('~assets/images/blueprint/grey-color-downarrow.png') no-repeat !important;
    background-size: 15px !important;
    background-position: right 5% bottom 47% !important;
    padding: 7px 0px 7px 0px;
    border: 1px solid $black !important;
    outline: 0;
    width: 100% !important;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    line-height: normal;
    border-radius: 0.2em;
  }
  &:focus {
    border: 1px solid $focus-color !important;
    box-shadow: 0 0 5px $focus-color !important;
  }
  &:hover {
    border: 1px solid $hover-color !important;
    box-shadow: 0 0 5px $hover-color !important;
  }
  &::placeholder {
    color: $placeholder-color;
   }
   &.select2-container--default 
   {
     .select2-selection--single {
     padding-left: 10px;
     }
   }
}
.select2-container--focus {
     border: 1px solid $focus-color !important;
    box-shadow: 0 0 5px $focus-color !important;
}
/* SELECT2 search field */
.select2-search__field {
  border: 1px solid $black !important;
  border-radius: 3px;
  color-scheme: normal;
  &:focus {
    border: 1px solid $focus-color;
    box-shadow: 0 0 5px $focus-color;
  }
  &:hover {
    border: 1px solid $hover-color;
    box-shadow: 0 0 5px $hover-color;
  }
}
#c-container label + select {
  &:not([multiple="multiple"]) {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
  }
  &.inline:not([multiple="multiple"]) {
    margin-left: 1em;
    margin-top: 0;
    width: 12em;
  }
}

/* MOBILE */
@media screen and (max-width: $screen-mobile) {
  select {
    width: 100% !important;
    &.inline:not([multiple="multiple"]) {
      width: 100% !important;
    }
  }
  label + select {
    &:not([multiple="multiple"]), &.inline:not([multiple="multiple"]) {
      margin-top: 20px;
      margin-left: 0;
    }
  }
}

/* DISABLED */

select {
  &:disabled:not([multiple="multiple"]) {
    background: url('~assets/images/blueprint/grey-disabled-downarrow.png') no-repeat !important;
    background-size: 15px !important;
    background-position: right 5% bottom 47% !important;
    color: $disabled-gray-color !important;
  }
  &:not([multiple="multiple"]) {
    &:focus:disabled, &:hover:disabled {
      box-shadow: 0 0 0px $link-focus-color !important;
    }
    &:disabled {
      border: 1px solid $disabled-gray-color !important;
    }
    &.error {
      border:1px solid $alertErrorLeft !important;
      & + .text-danger{
        p, span{
          display: inline-block;
          color: $alertErrorLeft !important;
          margin-bottom: 0 !important;
        }
        &:before{
          content: "\0021";
          background: $alertErrorLeft;
          color: $white;
          height: 18px;
          width: 18px;
          border-radius: 50%;
          display: inline-block;
          line-height: 18px;
          text-align: center;
          margin-right: 5px;
        }
      }
      &:focus {
        box-shadow: 0 0 0px $focus-color !important;
      }
    }
  }
  &::-ms-expand {
    display: none;
  }
  &::placeholder {
    color: $disabled-gray-color;
  }
}

.select2-selection__arrow {
  opacity: 0;
}

