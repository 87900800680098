.cb-accordions {

  @each $theme,
  $map in $themes {
    .#{$theme} & {
      .card {
        padding: 0;
        border-radius: 0;
        border-left: none;
        border-right: none;
        border-top: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;

        // min-height: 4.313rem;
        .card-header {
          background-color: $white;
          border-bottom: none;
          padding: 10px;

          &:first-child {
            border-radius: 0px;
          }

          .accordion-trigger {
            color: $link-color;

            >* {
              color: $accordion-black;
              font-size: 16px;

              &:hover {
                color: $button-color;
              }
            }

            h3:before {
              background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='12px' height='8px' viewBox='0 0 12 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 54.1 (76490) - https://sketchapp.com --%3E%3Ctitle%3ECF5BB6A7-C7EB-4F1F-849C-E97AF63E7452%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='CBMA-UI' transform='translate(-22.000000, -318.000000)' fill='%23285A93' fill-rule='nonzero'%3E%3Cg id='Icon-UI/arrow-down-small-blue' transform='translate(28.000000, 322.000000) rotate(180.000000) translate(-28.000000, -322.000000) translate(16.000000, 310.000000)'%3E%3Cg transform='translate(12.000000, 12.000000) rotate(180.000000) translate(-12.000000, -12.000000) translate(6.000000, 8.000000)' id='Shape'%3E%3Cpolygon points='1.41 0.333333333 6 4.65991903 10.59 0.333333333 12 1.66531714 6 7.33333333 0 1.66531714'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat scroll;
              margin-top: 8px;
            }
          }

          .accordion-trigger-disabled {
            >* {
              color: $disabled-accordion-color !important;
              font-size: 16px;
            }
          }

          a:hover {
            text-decoration: none;
          }

          h3.font-s {
            color: $accordion-black;
            font-size: 16px;
          }

          .accordion-trigger {
            >* {
              font-family: $default-font-family-bold;
            }
          }

          // Icon fix - Accordion upgrade 9.0.0
          button.btn.btn-link {
            &[aria-expanded="true"] {
              .accordion-trigger {

                h3:before,
                span:before {
                  transform: rotate(180deg);
                }
              }

              box-shadow: 0 0 5px $cyan-blue;
              padding: 1em 1em;
              margin-bottom: -1em;
            }

            width: 100%;
            text-align: left;
            text-decoration: none;

            &.collapsed {
              border: none !important;
              box-shadow: none !important;

              &:focus {
                outline: 1px solid $link-color;
              }

              .accordion-trigger {
                h3,
                span {
                  font-family: $default-font-family;
                }
              }
            }
          }
        }

        .card-body {
          padding: 20px 10px 1.25rem 10px;
          padding-bottom: 1.25rem;
          width: 100% !important;
          background-color: $white;
        }
      }

      // &:first-child {
      //   border-top: 5px solid;
      // }
    }
  }

  .btn-link {

    &:focus,
    &:hover {
      text-decoration: none !important;
      width: 100%;
      text-align: left;
    }

    &.collapsed .accordion-trigger {

      span,
      h3 {
        &:before {
          margin-left: 17px;
        }
      }
    }
  }
}

.accordion-trigger h3,
.accordion-trigger span {
  &:before {
    background: rgba(0, 0, 0, 0) url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='12px' height='8px' viewBox='0 0 12 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 54.1 (76490) - https://sketchapp.com --%3E%3Ctitle%3ECF5BB6A7-C7EB-4F1F-849C-E97AF63E7452%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='CBMA-UI' transform='translate(-22.000000, -318.000000)' fill='%23285A93' fill-rule='nonzero'%3E%3Cg id='Icon-UI/arrow-down-small-blue' transform='translate(28.000000, 322.000000) rotate(180.000000) translate(-28.000000, -322.000000) translate(16.000000, 310.000000)'%3E%3Cg transform='translate(12.000000, 12.000000) rotate(180.000000) translate(-12.000000, -12.000000) translate(6.000000, 8.000000)' id='Shape'%3E%3Cpolygon points='1.41 0.333333333 6 4.65991903 10.59 0.333333333 12 1.66531714 6 7.33333333 0 1.66531714'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat scroll;
    content: "";
    height: 8px;
    left: 0;
    position: absolute;
    width: 12px;
    margin-left: 20px;
    margin-top: 8px;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }

  padding-left: 1em;
  cursor: pointer;
  font-size: 18pt;
  color: $accordion-black;

  &:hover {
    color: $cyan-blue;
  }
}



.accordion-trigger-open h3:before,
.accordion-trigger-open span:before {
  background-position: -150px -50px;
}



.accordion-trigger-disabled {
  cursor: default;

  h3::before,
  span::before {
    background-position: -1px -97px;
    color: $disabled-accordion-color !important;
    cursor: default;
  }

  >* {
    color: $disabled-accordion-color !important;
    font-size: 16px;
  }
}

.accordion-trigger-disabled h3,
.accordion-trigger-disabled span {
  &:before {
    background: rgba(0, 0, 0, 0)url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='12px' height='8px' viewBox='0 0 12 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 54.1 (76490) - https://sketchapp.com --%3E%3Ctitle%3ECF5BB6A7-C7EB-4F1F-849C-E97AF63E7452%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='CBMA-UI' transform='translate(-22.000000, -318.000000)' fill='%23979798' fill-rule='nonzero'%3E%3Cg id='Icon-UI/arrow-down-small-blue' transform='translate(28.000000, 322.000000) rotate(180.000000) translate(-28.000000, -322.000000) translate(16.000000, 310.000000)'%3E%3Cg transform='translate(12.000000, 12.000000) rotate(180.000000) translate(-12.000000, -12.000000) translate(6.000000, 8.000000)' id='Shape'%3E%3Cpolygon points='1.41 0.333333333 6 4.65991903 10.59 0.333333333 12 1.66531714 6 7.33333333 0 1.66531714'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat scroll;
    background-position: -1px !important;
    color: $disabled-accordion-color !important;
    content: "";
    height: 38px;
    left: 0;
    position: absolute;
    width: 35px;
    margin-left: 15px;
    top: 11px;
  }

  padding-left: 1em;
  font-size: 16px !important;
  cursor: default;
}

.accordion-trigger-xs {
  background-color: $light-gray;
  padding: 10px;
}

.primefaces-accordion-trigger-standalone.dark-border-top,
.accordion-trigger.dark-border-top,
.accordion-trigger.dark-border-top.light-theme {
  @include border-top (7px, solid, $dbtp-accordion);
}

.accordion-trigger-disabled.accordion-trigger.dark-border-top {
  @include border-top (7px, solid, $disabled-accordion-color);
}

/* LIGHT THEME */
.accordion-trigger.light-theme h3,
.accordion-trigger.light-theme span {
  margin-top: 10px;
  line-height: 1.367em;

  &:before {
    left: 10px;
  }
}

.accordion-trigger.light-theme {
  background-color: $white;
  @include border-top (1px, solid, $gray);
  padding-bottom: 10px;
}

.accordion-trigger.light-theme {
  &.accordion-trigger-open {
    background-color: $white;

    div {
      &.accordion-content {
        display: block;
      }
    }
  }

  div {
    &.accordion-content {
      display: none;
      padding-left: 3em;
      color: $gray-link-color;
    }
  }
}

/* MOBILE */
@media (max-width: $screen-mobile) {
  .accordion-trigger.light-theme {
    div {
      &.accordion-content {
        padding-left: 0;
      }
    }
  }
}

.accordion-trigger-open.light-theme>p::before {
  background-position: 0px -50px;
  margin-left: 1em;
}

.accordion-trigger-open.light-theme>h3::before,
.accordion-trigger-open.light-theme>span::before {
  background-position: 0px -50px;
}

/**********************/


.accordion-trigger-standalone {
  background-color: $light-gray;
  padding: 10px;
  @include border-top (1px, solid, $gray);
  @include border-bottom(1px, solid, $gray);

  &.accordion-trigger-standalone-open {
    background-color: $bg-accordion-open;
    border-bottom: 1px solid transparent;

    +div {
      @include border-bottom(1pz, solid, $gray);
    }
  }

  h3,
  span {
    padding: .2em 0 0 3em;
    color: $link-color;
    font-size: 1.5em;
    line-height: 2.1875em;
    margin-bottom: 8px;
    font-weight: 100 !important;

    &::before {
      background: url("~assets/images/blueprint/accordion.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
      background-position: -150px 0;
      content: " ";
      height: 38px;
      left: 0;
      position: absolute;
      top: 25px;
      width: 35px;
      margin-left: 1em;
    }
  }
}

.accordion-trigger-standalone-open h3::before {
  background-position: -150px -50px;
  background-color: $bg-accordion-open;
}

/* TABLET & DESKTOP */
@media screen and (min-width: $screen-desktop) {
  .accordion-trigger-standalone+div.md-no-bottom-border {
    border-bottom: 1px solid transparent;
  }
}

/* MOBILE */
@media (max-width: $screen-mobile) {
  .accordion-trigger-xs {
    &.accordion-trigger-open-xs {
      background-color: $bg-accordion-open;
    }

    > {
      p {
        padding: 0.2em 0em 0em 3em;
        color: $link-color;
        font-size: 1.5em;
        line-height: 2.1875em;
        margin-bottom: 8px;
        font-weight: 100 !important;

        &.strong {
          padding: 0.2em 0em 0em 3em;
          color: $link-color;
          font-size: 1.5em;
          line-height: 2.1875em;
          margin-bottom: 8px;
          font-weight: 100 !important;
        }
      }

      div {
        display: none;

        &.accordion-panel-expanded-xs {
          display: block;
          background-color: $bg-accordion-open;
          padding-left: 0;
          padding-right: 0;
        }
      }

      p::before {
        background: url(#{$blueprint-images-path}/accordion.png) no-repeat scroll 0 0 rgba(0, 0, 0, 0);
        background-position: -150px 0px;
        content: " ";
        height: 38px;
        left: 0;
        position: absolute;
        top: 25px;
        width: 35px;
        margin-left: 1em;
      }
    }
  }

  .accordion-trigger-open-xs>p::before {
    background-position: -150px -50px;
    margin-left: 1em;
    background-color: $bg-accordion-open;
  }

  .accordion-trigger-xs {
    @include border-top (1px, solid, $gray);
    cursor: pointer;
  }

  .accordion-trigger-standalone {
    @include border-top (1px, solid, $gray);
    cursor: pointer;
  }

  .accordion-trigger-xs.dark-border-top,
  .accordion-trigger-standalone.dark-border-top {
    @include border-top (7px, solid, $dbtp-accordion);
  }
}

.accordion-trigger-standalone-MALP {
  background-color: $white;
  padding: 10px;
  border: 1px solid $grey;
  margin-bottom: 20px;
  margin-top: 5px;
  overflow: hidden;

  &.accordion-trigger-standalone-open-MALP {
    background-color: $white;
    @include border-bottom(1px, solid, $grey);

    +div {
      @include border-bottom(1px, solid, $gray);
    }

    &.card {
      .h2 {
        box-shadow: 0 0 5px #285a93;

        &::before {
          background: url(#{$blueprint-images-path}/minus_circle.svg) no-repeat scroll 0 0 rgba(0, 0, 0, 0);
        }
      }
    }
  }

  &.card {
    border-radius: 0;
    border-left: none;
    border-right: none;
    // border-top: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
    padding: 10px !important;

    .h2 {
      padding: 0.6em 0 0.5em 3em;
      color: $link-color;
      font-size: 16px;
      margin-bottom: 8px;
      margin-top: 8px;
      font-weight: 100 !important;

      &::before {
        background: url(#{$blueprint-images-path}/plus_circle.svg) no-repeat scroll 0 0 rgba(0, 0, 0, 0);
        // background-position: -150px 0;
        content: " ";
        height: 38px;
        left: 0;
        position: absolute;
        width: 35px;
        margin-left: 1em;
        margin-top: -0.5em;
      }
    }
  }
}

.accordion-trigger-standalone-table {
  padding: 10px;

  &.accordion-trigger-standalone-open {
    background-color: $bg-accordion-open;
    border-bottom: 1px solid $grey;

    +div {
      @include border-bottom(1px, solid, $gray);
    }
  }

  table::before {
    background: url(#{$blueprint-images-path}/plus_circle.svg) no-repeat scroll 0 0 rgba(0, 0, 0, 0);
    // background-position: -150px 0;
    content: " ";
    height: 38px;
    left: 0;
    position: absolute;
    width: 35px;
  }
}

@media (max-width: $screen-mobile) {
  .accordion-trigger-standalone-table {
    padding: 10px;

    &.accordion-trigger-standalone-open {
      background-color: $bg-accordion-open;
      border-bottom: 1px solid $grey;

      +div {
        @include border-bottom(1px, solid, $gray);
      }
    }

    div#xs-selected-account::before {
      background: url(#{$blueprint-images-path}/accordion.png) no-repeat scroll 0 0 rgba(0, 0, 0, 0);
      background-position: -150px 0;
      content: " ";
      height: 38px;
      left: 0;
      position: absolute;
      width: 35px;
    }
  }
}

.dark-border-top {
  @include border-top (7px, solid, $dbtp-accordion);
}

// Accordion without box-shadow in expanded view
.cb-accordions.no-highlight {
  .card {
    .card-header {
      .accordion-trigger .font-style {
        font-size: 14px !important;
        font-weight: 700;
      }

      .accordion-trigger span {
        &:before {
          margin-left: 5px;
        }
        padding-left: 5px;
      }

      button.btn.btn-link {
        &[aria-expanded="true"] {
          box-shadow: none !important;
          padding: 10px;
        }
        &:focus {
          border: none !important;
        }
        &:focus-visible {
          outline: 1px solid $link-focus-color !important;
        }

        &.collapsed .accordion-trigger {
          span,
          h3 {
            &:before {
              margin-left: 10px;
            }
          }
        }
      }
    }

    .card-body {
      padding: 10px 0rem 1.25rem 0rem;
    }
  }
  
}
