@import "/src/assets/styles/blueprint/components_7.1/components/shared/colors";
.gh2b-start-container {
	background: rgb(242,246,247);
	background: -moz-linear-gradient(180deg, rgba(242,246,247,1) 0%, rgba(255,255,255,1) 80%);
	background: -webkit-linear-gradient(180deg, rgba(242,246,247,1) 0%, rgba(255,255,255,1) 80%);
	background: linear-gradient(180deg, rgba(242,246,247,1) 0%, rgba(255,255,255,1) 80%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f2f6f7",endColorstr="#ffffff",GradientType=1);
	padding: 0px;
    max-width: 599px;
	border-top: 0px;
	width: 100%;
	font-family: open_sanssemibold, Arial, Helvetica, Sans-serif;
}	

#osm {
	border: 1px solid $focus-color !important;
	border-left: 10px solid $focus-color !important;
}

.boldText {
	font-weight: 700 !important;
	display: inline;
}

.boldTextYellow {
	font-weight: 700 !important;
	color: #eccc19;
	display: inline;
}

.boldTextRed {
	font-weight: 700 !important;
	color: #c94946;
	display: inline;
}

/* image icons ******************************************************************/
.gh2b-internet-icon {	
	background-image: url(https://webcdn3.cox.com/content/dam/cox/residential/flex/images/icons/brand/laptop-icon-with-wifi-symbol-blue-green-gradient.svg);
	background-repeat: no-repeat;
	background-position: left bottom;
	padding-left: 25px;
	padding-top: 50px;
	min-height: 60px;
	margin-left: 20px;
}

/* intro page ******************************************************************/
.gh2b-header-content {
	margin: 20px 0px 35px 20px;	
}

.gh2b-sub-header-content{
	font-weight: 500;
	margin-bottom: 40px;
	margin-left: 20px;
}

/* intro page - image list ******************************************************************/
.gh2b-item-list-container {
	display: table;
	border-color: $black;
	min-width: 97%;
	border-collapse: collapse;
	margin: auto;	
}
.gh2b-item-row {
	display: table-row;
	max-height: 200px !important;
	height: 200px !important;
}

.gh2b-item-col-1 {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	width: 33%;
	max-height: 200px !important;
	height: 200px !important;
}

.gh2b-item-col-1 .gh2b-list-img {
	max-width: 200px;
	max-height: 160px;
	padding: 20px 0px 20px 30px;
}

.gh2b-item-col-2 {
	display: table-cell;
	vertical-align: middle;
	width: 67%;
	color: $tab-font;
	padding: 0px 0px 0px 30px;
	max-height: 200px !important;
	height: 200px !important;
}

.gh2b-item-borders {
	border-top: 1px solid $border;
	border-bottom: 1px solid $border;
}

.gh2b-opt-text {	
	color: $picklist-border;
	margin: 15px 0px 0px 0px;
}

/* end intro page ******************************************************************/

.gh2b-container {
	margin: auto;
	margin-top: 0px;
    max-width: 750px;
	padding: 35px 20px 0px 20px;
	text-align: top;
    font-size: 14px !important;
}
.kgiweb-container {
	margin: auto;
	margin-top: 0px;
    max-width: 750px;
	padding: 15px 20px 0px 20px;
	text-align: top;
    font-size: 14px !important;
}

.gh2b-step-image, .kgiweb-step-image {
	margin: auto;
	padding: 35px 0px 35px 0px;
	border-top: 4px dotted $border;
	border-bottom: 4px dotted $border;
	display: table;
	width: 100%;
	text-align: center;
	margin-bottom: 25px;
	vertical-align: center;
}

.gh2b-step-image img, .kgiweb-step-image img {
	margin-left: auto; 
	margin-right: auto; 
	max-height: 250px;
}

.gh2b-step-container, .kgiweb-step-container {
	margin: auto;
}

.gh2b-step-number {
	font-size: 32px !important;
	color: $hover-button;
	direction: rtl;
	text-align: right;
	margin-top: 0px;
	padding: 0px 0px 0px 0px;
	display: block;
	position: absolute;
}

.kgiweb-step-number {
	font-size: 16px !important;
	color: $hover-button;
	direction: rtl;
	text-align: right;
	margin-top: -3px;
	padding: 0px 0px 0px 0px;
	display: block;
	position: absolute;
}

.gh2b-step-instructions {
	padding: 0px 40px 0px 50px;
	font-weight: 300;
	color: $light-black;
}

.kgiweb-step-instructions {
	padding: 0px 20px 0px 20px;
	font-weight: 300;
	color: $light-black;
}
.kgiweb-step-instructions h2{
	font-size: 16px!important;
	font-weight: 600;
}

.gh2b-step-note {
	padding: 0px 40px 0px 50px;
	color: $light-black;
	margin-top: 30px;
	margin-bottom: 30px;
}

.kgiweb-step-note {
	padding: 0px 20px;
	color: $light-black;
	margin-top: 30px;
	margin-bottom: 30px;
}

.kgiweb-step-note .msg-info{
	padding: 10px;
}

.kgiweb-leadHeading p{
	font-weight: 700;	
	padding: 0;
}

.kgiweb-leadHeading {
	padding: 15px 0;
	font-weight: 700;
}

.gh2b-nostep-header {
	padding: 0px 0px 0px 0px;
	color: $light-black;
}

.gh2b-nostep-note {
	padding: 0px 0px 0px 0px;
	color: $light-black;
	margin-top: 30px;
	margin-bottom: 30px;
}

/* progress bar ******************************************************************/
.gh2b-progress-bar {
	color: #fff;
	text-align: center;
	background-color: #f1f1f1 !important;
	border-radius: 10px;
	margin-bottom: 35px;
}

.gh2b-progress-fill {
	background: linear-gradient(145.72deg,#009ae0,#002f87);
	
}

.gh2b-progress-bar-text {
	margin: 20px 0px 8px 0px;
}

.gh2b-noprogress-bar-spacer {
	margin:0px 0px 0px 0px;
	/*font-size: 1vw !important;*/
}

/* throbber ******************************************************************/
.gh2b-throbber {
	margin: auto;
	padding: 19px 0px 15px 0px;
	border-bottom: 4px dotted $border;
	display: table;
	width: 100%;
	text-align: center;
	margin-bottom: 25px;
	vertical-align: center;
}

.gh2b-throbber img {
	margin-left: auto; 
	margin-right: auto; 
	max-height: 500px;
}
/* gh2b end ********************************************************************/

