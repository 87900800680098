p-picklist{
    @each $theme, $map in $themes {
        .#{$theme} & {
            .p-picklist {
                .p-picklist-header {
                    text-align: left;
                    font-size: 16px;
                    background: $picklist-header;
                    border: 1px solid $picklist-border;
                    color: $picklist-text ;
                    padding: .5em .75em;
                }
                .p-picklist-list-wrapper {
                    width: 42%;
                }
                .p-picklist-list {
                    border: 1px solid $gray-picklist;
                    background: $white;
                    color: $text-color;
                
                    .p-picklist-item {
                        background: $white;
                        color: $text-color;
                        padding: 12px 5px;
                    }
                        .p-picklist-item:not(.p-highlight):hover {
                            background-color: $bg-picklist;
                            color: $text-color;
                        }
                        .p-picklist-item.p-highlight {
                            background-color: $highlight-bg;
                            color: $white;
                            border:none;
                        }
                }
                .p-picklist-source-controls {
                    display: none !important;
                }
                .p-picklist-transfer-buttons {
                    justify-content: flex-start;
                   [icon='pi pi-angle-right'] {
                        background: url("~assets/images/picklist/picklist-add.svg") no-repeat;
                    }
                    [icon='pi pi-angle-double-right'] {
                        background: url("~assets/images/picklist/picklist-add-all.svg") no-repeat;
                    }
                    [icon='pi pi-angle-left'] {
                        background: url("~assets/images/picklist/picklist-remove.svg") no-repeat;
                        margin-top: 30px;
                    }
                    [icon='pi pi-angle-double-left'] {
                        background: url("~assets/images/picklist/picklist-remove-all.svg") no-repeat;
                    }
                    .p-state-default[pbutton=''] {
                        padding: 10px 12px;
                    }

                }
                @media screen and ( min-width:$tablet-screen-min) and (max-width:$tablet-screen-max) {
                    .p-picklist-transfer-buttons [icon='pi pi-angle-left'] {
                        margin-top: 6px !important;
                    }
                }
                .p-picklist-target-controls {
                    display: flex!important;
                    flex-direction: row;
                    position: absolute!important;
                    top: 353px!important;
                    left: 75%!important;
                    padding: 0.5px;
                    vertical-align: middle;
                    margin: 10px;
                    width: 150px;
                    [icon='pi pi-angle-up'] {
                        background:url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='35px' height='35px' viewBox='0 0 35 35' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 54.1 (76490) - https://sketchapp.com --%3E%3Ctitle%3E81EA2A53-AD37-4BD5-B734-6DB21CC39D4B%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='CBMA-UI' transform='translate(-61.000000, -943.000000)'%3E%3Cg id='picklist-forward-arrow' transform='translate(61.000000, 943.000000)'%3E%3Cg id='button'%3E%3Crect id='Rectangle-Copy-21' fill='%23002F87' x='0' y='0' width='35' height='35'%3E%3C/rect%3E%3Cpolygon id='Shape-Copy' fill='%23FFFFFF' fill-rule='nonzero' transform='translate(18.000000, 17.500000) rotate(180.000000) translate(-18.000000, -17.500000) ' points='13.41 14 18 18.3265857 22.59 14 24 15.3319838 18 21 12 15.3319838'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat;
                    }
                    [icon='pi pi-angle-double-up'] {
                        background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='35px' height='35px' viewBox='0 0 35 35' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 54.1 (76490) - https://sketchapp.com --%3E%3Ctitle%3ED5CAA963-A466-4F95-8DB2-621A37A2D534%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='CBMA-UI' transform='translate(-106.000000, -943.000000)'%3E%3Cg id='picklist-first-arrow' transform='translate(106.000000, 943.000000)'%3E%3Cg id='Group-3-Copy-3' fill='%23002F87'%3E%3Crect id='Rectangle-Copy-21' x='0' y='0' width='35' height='35'%3E%3C/rect%3E%3C/g%3E%3Cg id='button' transform='translate(12.000000, 13.000000)' fill='%23FFFFFF'%3E%3Crect id='Rectangle' x='0' y='0' width='12' height='2'%3E%3C/rect%3E%3Cpolygon id='Shape-Copy-2' fill-rule='nonzero' transform='translate(6.000000, 5.500000) rotate(180.000000) translate(-6.000000, -5.500000) ' points='1.41 2 6 6.3265857 10.59 2 12 3.33198381 6 9 0 3.33198381'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat;
                    }
                    [icon='pi pi-angle-down'] {
                        background:url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='35px' height='35px' viewBox='0 0 35 35' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 54.1 (76490) - https://sketchapp.com --%3E%3Ctitle%3EEBF634D4-7C99-4B47-9283-3C2C09AA2C63%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='CBMA-UI' transform='translate(-16.000000, -943.000000)'%3E%3Cg id='picklist-down-arrow' transform='translate(16.000000, 943.000000)'%3E%3Cg id='button'%3E%3Crect id='Rectangle-Copy-21' fill='%23002F87' x='0' y='0' width='35' height='35'%3E%3C/rect%3E%3Cpolygon id='Shape' fill='%23FFFFFF' fill-rule='nonzero' points='13.41 14 18 18.3265857 22.59 14 24 15.3319838 18 21 12 15.3319838'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat;
                    }
                    [icon='pi pi-angle-double-down'] {
                        background:url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='35px' height='35px' viewBox='0 0 35 35' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 54.1 (76490) - https://sketchapp.com --%3E%3Ctitle%3E2AD1C5A5-5379-4249-9358-284745F97F9F%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='CBMA-UI' transform='translate(-151.000000, -943.000000)'%3E%3Cg id='picklist-last-arrow' transform='translate(151.000000, 943.000000)'%3E%3Cg id='Group-3-Copy-4' fill='%23002F87'%3E%3Crect id='Rectangle-Copy-21' x='0' y='0' width='35' height='35'%3E%3C/rect%3E%3C/g%3E%3Cg id='button' transform='translate(18.000000, 17.500000) rotate(180.000000) translate(-18.000000, -17.500000) translate(12.000000, 13.000000)' fill='%23FFFFFF'%3E%3Crect id='Rectangle' x='0' y='0' width='12' height='2'%3E%3C/rect%3E%3Cpolygon id='Shape-Copy-2' fill-rule='nonzero' transform='translate(6.000000, 5.500000) rotate(180.000000) translate(-6.000000, -5.500000) ' points='1.41 2 6 6.3265857 10.59 2 12 3.33198381 6 9 0 3.33198381'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat;
                    }
                    button {
                        height: 35px;
                    }
                }
                .p-picklist-buttons .p-button{
                    margin-bottom: 0.25em;
                    width: 130px;
                    border-radius: 0rem;
                    border:none;
                    padding: 10px 12px;
                    
                }
               
                [icon='fa-angle-double-left'] .fa-angle-double-left::before,
                [icon='fa-angle-left'] .fa-angle-left::before,
                [icon='fa-angle-double-right'] .fa-angle-double-right::before,
                [icon='fa-angle-right'] .fa-angle-right::before,
                [icon='fa-angle-up'] .fa-angle-up::before,
                [icon='fa-angle-double-up'] .fa-angle-double-up::before,
                [icon='fa-angle-down'] .fa-angle-down::before,
                [icon='fa-angle-double-down'] .fa-angle-double-down::before {
                    content: none;
                }
            }
            .p-picklist .p-picklist-list {
                padding:0px;
            }
           
            .p-button {
                display: grid;
                border: none;
                margin-left: 0em !important;
            }
        }
    }
}

  