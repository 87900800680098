@font-face {
    font-weight: normal;
    font-style: normal;
    font-family: "open_sansregular";
    src: url("~assets/fonts/OpenSans-Regular-webfont.woff") format("woff"),
    url("~assets/fonts/OpenSans-Regular-webfont.ttf") format("truetype");
}

/* open sans - semi-bold */
@font-face {
    font-family: "open_sanssemibold";
    font-weight: normal;
    font-style: normal;
    src: url("~assets/fonts/open-sans.semibold-webfont.woff") format("woff"),
    url("~assets/fonts/open-sans.semibold-webfont.ttf") format("truetype");
}

/* open sans - bold */
@font-face {
    font-family: "open_sansbold";
    font-weight: normal;
    font-style: normal;
    src: url("~assets/fonts/OpenSans-Bold-webfont.woff") format("woff"),
    url("~assets/fonts/OpenSans-Bold-webfont.ttf") format("truetype");
}


/** cera pro font **/
/* open sans - bold */
@font-face {
    font-family: "cerapro-regular";
    font-weight: normal;
    font-style: normal;
    src: 
        url("~assets/fonts/cera-pro/cerapro-regular-webfont.woff") format("woff"),
        url("~assets/fonts/cera-pro/cerapro-regular-webfont.woff2") format("woff2"),
        url("~assets/fonts/cera-pro/CeraPro-Regular.otf") format("opentype");
}

@font-face {
    font-family: 'cerapro-bold';
    src: url('~assets/fonts/cera-pro/cerapro-bold-webfont.woff2') format('woff2'),
         url('~assets/fonts/cera-pro/cerapro-bold-webfont.woff') format('woff'),
         url("~assets/fonts/cera-pro/CeraPro-Bold.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'cerapro-medium';
    src: url('~assets/fonts/cera-pro/cerapro-medium-webfont.woff2') format('woff2'),
         url('~assets/fonts/cera-pro/cerapro-medium-webfont.woff') format('woff'),
         url("~assets/fonts/cera-pro/CeraPro-Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}
