@import "../shared/colors";
// Extra small screen / phone
$screen-xs: 768px !default;
$screen-xs-min: $screen-xs !default;
$xs-screen-min: 320px;
$xs-screen-max: 575px;

// Medium screen / desktop
$mobile-screen-min: 576px;
$mobile-screen-max: 767px;
$tablet-screen-max: 995px;
$screen-md: 960px !default;
$screen-md-min: $screen-md !default;

$screen-mobile: ($screen-xs-min - 1) !default;
$screen-tablet: ($screen-md - 1) !default;
$screen-desktop: $screen-xs-min !default;

//Extra small screen/phone landscape
$screen-xs-landscape: 824px !default;
$screen-xs-landscape-1: 736 !default;

//Ipad Dimensions
$screen-ipad-min: 768px;
$screen-ipad-max: 1024px;

//DSM based screen breakpoints
$xs-screen-min: 320px;
$xs-screen-max: 575px;
$mobile-screen-min: 576px;
$mobile-screen-max: 767px;
$tablet-screen-min: 768px;
$tablet-screen-max: 995px;
$desktop-screen-min: 996px;
$desktop-screen-max: 1199px; 
$xl-screen-min: 1200px;

$blueprint-images-path: "~assets/images/blueprint";
$images-path : "~assets/images";
$icons-path : "~assets/images/blueprint/icons";

// $default-font-family: 'open_sansregular', Arial, Helvetica, sans-serif;
$default-font-family: 'cerapro-regular', Helvetica, sans-serif;
$default-font-size: 16px; 
$default-background: $white;
$default-font-color: $gray-link-color;
$default-font-family-bold: 'cerapro-bold', Helvetica, sans-serif;
$default-font-family-medium: 'cerapro-medium', Helvetica, sans-serif;
