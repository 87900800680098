
/* reset styles */

:focus {
  outline: 0;
}

::-moz-focus-inner {
  border: 0;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: $link-color;
}

/* Ensure the tall letters are not not cut off */

label {
  line-height: normal;
}

span {
  &.link-enabled {
    pointer-events: auto;
    cursor: pointer;
    color: $link-color;
  }
  &.link-disabled {
    pointer-events: none;
    cursor: auto;
    color: $link-color-disabled;
  }
}

a {
  &:focus {
    text-decoration: underline;
  }
  &.disabled {
    color: $link-color-disabled !important;
    pointer-events: none;
    cursor: default;
  }
}

hr {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 1px;
  border: 0;
  &.page-divider {
    margin-top: 20px;
    margin-bottom: 20px;
    background: $gray;
  }
  &.inline-page-divider {
    background: $gray;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 100;
}

h1 {
  font-size: 2.500em;
  /*  40px; */
  line-height: 1.100em;
  /* 44px */
  margin-bottom: 8px;
  /* gives 15px bottom margin with line height and margin */
}

h2 {
  font-size: 1.875em;
  /* 30px; */
  line-height: 1.133em;
  /* 34px */
  margin-bottom: 8px;
  /* gives 15px bottom margin with line height and margin */
}

@media (min-width: $screen-desktop) {
  h2.title, h1.title {
    margin-bottom: 30px;
    margin-top: 30px;
  }
}

h3 {
  font-size: 1.500em;
  /* 24px */
  line-height: 1.167em;
  /* 28px */
  margin-bottom: 8px;
  /* gives 15px bottom margin with line height and margin */
}

h4 {
  font-size: 1.250em;
  /* 20px */
  line-height: 1.333em;
  /* 24px */
  margin-bottom: 8px;
  /* gives 15px bottom margin with line height and margin */
}

h5 {
  font-family: "cerapro-regular", Helvetica, sans-serif;
  font-size: 1em;
  /* 16px */
  line-height: 1.250em;
  /* 20px */
  margin-bottom: 5px;
}

h6 {
  font-family: "cerapro-regular", Helvetica, sans-serif;
  font-size: 0.875em;
  /* 14px */
  line-height: 1.286em;
  /* 18px */
  margin-bottom: 5px;
}

strong, b {
  font-family: "cerapro-regular", Helvetica, sans-serif;
  font-weight: 100;
}

small {
  font-size: 12px;
}

ul.flat {
  list-style: none;
  padding-left: 0px;
  li {
    padding-bottom: 10px;
    a.strong {
      font-family: "cerapro-regular", Helvetica, sans-serif;
      font-weight: 100;
    }
  }
}

p {
  line-height: 1.250em;
  margin-bottom: 20px !important;
  word-wrap: break-word !important;
  &#page-toaster-text {
    margin-top: 10px;
  }
}

.line-height-50{
  line-height: 50px !important;
}
.break-all {
  word-break: break-all !important;
}

.break-word {
  word-break: break-word !important;
}

/* WORD BREAK - IE FIX */

wbr:after {
  content: "\00200B";
}

/* top level bullets - should be completely left aligned */

ul, ol {
  margin: 0 0 15px 0;
  padding-left: 22px;
}

ul ul, ol ol {
  margin: 0;
}

table {
  border-collapse: collapse;
}

th, td {
  text-align: left;
}

img {
  border: 0;
  max-width: 100%;
}

/* superscript */

sup {
  font-size: 60%;
  /* needs to be bigger by default because anything smaller is not readable at 12px */
  margin-left: 2px;
  &.symbol {
    font-size: 100%;
  }
}

.f4box {
  background: $light-gray none repeat scroll 0 0;
  margin-bottom: 20px;
  padding: 10px;
  overflow: hidden;
}

.fffbox {
  background: $white none repeat scroll 0 0;
  margin: 10px;
  padding: 10px;
}

@media screen and (max-width: $screen-mobile) {
  .f4box {
    background: $white none repeat scroll 0 0;
    margin-bottom: 20px;
    overflow: hidden;
    padding: 10px;
  }
  .fffbox {
    background: $light-gray none repeat scroll 0 0;
  }
}


// carousel fixes
.carousel{
  .carousel-inner{
    border-bottom: solid 3px $blue00;
    box-shadow: 0px 5px 3px -2px $gray;
  }
}


