/* GLYPHICONS */

@font-face {
  font-family: "Glyphicons Halflings";
  src: url("~assets/fonts/glyphicons-halflings-regular.eot");
  src: url("~assets/fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"), url("~assets/fonts/glyphicons-halflings-regular.woff2") format("woff2"), url("~assets/fonts/glyphicons-halflings-regular.woff") format("woff"), url("~assets/fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("~assets/fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular") format("svg");
}


.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyphicon-chevron-up:before {
  content: "\e113";
}

.glyphicon-chevron-down:before {
  content: "\e114";
}

.glyphicon-usd:before {
  content:"\e148";
}

.glyphicon-ok:before {
  content:"\e013";
}

.glyphicon-remove:before {
  content:"\e014";
}

.glyphicon-plus-sign:before {
  content: "\e081";
}

.glyphicon-minus-sign:before {
  content: "\e082";
}

.glyphicon-chevron-right:before {
  content: "\e080";
}

.glyphicon-chevron-left:before {
  content: "\e079";
}

.glyphicon-triangle-right:before {
  content: "\e250";
}

.glyphicon-triangle-left:before {
  content: "\e251";
}

.glyphicon-triangle-bottom:before {
  content: "\e252";
}

.glyphicon-triangle-top:before {
  content: "\e253";
}
