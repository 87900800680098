/* CHEVRONS DEFAULT ASCENDING & DESCENDING */

table.dataTable thead {
  .sorting::after, .sorting_asc::after, .sorting_desc::after {
    position: relative;
    top: 1px;
    float: right;
    margin-right: 10px;
    display: inline-block;
    /* GLYPHICONS FONT FACE LOADED BEFORE */
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .sorting.mt-5::after, .sorting_asc.mt-5::after, .sorting_desc.mt-5::after {
    margin-top: 0.5em;
  }
  .sorting::after, .sorting_desc::after {
    content: "\e114";
  }
  .sorting_asc::after {
    content: "\e113";
  }
}

/* DEFAULT - DOWN */

/****************************************/
/* BORDER BOTTOM */

table.dataTable.highlight-sort thead {
  .sorting_asc, .sorting_desc{
    border-bottom: 3px solid $black;
  }
}

/* DEFAULT - DOWN */

/****************************************/

/* TABLE HELPERS */

/* MOUSE */

th.cursor-auto {
  cursor: auto !important;
}

/****************************************/

/* TEXT */

.table-layout {
  table-layout:fixed;
}

/* TABLET & DESKTOP */
@media screen and (min-width: $screen-desktop) {
  .td-md-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

/****************************************/

/* BORDER */

/* TABLET & DESKTOP */
@media screen and (min-width: $screen-desktop) {
  .tb-ver-line table.dataTable {
    th + th, td + td {
      border-left: 1px solid #e0dacb;
    }
  }
}

/****************************************/

/* MISCELLANEOUS */

/* PADDING */

td {
  &.pad-top-bottom-p-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  &.gray-border-top-1 {
    border-top: 1px solid $gray;
  }
  &.gray-border-bottom-1 {
    border-bottom: 1px solid $gray;
  }
}

/* BORDERS */

/* MOBILE */
@media screen and (max-width: $screen-mobile) {
  table.pointer tr {
    cursor: pointer;
  }
  .dataTable {
    margin-bottom: 0;
  }
  /* EMPTY TABLE */
  table.empty-table-xs tr {
    td.dataTables_empty {
      padding-bottom: 1em;
      word-break: break-all;
    }
    background-color: rgb(229, 229, 229) !important;
    border-top: 1px solid transparent !important;
    display: table;
    width: 100%;
  }
  /* SEE MORE */
  span#val-t-load-more, .see-more {
    background: white;
    width: 100%;
    text-align: center;
    cursor: pointer;
    padding: 1em;
  }
  /* LAST CHILD SPACING */
  .last-child-margin-bottom-1 td:last-child {
    margin-bottom: 1em;
  }

}

@media screen and (max-width: $screen-mobile){
	table.easypay-theme tbody tr
	{
		border:1px solid #ccc;
	}
	table.easypay-theme thead{
	background-color: white;
	}
	table.easypay-theme tbody{
	background-color: #f4f4f4;
	}
	table.easypay-theme .vertical-aign-top{
	vertical-align:top;
}
	table.pad-top-bottom-15{
	padding-top:15px;
	padding-bottom:15px;
}
	table.pad-top-10{
	padding-top:10px;
}
	table.pad-bottom-5{
	padding-bottom:5px;
}
	table.pad-left-0{padding-left:0px;}
	table.pad-right-0{padding-right:0px;}

}

/****************************************/

/*****************/

/* DEFAULT GRAY THEME */

.gray-theme.stripe tr.odd {
  background: $light-gray;
}

.dark-gray-theme {
  &.stripe tr.odd {
    background: $tone-bone-white;
  }
  td.dataTables_empty {
    padding: 1em 0 1em 10px;
    word-break: break-all;
  }
}


/* LIGHT THEME */

table.light-theme tbody tr, table.light-theme thead tr {
  border-bottom: 1px solid $gray;
}

table.light-theme tbody tr input[type=text] {
  margin: 0
}

/***/
table.easypay-theme td:nth-child(2), table.easypay-theme th:nth-child(2)
	{
	border-right: 1px solid #ccc;
}
table.easypay-theme td:nth-child(3), table.easypay-theme th:nth-child(3)
{
	padding-left:1em !important
}

/**************************/

table.lce thead {
  border-top: 0.25em solid black;
  background: $light-gray;
  color: $link-focus-color;
  th {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

/* MOBILE */
@media screen and (max-width: $screen-mobile) {
  /* MOBILE TABLE - SP STYLE */
  .mtbrsp {
    table, thead, tbody, th, td {
      display: block;
    }
    &.stripe tr.odd {
      background: $gray-15;
    }
    table {
      &.gray-theme tr {
        background-color: $light-gray;
        border-top: 1px solid $gray;
      }
      tr {
        border-top: 1px solid $gray;
      }
      &.gray-theme tr + tr, tr + tr {
        border-bottom: 1px solid $gray;
      }
      &.gray-theme tbody tr td, tbody tr td {
        padding-left: 1em;
        padding-right: 1em;
      }
    }
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    td {
      border: none;
      position: relative;
      float: left;
      padding: 1em 0 0 0;
      div p {
        span {
          padding-left: 0.5em;
        }
        color: $link-color;
      }
    }
  }

   .mtbrsp-fixed {

    table.stripe tr.odd {
      background: $gray-15;
    }
    table {
      &.gray-theme tr {
        background-color: $light-gray;
        border-top: 1px solid $gray;
      }
      tr {
        border-top: 1px solid $gray;
      }
      &.gray-theme tr + tr, tr + tr {
        border-bottom: 1px solid $gray;
      }
      &.gray-theme tbody tr td, tbody tr td {
        padding-left: 1em;
        padding-right: 1em;
      }
    }
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    td {
      border: none;
      position: relative;
      float: left;
      padding: 1em 0 0 0;
      div p {
        span {
          padding-left: 0.5em;
        }
        color: $link-color;
      }
    }
  }
}

/** PAGINATION */

.paging {
  background: white;
  padding-top: 2em;
  padding-left: 0;
  .dataTables_paginate {
    text-align: left;
    padding-bottom: 10px;
  }
  .paginate_button :focus {
    text-decoration: none;
    border-radius: 2px;
    height: 28px;
  }
  .current {
    color: $black;
    border-radius: 2px;
    border: 1px solid $gray;
    background-color: $white;
    @include box-shadow(1px 1px 5px 0px rgba(215, 215, 215, 1));
    padding: 1px 9px 4px 7px !important;
  }
  .paginate_button {
    width: 30px;
    height: 30px;
    font-size: 1.125em;
    padding: 1px 9px 4px 20px;
  }
  .paginate_button.disabled {
    pointer-events: none;
    cursor: default;
    border: none !important;
    box-shadow: none !important;
    background-color: lightgray;
  }
  a.first,
  a.last {
    margin-top: -30px;
  }
  a.first,
  a.previous,
  a.next,
  a.last {
    background-image: url("~assets/images/blueprint/icons/pagination.png");
    background-repeat: no-repeat;
    color: transparent;
  }
  .paginate_button.first {
    background-position: 0 50%;
    background-color: $link-color;
  }
  .paginate_button.previous {
    background-position: -30px 50%;
    margin: -30px 12px 0 10px;
    background-color: $link-color;
  }
  .paginate_button.next {
    background-position: -60px 50%;
    margin: -30px 10px 0 15px;
    background-color: $link-color;
  }
  .paginate_button.last {
    background-position: -90px 50%;
    background-color: $link-color;
  }
  .paginate_button.disabled {
    background-color: $gray-10;
  }
}
.paginate_button_license{
	padding: 1px 7px 4px 14px !important;
}
/*******************/

/** MOBILE */
@media screen and (max-width: $screen-mobile) {

  table.mobile-friendly {
    display: block;
    thead, tbody, th, td, tr {
      display: block;
    }
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    td {
      border: none;
      position: relative;
      padding-left: 50%;
    }
    td:before {
      position: absolute;
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
    }
    td:nth-of-type(n):before {
      content: attr(title);
    }
  }
}

/* ROW EXPANSION */

.rowExpansion table.dataTable tr.expand + tr {
  display: none !important;
}

.rowExpansion table.dataTable tr.expand.open + tr {
  display: table-row !important;
  background: $gray-15;
}

.rowExpansion table.dataTable tr.expand.open {
  border-bottom: none !important;
  background: $gray-15;
}

.rowExpansion table.defocus tr.expand:not(open), .rowExpansion table.defocus thead.toplevel tr {
  opacity: 0.5;
}

/* NO BORDERS */
.noborders tr {
  border: 0 !important;
}

.noborder {
  border: 0 !important;
}

/**************************************/
/* PRIMEFACES DATATABLE */
/**************************************/
.default-table {
  border: 1px solid transparent !important;
  thead th {
    border: 1px solid transparent !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  tbody tr{
	border: 1px solid transparent !important;
  }
  tr.ui-widget-content.ui-datatable-even {
    background-color: rgb(229, 229, 229);
  }
}


/* MOBILE */
@media screen and (max-width: $screen-mobile) {
  /* MOBILE TABLE - SP STYLE */
  .default-table {
    table {
      padding-top: 1em;
    }
    table, thead, tbody, th, td {
      display: block;
    }
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    td {
      border: none;
      position: relative;
      float: left;
      width: 100%;
      padding-left: 1em;
      padding-right: 1em;
    }
  }
    .paginate_button_license-xs{
	padding: 1px 5px 4px 10px !important;
}
}

@media screen and (min-width: $screen-desktop) {
.ver-col-lines{
	thead th:nth-child(2), thead th:nth-child(3), tbody tr td:nth-child(2), tbody tr td:nth-child(3){
		border-left: 2px solid #ddd !important;
	}
}
.toggle-center{
	thead th:nth-child(2), tbody tr td:nth-child(2){
		padding-left: 7em;
	}
}

td {
  &.pad-md-top-bottom-p-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  	}
  }
}
