@keyframes mat-progress-spinner-linear-rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes mat-progress-spinner-stroke-rotate-100 {
    0% {
        stroke-dashoffset: 268.606171575px;
        transform: rotate(0);
    }
    12.5% {
        stroke-dashoffset: 56.5486677px;
        transform: rotate(0);
    }
    12.5001% {
        stroke-dashoffset: 56.5486677px;
        transform: rotateX(180deg) rotate(72.5deg);
    }
    25% {
        stroke-dashoffset: 268.606171575px;
        transform: rotateX(180deg) rotate(72.5deg);
    }
    25.0001% {
        stroke-dashoffset: 268.606171575px;
        transform: rotate(270deg);
    }
    37.5% {
        stroke-dashoffset: 56.5486677px;
        transform: rotate(270deg);
    }
    37.5001% {
        stroke-dashoffset: 56.5486677px;
        transform: rotateX(180deg) rotate(161.5deg);
    }
    50% {
        stroke-dashoffset: 268.606171575px;
        transform: rotateX(180deg) rotate(161.5deg);
    }
    50.0001% {
        stroke-dashoffset: 268.606171575px;
        transform: rotate(180deg);
    }
    62.5% {
        stroke-dashoffset: 56.5486677px;
        transform: rotate(180deg);
    }
    62.5001% {
        stroke-dashoffset: 56.5486677px;
        transform: rotateX(180deg) rotate(251.5deg);
    }
    75% {
        stroke-dashoffset: 268.606171575px;
        transform: rotateX(180deg) rotate(251.5deg);
    }
    75.0001% {
        stroke-dashoffset: 268.606171575px;
        transform: rotate(90deg);
    }
    87.5% {
        stroke-dashoffset: 56.5486677px;
        transform: rotate(90deg);
    }
    87.5001% {
        stroke-dashoffset: 56.5486677px;
        transform: rotateX(180deg) rotate(341.5deg);
    }
    100% {
        stroke-dashoffset: 268.606171575px;
        transform: rotateX(180deg) rotate(341.5deg);
    }
}

.cb-spinner {
    @each $theme, $map in $themes {
        .#{$theme} & {
            position: relative;            
            width: 70px;
            // height: 70px;
            z-index: 9999;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            svg {
                position: absolute;
                transform: rotate(-90deg);
                top: 0;
                left: 0;
                transform-origin: center;
                overflow: visible;
                animation: mat-progress-spinner-linear-rotate 2000ms linear infinite;
            }
            circle {
                stroke: element-type($map, default-gradient, 'color2');
                fill: transparent;
                transform-origin: center;
                transition: stroke-dashoffset 225ms linear;
                transition-property: stroke;
                animation-duration: 4000ms;
                animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
                animation-iteration-count: infinite;
            }
        }
    }
}
.cb-secondary-spinner {
    width: 100%;
    height: 100%;
    top: 0;
    // height: 0;
    position: absolute;
	// background: rgba(255,255,255, 0.4);
    filter: alpha(opacity = 80);
    z-index: 9990;

    .cb-spinner {
        @each $theme, $map in $themes {
            .#{$theme} & {
                position: fixed;
                top: 50%;
                left: 50%;
                width: 100px;
                height: 100px;
                z-index: 9999;                
            }
        }
    }
}