/*
 Position and hide the real checkboxes and radio buttons.
 The inputs are made transparent instead of completely hidden to preserve
 clickability in browsers that don't have clickable labels.
 */

 input[type="checkbox"],
 .cb-checkbox {
   position: absolute;
   /* Match the image dimensions */
   width: 24px !important;
   height: 24px;
   /* Reset anything that could peek out or interfere with dimensions */
   overflow: hidden;
   margin: -3px 0 0;
   padding: 0;
   outline: 0;
   opacity: 0;
 
   +label {
     display: inline-block;
     margin: 15px 10px 0px 0;
 
     &:before {
       display: inline-block;
       margin: -3px 12px 8px 0px;
       /* negative margin to account for focus outer glow */
       background-position: 0 0;
       background-repeat: no-repeat;
       content: " ";
       vertical-align: top;
     }
   }
 
   &:focus+label:before,
   &:hover+label:before {
     /* unchecked - focused */
     border: 2px solid $cyan-blue;
   }
 
   &:checked {
     +label:before {
       /* checked */
       background-image: url("~assets/images/confirmation_check_white.svg");
       background-color: $checked-color;
       background-position: center;
     }

     &:focus+label:before{
      border: 1px solid $link-focus-color!important;
      -webkit-box-shadow: 0 0 5px $link-focus-color!important;
      box-shadow: 0 0 5px $link-focus-color!important;
     }
   }
 
   +label:before {
     height: 24px;
     width: 24px;
     background-color: $white;
     border-radius: 3px;
     display: inline-block;
     border: 2px solid $accordion-black;
   }
 
   &.error {
     +label:before {
       /* unchecked */
       border: 2px solid $alertErrorLeft;
     }
 
     &:focus+label:before,
     &:hover+label:before {
       /* unchecked - focused */
       border: 2px solid $cyan-blue;
     }
 
     &:checked {
       +label:before {
         /* checked */
         background-color: $alertErrorLeft;
         background-image: url("~assets/images/confirmation_check_white.svg");
         background-position: center;
       }
 
       &:focus+label:before {
         /* checked - focused */
         border: 2px solid $cyan-blue;
       }
     }
 
     +label:before {
       height: 24px;
       width: 24px;
       background-color: $white;
       border-radius: 3px;
       display: inline-block;
       border: 2px solid $alertErrorLeft;
     }
   }
 
   &:disabled {
     +label:before {
       /* unchecked */
       border: 2px solid $disabled-grey;
     }
 
     &:checked {
       +label:before {
         /* checked */
         background-image: url("~assets/images/confirmation_check_white.svg");
         background-position: center;
         background-color: $disabled-grey;
       }
     }
 
     +label:before {
       height: 24px;
       width: 24px;
       background-color: $white;
       border-radius: 3px;
       display: inline-block;
       border: 2px solid $disabled-grey;
     }
   }
 }
 
 /** add this class when u want to show a hyphen(usually in selected count)**/
 input[type="checkbox"].customCheckboxContent {
   &:checked {
     +label:before {
       border: 2px solid $checked-color;
       background-image: url("~assets/images/minus_white.svg");
       background-position: center;
     }
   }
 }
 
 /** use this heirarchy of classes customCheckboxHolder > customcheckbox 
   when u want to invert the default checkbox css**/
 .customCheckboxHolder input[type=checkbox].customcheckbox {
   margin: 0;
 
   +label:before {
     margin: 0 !important;
     border: 2px solid $white;
   }
 
   &:checked {
     +label:before {
       background-color: $white !important;
       color: $tooltip-color;
     }
   }
 }
 
 
 /*
   Insert a pseudo element inside each label and give it a background
   image that will become the custom checkbox or radio button.
   Using inline-block lets you use vertical-align to adjust it vertically
   as needed.
   */
 
 /* Position the background image differently depending on the state of each checkbox and radio button. */
 
 /* checkbox/radio button */
 
 /* ERROR */
 
 /* checkbox/radio button */
 
 /************************/
 
 /* ERROR */
 
 /* checkbox/radio button */
 
 /************************/
 /* TABLET & DESKTOP */
 @media screen and (min-width: $screen-mobile) {
   .checkbox-justify-text input[type="checkbox"]+label span {
     padding-left: 46px;
     display: inline-block;
     float: left;
     margin-top: -35px;
   }
 }
 
 /* DESKTOP ONLY */
 @media screen and (min-width: $screen-tablet) {
   .checkbox-justify-text input[type="checkbox"]+label.tb-checkbox-flat span {
     margin-top: -30px;
   }
 }
 
 
 /** add this class when u want to show a hyphen(usually in selected count)**/
 input[type="checkbox"].customCheckboxContent {
   &:checked {
     +label:before {
       border: 2px solid $checked-color;
       background-image: url("~assets/images/minus_white.svg");
       background-position: center;
     }
   }
 }
 
 /** use this heirarchy of classes customCheckboxHolder > customcheckbox 
   when u want to invert the default checkbox css**/
 .customCheckboxHolder input[type=checkbox].customcheckbox {
   margin: 0;
 
   +label:before {
     margin: 0 !important;
     border: 2px solid $white;
   }
 
   &:checked {
     +label:before {
       background-color: $white !important;
       background-image: url("~assets/images/confirmation_check_blue.svg");
       background-position: center;
     }
   }
 }
 
 /* For Long Checkbox Labels Spanning to next line */
 input[type="checkbox"].classicCheckbox {
   appearance: none;
   position: inherit;
   opacity: 1;
   margin-right: 10px;
   border-radius: 3px;
   border: 2px solid $accordion-black;
 
   &:focus,
   &:hover {
     border: 2px solid $cyan-blue;
   }
 
   &:checked:before {
     content: "\2714\fe0e";
     color: $white;
     background-color: $checked-color;
     padding: 4px 5px 0px 3px;
   }
 }
 