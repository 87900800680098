/* RECTANGULAR RADIO BOXES, NOTE: Text-Overflow is not accounted for */
/* Updated to fix defect #67794 */

.radio-rect-box input[type="radio"] + label, .radio-box input[type="radio"] + label {
  color: $link-color;
}

/* RESET */

.radio-rect-box {
  input[type="radio"] {
    + label:before, &:focus + label:before {
      display: inline-block;
      width: auto;
      margin: auto;
      background: none;
      position: initial;
      padding: 0;
      outline: 0;
      opacity: 0;
      overflow: hidden;
      content: none;
      vertical-align: auto;
    }
    &:checked {
      + label:before, &:focus + label:before {
        display: inline-block;
        width: auto;
        margin: auto;
        background: none;
        position: initial;
        padding: 0;
        outline: 0;
        opacity: 0;
        overflow: hidden;
        content: none;
        vertical-align: auto;
      }
      + label {
        background: $dark-link-color;
        color: $white;

        span {
          color: $white;
        }
      }
    }
    + label {
      &:before {
        display: inline-block;
        width: auto;
        margin: auto;
        background: none;
        position: initial;
        padding: 0;
        outline: 0;
        opacity: 0;
        overflow: hidden;
        content: none;
        vertical-align: auto;
      }
      height: 3.5em;
      padding: 1em 0 0 0;
      display: table-cell;
      border: 1px solid $gray-3;
      background: $white;
      text-align: center;
      margin-right: 0;
      cursor: pointer;
      float: left;
    }
    display: none;
  }
  margin-top: 20px;
  margin-bottom: 20px;
  float: left;
  width: 100%;
  &.box3 {
    input[type="radio"] + label {
      width: 32%;
    }
    div.separator {
      width: 2%;
      float: left;
    }
  }
  input[type="radio"] {
    &:disabled + label {
      border: 1px solid $tone-bone-white !important;
      background: $contrast-bone-white !important;
      color: $bone-white !important;
      cursor: default !important;
    }
    &.error {
      + label {
        border: 1px solid $alertErrorLeft;
        background: $error-bg-red;
        color: $black;
      }
      &:checked + label {
        background: $dark-link-color;
        color: $white;
        border: 1px solid $alertErrorLeft;
      }
    }
  }
}

/* ERROR */

/*********************/

/******* WE NEED TO ADD FOCUS ******/

/* MOBILE */
@media screen and (max-width: $screen-mobile) {
  .radio-rect-box.box3 {
    input[type="radio"] + label {
      width: 49%;
    }
    div.separator:nth-child(6) {
      display: none;
    }
    label:nth-child(8) {
      margin-top: 20px;
      width: 100% !important;
    }
  }
}

/*************************************/

/* SQUARE RADIO BOXES, NOTE: Text-Overflow is not accounted for */

/* RESET */

.radio-box {
  input[type="radio"] {
    + label:before, &:focus + label:before {
      display: inline-block;
      width: auto;
      margin: auto;
      background: none;
      position: initial;
      padding: 0;
      outline: 0;
      opacity: 0;
      overflow: hidden;
      content: none;
      vertical-align: auto;
    }
    &:checked {
      + label:before, &:focus + label:before {
        display: inline-block;
        width: auto;
        margin: auto;
        background: none;
        position: initial;
        padding: 0;
        outline: 0;
        opacity: 0;
        overflow: hidden;
        content: none;
        vertical-align: auto;
      }
    }
    + label:before {
      display: inline-block;
      width: auto;
      margin: auto;
      background: none;
      position: initial;
      padding: 0;
      outline: 0;
      opacity: 0;
      overflow: hidden;
      content: none;
      vertical-align: auto;
    }
    display: none;
  }
  float: left;
  width: 100%;
  &.centered {
    float: none;
    width: auto;
    position: static;
  }&.pad-center {
       margin-left: 34%;
     }
  input[type="radio"] {
    + label {
      padding: 10px;
      height: calc( 840px / 6);
      width: calc( 840px / 6);
      border: 1px solid $gray-3;
      margin: 20px 0 20px 0;
      background: $white;
      cursor: pointer;
      display: table;
      text-align: center;
      float: left;
      span {
        display: table-cell;
        vertical-align: middle;
        max-width: 120px;
      }
    }
    &:checked + label {
      background: $dark-link-color;
      color: $white;

      span {
        color: $white;
      }
    }
  }
  div.separator {
    width: 19px;
    float: left;
  }
}

/* IE 9, CHROME & ANDROID BROWSERS DOES NOT SUPPORT HYPHENS */

/* MOBILE */
@media screen and (max-width: $screen-mobile) {
  .radio-box {
    input[type="radio"] + label {
      height: 3.5em;
      margin-bottom: 0;
      width: 100%;
      &:last-child {
        margin-bottom: 1em;
      }
    }
    &.pad-center {
           margin-left: 0%;
         }
    div.separator {
      display: none;
    }
    input[type="radio"] + label span {
      word-wrap: break-word;
      max-width: none;
    }
  }


}
