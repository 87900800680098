@use "sass:math";
/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

 //---Generic styling----//
 
//  html {
//     font-size: 1rem;
//   }
  
//   @include media-breakpoint-up(sm) {
//     html {
//       font-size: 1.2rem;
//     }
//   }
  
//   @include media-breakpoint-up(md) {
//     html {
//       font-size: 1.4rem;
//     }
//   }
  
//   @include media-breakpoint-up(lg) {
//     html {
//       font-size: 1.6rem;
//     }
//   }
html{
    font-size: 12pt/1.125em;
}
 body {
    // dynamic font family assignment
    @each $theme, $map in $themes {
        &.#{$theme}  {
            color: map-get($map: $map, $key: text-color);
            font-family: map-get($map: $map, $key: font-family);
            font-size: map-get($map: $map, $key: font-size);
            line-height: map-get($map: $map, $key: line-height);

            // headings 
            @each $heading, $head in $headings-type {
                #{$heading} ,.#{$heading}{    
                    font-size: math.div(element-type($headings-type, $heading, 'font-size'),$default-font-size)+em;
                    line-height: element-type($headings-type, $heading, 'line-height');
                    font-family: element-type($headings-type, $heading, 'font-family');
                }
            }
        }
    }
    
    font-weight: 400;
    //background: $primary-color;
    position: relative;
    // overflow: hidden;
    width: 100%;
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;

    .container-fluid{
        padding: 0 $default-spacing;
        .row{
            margin: 0 (-$default-spacing);
            $screen-sizes: (xl,lg,md,sm,xs);
            .col-auto {
                padding: 0 $default-spacing;
            }
            @each $size in $screen-sizes {
                @for $i from 1 through 12{
                    .col-#{$size}-#{$i} {
                        padding: 0 $default-spacing;
                    }
                    .col-#{$i} {
                        padding: 0 $default-spacing;
                    }
                }
            }
        }
    }
}
  
* {
    &:focus {
        outline: none;
    }
}

a {
    color: $link;
    &:hover {
        outline: none;
        text-decoration: none;
    }
}

// for slim scroll only for chrome, edge and mozilla
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

@media only screen and (max-width: 640px) {
    body { 
        @each $theme, $map in $themes {
             &.#{$theme}  {
                 font-size: map-get($map: $map, $key: font-size)-2;
                 // headings 
                 @each $heading, $head in $headings-type {
                     #{$heading} ,.#{$heading}{    
                         $val : $default-font-size - 16; // default medium screen font size
                         font-size: math.div((element-type($headings-type, $heading, 'font-size')-$val),$default-font-size)+em;
                         line-height: element-type($headings-type, $heading, 'line-height');
                         $val: $val - 16;
                     }
                 }
             }
         }
    }
}