%default-tab{
  .nav-link{
    border-radius: 0;
  }
}

.tabset{
  @each $theme, $map in $themes {
    .#{$theme} & {
      position: relative;
      @extend %default-tab;
      @include tab(
        element-type($map, default-tab, 'background-color'),
        element-type($map, default-tab, 'color'),
        element-type($map, default-tab, 'padding')
      );
      @each $tab-name, $tab in $tab-types {
        &.#{$tab-name}{
          @include tab(
            element-type($tab-types, $tab-name, 'background-color'),
            element-type($tab-types, $tab-name, 'color')
          );     
        } 
      }
      .tab-content{
        padding: 0 10px;
      }
    }
  }
}

.vertical-tabset {
  .nav.vertical {
    .nav-item {
	  font-family: $default-font-family-medium;
      border-radius: 0px;
      background-color: $pale-blue;
      height: 49px;
      margin-bottom: 10px;
      text-align: left;
      width: -webkit-fill-available;
        .nav-link {
          color: $black;
          border-radius: 0px;
            &.active, &:hover {
              background-color: $button-color;
              color: $white;
              height: 49px;
            }
        } 
    }
  }
}

@media screen and (max-width: 767px) {
  .vertical-tabset {
    .nav.vertical {
      .nav-item {
        margin-bottom: 0px;
        border-bottom: 1px solid $gray;
        .nav-link {
          &.active {
            border-bottom: 1px solid $gray;
          }
        }
      }
    }
  } 
}

// .tabs ul {
//   list-style: none;
//   padding-bottom: 1.4em;
//   margin: auto;
//   padding-left: 0;
//   -webkit-margin-before: 0em;
//   -webkit-margin-after: 0em;
//   -webkit-margin-start: 0px;
//   -webkit-margin-end: 0px;
//   -webkit-padding-start: 0px;
//   li {
//     cursor: pointer;
//     display: inline-block;
//     padding-right: 30px;
//     a {
//       padding-bottom: 1em;
//       color: $gray-link-color;
//       &.active {
//         font-weight: 700;
//         @include border-bottom(0.25em,solid,$gray-link-color);
//       }
//       &:focus {
//         text-decoration: none;
//       }
//     }
//   }
// }


// .navtabs ul {
// border-bottom:1px solid #ccc;
//   list-style: none;
//   padding-bottom: 0.75em;
//   margin: auto;
//   -webkit-margin-before: 0em;
//   -webkit-margin-after: 0em;
//   -webkit-margin-start: 0px;
//   -webkit-margin-end: 0px;
//   -webkit-padding-start: 0px;
//   padding-left:10px;
//   li {
//     cursor: pointer;
//     display: inline-block;
//     padding-right: 10px;
//     a {
//         padding-bottom: 1em;
//         color: $gray-link-color;
// 	    padding: 10px 10px 10px 15px;
// 	    border: 1px solid #ccc;
// 	    background-color:#ccc;
//       &.active {
//       	font-weight: 700;
// 	    margin-bottom: -4px;
// 	    border-bottom: transparent;
// 	    padding-bottom: 11px;
// 	    background-color: #fff;
//       }
//       &:focus {
//         text-decoration: none;
//       }
//     }
//   }
// }


// .primefacestabs {
//   ul.ui-widget-header {
//     -webkit-margin-before: 0em;
//     -webkit-margin-after: 0em;
//     -webkit-margin-start: 0px;
//     -webkit-margin-end: 0px;
//     -webkit-padding-start: 0px;

//     li {
//       cursor: pointer;
//       list-style-position: outside;
//       a {
//         padding-bottom: 1em;
//         color: $gray-link-color;
//         &.active {
//           font-weight: 700;
//           @include border-bottom(0.25em,solid,$gray-link-color);
//         }
//         &:focus {
//           text-decoration: none;
//         }
//       }
//     }
//     &:first-child {
//       border-bottom: 1px solid #ccc;
//     }
//   }

//   .ui-tabs-panel {
//     display: block;
//     padding-left: 0em;
//     padding-right: 0em;
//   }

//   .ui-tabs-panel.ui-helper-hidden {
//     display: none;
//   }

//   .ui-tabs-selected.ui-state-active {
//     font-weight: 700;
//     border-bottom: 0.25em solid $gray-link-color;
//   }
// }
// .tab-bottom-bar {
//   @include border-bottom(2px, solid,$link-color-disabled);
// }
// .nav-pills .nav-link.active,
//  .nav-pills .show > .nav-link.active {
//   font-size:17px !important; 
//   color: $tab-font !important;
//   background-color: $white !important;
//   border-bottom: 3px solid $tab-underline-color !important;
//   padding: 7px 25px !important;
//   border-radius: 0 !important;
//   font-weight: 700 !important;
// }
// .nav-pills .nav-link {
//   font-size:17px !important;
//   color: $nav-tab-color !important;
// }
// .nav-pills .nav-link:hover {
//   font-size:17px !important;
//   color: $link !important;
// }
// .nav-link.active:hover {
//   color: $tab-font !important;
// }