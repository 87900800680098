@import "../../../components_7.1/components/shared/colors";
::ng-deep .carousel-indicators {
    background: $white;
    border: 0.063rem solid $white;
    width: 6.25rem;
    height: 0.9rem;
    opacity: 70%;
    bottom: -1.6rem;
    margin:auto;
    width: 100%;
    li {
        height: 0.75rem !important;
        width : 0.75rem !important;
        border-radius: 0.57rem !important;
        background-color: $white;
        border: 0.063rem solid $black;
        margin-left: 0.625rem;
        margin-right: 0.625rem;
    }         
    .active {
        background-color: $black;
        height: 0.75rem !important;
        width : 0.75rem !important;
        border-radius: 0.57rem !important;
        border: 0.063rem solid $black;
        margin-left: 0.625rem;
        margin-right: 0.625rem;
        .oval {
            box-sizing: border-box;
            height: 15px;
            width: 15px;
            border: 1px solid $black;
        }
    }
}

::ng-deep .carousel-control-prev{
    width:3.125rem;
    opacity: 1;
    .carousel-control-prev-icon{
        background-image: url('~assets/images/blueprint/Default-Back.svg');
        width: 70%;
        height: 40%;
        margin-left: -4rem;
        background-repeat: no-repeat;
        &:hover {
            background-image: url('~assets/images/blueprint/Hover-Back.svg');
        }
        &:active {
            background-image: url('~assets/images/blueprint/Active-Back.svg');
        }
    }
}

::ng-deep .carousel-control-next{
    width:3.125rem;
    opacity: 1;
    .carousel-control-next-icon{
        background-image: url('~assets/images/blueprint/Default-Forward.svg');
        width: 70%;
        height: 40%;
        margin-right:-2.7rem;
        background-repeat: no-repeat;
        &:hover {
            background-image: url('~assets/images/blueprint/Hover-Forward.svg');
        }
        &:active {
            background-image: url('~assets/images/blueprint/Active-Forward.svg');
        }
    }
}

::ng-deep .carousel-inner {
      border-bottom: solid 0.19rem $blue00;
      box-shadow: 0rem 0.32rem 0.19rem -0.125rem $gray;
}

.centered {
    position: absolute;
    bottom: 44%;
    left: 30%;
    z-index: 20;
}
.carousalApp {
    position: absolute;
    bottom: 27%;
    left: 50%;
    z-index: 20;
}
.bannerSecond{
    position: absolute;
    bottom: 8%;
    left: 83%;
    z-index: 20;
}
.bannerColor {
    color: $error-blue-bg;
}
.background-slide2 {
    background-image: url('~assets/images/blueprint/cbcc-banner-bg.png');
    background-repeat: no-repeat;
    height: 4.94rem;
}
.only{
    font-size: 0.75rem;
    font-weight: 400;
    margin-left: -1.25rem;
}
.price {
    font-size: 1.57rem;
    margin-left: -0.94rem;
}
.per {
    font-size: 0.68rem;
    margin-left: 2rem;
    margin-top: -1.06rem;
}
.sup {
    margin-left: -0.063rem;
    top: -1em;
}
.banner-font {
    font-size: 0.75rem;
}
.banner-font-cox {
    letter-spacing: 0.063rem;
    font-size: 1rem;
}
.mobileapp-margin{
    margin-left: -0.063rem;
}
.mobileapp-font{
    margin-left: 3.44rem;
}
.banner-font-cox-mobile {
    font-size: 1.07rem;
    margin-left: 2.69rem;
    bottom: 0.19rem;
}
.banner-font-mobile {
    font-size: 0.81rem;
    margin-left: 0.625rem;
    font-weight: 400;
    letter-spacing: 0.063rem;
}
.mobileapp-width{
    width: 8.75rem;

}
.pad-bottom-p-10 {
    padding-bottom: 0.625rem;
}
.pad-top-0-5{
    padding-top: 1.7rem;
}
.pad-top-0-4 {
    padding-top: 0.4em;
}
.pad-left-0-5 {
    padding-left: 0.5em;
}
.vertical-line {
    border-left: 4.69rem solid $vertical-line-color;
    height: 4.69rem;
    top: -1.2rem;
    border-left-color: $light-grey;
    border-left-width: thin;
    margin-left: 0rem;
}
.carousel-position {
    top: 1.25rem;
    margin-left: 0.88rem;
    margin-bottom: 1.57rem;
    margin-right: 1.38rem;
}
.second-banner-position {
    margin-left: 0rem;
}
.banner-position-xs {
    margin-left: -0.25rem;
}
.slide1-border {
    border: solid;
    border-width: 0.04rem;
    border-color: $light-grey;
}
.covid-para{
    font-size: 0.73rem;
    color:$black;
    margin-bottom: 0rem;
    margin-left: 0.38rem;
    margin-right: 0.38rem;
}
.banner-covid-heading {
    letter-spacing: 0.063rem;
    font-size: 0.94rem;
    color: $black;
    margin-left: 0.38rem;
}
.covid-para-height{   
    top: 0.2rem;
}
.banner-covid-heading-voice{
    letter-spacing: 0.063rem;
    font-size: 0.94rem;
    color: $black;
    margin-left: -0.57rem;
}
.covid-para-voice{
    font-size:0.71rem;
    color:$black;
    margin-bottom: 0rem;
    margin-left: -0.57rem;
}
.networkText{
    font-size: 0.94rem;
}
.img-alignment{
    margin-left: 0.32rem;
}
.learn-img-alignment{
    margin-bottom: 0.4rem;
}
.icon_top_pad {
    padding-top: 0.7em;
}
.learn-covid{
text-decoration: underline;
}
.banner-covid-heading-mobile{
    color:$black;
    font-size: 0.75rem;
}
.covid-para-mobile{
    color: $black;
    font-size: 0.57rem;
}
.carousel-ml-5{
    margin-left: 0.32rem;
}
.carousel-ml-10{
    margin-left: 0.625rem;
}
.carousel-b-0{
    bottom: 0rem;
}