// Angular upgrade - Primeng - 11.1.0 

.p-button {
  display: block;
  background: $white;
  border: none;
  margin-left: -1.5em !important;
  &.p-button-icon-only {
    width: 1em;
    height: 2.5em;
    margin: 0.3125em;
  }
  &:enabled:hover {
    background: $white;
    box-shadow: none !important;
    border: none !important;
  }
}
.p-button-icon {
  &:before {
    content: url("data:image/svg+xml,%3Csvg enable-background='new 0 0 24 24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cmask id='a' height='24' maskUnits='userSpaceOnUse' width='24' x='0' y='0'%3E%3Cpath clip-rule='evenodd' d='m15.55 10.8h2.4v2.4h-2.4zm-4.8 0h2.4v2.4h-2.4zm-4.8 0h2.4v2.4h-2.4zm14.4-8.3h-1.2v-2.5h-2.4v2.4h-3.6v-2.4h-2.4v2.4h-3.6v-2.4h-2.4v2.4h-1.2c-1.3.1-2.4 1.1-2.4 2.4v16.8c0 1.3 1 2.4 2.4 2.4h16.9c1.3 0 2.4-1.1 2.4-2.4v-16.8c-.1-1.3-1.1-2.3-2.5-2.3zm0 19.1h-16.8v-13.2h16.9v13.2z' fill='%23fff' fill-rule='evenodd'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath clip-rule='evenodd' d='m0 0h24v24h-24z' fill='%23808084' fill-rule='evenodd'/%3E%3C/g%3E%3C/svg%3E");
  }
  &:hover {
    content:url("data:image/svg+xml,%3Csvg enable-background='new 0 0 24 24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cmask id='a' height='24' maskUnits='userSpaceOnUse' width='24' x='0' y='0'%3E%3Cpath clip-rule='evenodd' d='m15.55 10.8h2.4v2.4h-2.4zm-4.8 0h2.4v2.4h-2.4zm-4.8 0h2.4v2.4h-2.4zm14.4-8.3h-1.2v-2.5h-2.4v2.4h-3.6v-2.4h-2.4v2.4h-3.6v-2.4h-2.4v2.4h-1.2c-1.3.1-2.4 1.1-2.4 2.4v16.8c0 1.3 1 2.4 2.4 2.4h16.9c1.3 0 2.4-1.1 2.4-2.4v-16.8c-.1-1.3-1.1-2.3-2.5-2.3zm0 19.1h-16.8v-13.2h16.9v13.2z' fill='%23fff' fill-rule='evenodd'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath clip-rule='evenodd' d='m0 0h24v24h-24z' fill='%23285a93' fill-rule='evenodd'/%3E%3C/g%3E%3C/svg%3E");    
  }
}

.p-inputwrapper.calendar-blueicon {
  .p-calendar-w-btn {
    input.p-inputtext {
      height: 48px !important;
      width: 180px !important;
    }
  
    .p-button.p-button-icon-only {
      width: 1.5em !important;
      margin-left: -2em !important;
      .p-button-icon {
        content:url("data:image/svg+xml,%3Csvg enable-background='new 0 0 24 24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cmask id='a' height='24' maskUnits='userSpaceOnUse' width='24' x='0' y='0'%3E%3Cpath clip-rule='evenodd' d='m15.55 10.8h2.4v2.4h-2.4zm-4.8 0h2.4v2.4h-2.4zm-4.8 0h2.4v2.4h-2.4zm14.4-8.3h-1.2v-2.5h-2.4v2.4h-3.6v-2.4h-2.4v2.4h-3.6v-2.4h-2.4v2.4h-1.2c-1.3.1-2.4 1.1-2.4 2.4v16.8c0 1.3 1 2.4 2.4 2.4h16.9c1.3 0 2.4-1.1 2.4-2.4v-16.8c-.1-1.3-1.1-2.3-2.5-2.3zm0 19.1h-16.8v-13.2h16.9v13.2z' fill='%23fff' fill-rule='evenodd'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath clip-rule='evenodd' d='m0 0h24v24h-24z' fill='%23285a93' fill-rule='evenodd'/%3E%3C/g%3E%3C/svg%3E");
        height: 24px;
        width: 24px;
        display: block;
      }
    }
  }
}

.p-datepicker  {
  color: $black;
  .p-datepicker-prev-icon {
    content:url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='38px' height='38px' viewBox='0 0 38 38' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 54.1 (76490) - https://sketchapp.com --%3E%3Ctitle%3EA006342D-3AA8-491F-92E0-7AA76E773746%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cdefs%3E%3Ccircle id='path-1' cx='15' cy='15' r='15'%3E%3C/circle%3E%3Cfilter x='-21.7%25' y='-18.3%25' width='143.3%25' height='143.3%25' filterUnits='objectBoundingBox' id='filter-2'%3E%3CfeOffset dx='0' dy='1' in='SourceAlpha' result='shadowOffsetOuter1'%3E%3C/feOffset%3E%3CfeGaussianBlur stdDeviation='2' in='shadowOffsetOuter1' result='shadowBlurOuter1'%3E%3C/feGaussianBlur%3E%3CfeColorMatrix values='0 0 0 0 0.501960814 0 0 0 0 0.501960814 0 0 0 0 0.517647088 0 0 0 0.487432065 0' type='matrix' in='shadowBlurOuter1'%3E%3C/feColorMatrix%3E%3C/filter%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='CBMA-UI' transform='translate(-15.000000, -686.000000)'%3E%3Cg id='Carousel/Directional-arrow/Default-Back' transform='translate(19.000000, 686.000000)'%3E%3Cg id='Left' transform='translate(0.000000, 3.000000)'%3E%3Cg id='Oval'%3E%3Cuse fill='black' fill-opacity='1' filter='url(%23filter-2)' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill='%23FFFFFF' fill-rule='evenodd' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3Cpolygon id='›' fill='%23285A93' transform='translate(14.494629, 15.596436) scale(-1, 1) translate(-14.494629, -15.596436) ' points='18.9892578 15.8271484 12.8710938 23.1928711 10 21.5351562 14.8193359 15.5878906 10 9.60644531 12.8710938 8 18.9892578 15.3828125'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }
  .p-datepicker-next-icon {
    content:url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='38px' height='38px' viewBox='0 0 38 38' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 54.1 (76490) - https://sketchapp.com --%3E%3Ctitle%3E697154B3-7FB1-459B-B5BB-1EEBFF0B64EF%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cdefs%3E%3Ccircle id='path-1' cx='15' cy='15' r='15'%3E%3C/circle%3E%3Cfilter x='-21.7%25' y='-18.3%25' width='143.3%25' height='143.3%25' filterUnits='objectBoundingBox' id='filter-2'%3E%3CfeOffset dx='0' dy='1' in='SourceAlpha' result='shadowOffsetOuter1'%3E%3C/feOffset%3E%3CfeGaussianBlur stdDeviation='2' in='shadowOffsetOuter1' result='shadowBlurOuter1'%3E%3C/feGaussianBlur%3E%3CfeColorMatrix values='0 0 0 0 0.501960784 0 0 0 0 0.501960784 0 0 0 0 0.517647059 0 0 0 1 0' type='matrix' in='shadowBlurOuter1'%3E%3C/feColorMatrix%3E%3C/filter%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='CBMA-UI' transform='translate(-255.000000, -686.000000)'%3E%3Cg id='Carousel/Directional-arrow/Hover-Foward' transform='translate(259.000000, 686.000000)'%3E%3Cg id='Right' transform='translate(0.000000, 3.000000)'%3E%3Cg id='Oval'%3E%3Cuse fill='black' fill-opacity='1' filter='url(%23filter-2)' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill='%23FFFFFF' fill-rule='evenodd' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3Cpolygon id='›' fill='%23285A93' points='19.9892578 18.8271484 13.8710938 26.1928711 11 24.5351562 15.8193359 18.5878906 11 12.6064453 13.8710938 11 19.9892578 18.3828125'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }
  .p-datepicker-month, .p-datepicker-year, .ng-tns-c140-0, .ng-tns-c140-1 {
    color: $black;
   }
  .p-datepicker-month, .p-datepicker-year {
    color: $black !important;
    font-size: 24px;
    font-weight: 400 !important;
    padding: 0px !important;
    text-transform: capitalize;
  }
  &:not(.p-datepicker-inline) {
    background-color: $white;
    border: 1px solid $gray-3;
    padding: 10px;
  .p-datepicker-header {
    background-color: $white;
    padding: 15px 10px;
    border-bottom: 1px solid $gray;
  }
.p-datepicker-title {
  font-size: 1.5em;
  font-weight: 400;
  }
  }
thead tr {
th.ng-tns-c140-0, .ng-tns-c140-1 {
  text-align: center;
  font-weight: 500;
 }
}
table td {
  padding: 0.05em;
&.p-datepicker-today {
> span {
  color: $cyan-blue;
  border: 2px solid $button-color;
  background: $white;
}
> span.p-highlight {
  background: $button-color;
  color: $white;
}
}
> span.p-highlight {
  border: 2px solid $button-color;
  background: $button-color; 
  color: $white;
}
}
.p-ripple {
  text-decoration: underline;
&:hover {
  border: 2px solid $cyan-blue;
  }
}
.p-disabled {
  text-decoration: none;
  color: $pebble-grey;
  opacity: 1;
}
.p-datepicker-calendar {
  font-size: 0.75em;
  margin-top:0.25em;
}
}

p-calendar.datesWithDots {
  .p-datepicker {
    display: block;
    background-color: $white;

    .p-datepicker-group-container {
      font-family: $default-font-family;
      @media only screen and (max-width: 320px){
        margin-right: -10px; 
      }
    }

    .p-datepicker-header {
      background: $white;
      border-bottom: none;
      font-size: 14px;
      font-weight: 700;
      @media only screen and (max-width: 320px){
        margin-right: 10px; 
      }
	  
	  .p-datepicker-prev.p-link, .p-datepicker-next.p-link {
        &:focus {
          border: none !important;
          box-shadow: none !important;
        }
        &:focus-visible {
          border: 1px solid $button-color !important;
        }
      }

      .p-datepicker-next-icon {
        content:url("~assets/images/blueprint/Active-Forward.svg");
        width: 30px;
        height: 30px;
      }

      .p-datepicker-prev-icon {
        content:url("~assets/images/blueprint/Active-Back.svg");
        width: 30px;
        height: 30px;
      }
    }

    .p-datepicker-prev.disabled{
      pointer-events: none;
      .p-datepicker-prev-icon {
        content:url("~assets/images/blueprint/CBMA UI/Inactive-Back.svg");
        width: 30px;
        height: 30px;
      }
    }

    .p-datepicker-calendar-container {
      padding: 0px 10px 0px 10px;
    }

    .p-ripple {
      text-decoration: none;
      height: 42px;
      width: 35.57px;
      font-size: 14px;
      &:hover {
        border: 2px solid $hover-button;
        border-radius: 50%;
		height: 36px;
      }

      .displayDot {
        height: 5px;
        width: 5px;
        background-color: $hover-button !important;
        border-radius: 50%;
        margin-left: 1px;
      }

      &.p-disabled {
        .displayDot {
          background-color: $gray !important;
        }
      }
    }

    table {
      thead tr {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
      }
      th {
        padding: 0px 0px 0px 0px;
        color: $ocean-green;
        font-size: 10px;
        width: 36.57px;
        text-align: center;
  
        &:last-child {
          padding-right: 0px;
        }
      }

      tbody {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
    
    

    table td > span.p-highlight {
      border: 2px solid $hover-button;
      background: $hover-button;
      border-radius: 50%;
      color: $white;
	  height: 36px;
    }

    table td.p-datepicker-today > span {
      color: $black;
      border: none;
      background: $white;
      font-family: $default-font-family-bold;
      
      &.p-highlight {
        border: 2px solid $hover-button;
        background: $hover-button;
        border-radius: 50%;
        color: $white;
        height: 36px;
      }
    }
  }
}