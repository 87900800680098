/* GLOBAL MESSAGES */

.msg-default {
  padding: 0;
}

div.msg-default {
  padding-bottom: 20px;
  padding-top: 15px;
}
.msg-error, .msg-success, .msg-info, .msg-alert {
  list-style-type: none;
  border-style: solid;
  // border-width: 1px 0px;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}

/************************************/

/* ERROR */

.msg-error {
  min-height: 23px;
  /* prevent container collapse if java fails to add a message */
  text-align: left;
  border-color: $alertErrorLeft;
  background-color: $alertErrorLeft;
}

li.msg-error {
  background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 54.1 (76490) - https://sketchapp.com --%3E%3Ctitle%3E25FA00C4-A4E3-4FAB-9FC3-EF17FD6B26CD%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='My-Account-Image-Library' transform='translate(-16.000000, -215.000000)' fill='%23C63C34'%3E%3Cg id='alert_error-' transform='translate(16.000000, 215.000000)'%3E%3Cpath d='M10.8,14.4 L13.2,14.4 L13.2,6 L10.8,6 L10.8,14.4 Z M10.8,19.2 L13.2,19.2 L13.2,16.8 L10.8,16.8 L10.8,19.2 Z M12,0 C5.376,0 0,5.376 0,12 C0,18.624 5.376,24 12,24 C18.624,24 24,18.624 24,12 C24,5.376 18.624,0 12,0 L12,0 Z' id='Fill-1'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") $error-bg-red no-repeat 15px 15px;
  padding-left: 3em;
  // @include border-bottom(1px ,solid,$alertErrorLeft);
  // @include border-top(1px, solid, $alertErrorLeft);
  border-left-width: 10px;
  border-left-color: $alertErrorLeft;
  color: $black;
  padding-top:0.9em;
  padding-bottom: 0.9em;
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-right-width: 0px;
}

.abusive-category li.msg-error {
  padding-right: 20px;
}

/************************************/

/* ALERT */

.msg-alert {
  min-height: 23px;
  /* prevent container collapse if java fails to add a message */
  text-align: left;
  // border-color: $yellow;
  // background-color: $yellow-error-bg;
  border-color: $alertWarning;
  background-color: $alertWarning;
}

li.msg-alert {
  background:  url("~assets/images/blueprint/icons/alert_warning.svg") $alertWarning no-repeat 15px 15px;
  padding-left: 3em;
  @include border-top(1px, solid, $alertWarning);
  @include border-bottom(1px, solid, $alertWarning);
  border-left-width: 10px;
  border-left-color: $alertWarningLeft;
  padding-top:0.9em;
  padding-bottom: 0.9em;
}

span.msg-alert {
  background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='24px' height='22px' viewBox='0 0 24 22' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 54.1 (76490) - https://sketchapp.com --%3E%3Ctitle%3E3A26A201-C611-4767-A847-F5EEB49859C7%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cdefs%3E%3Cpath d='M0,21.9095238 L24,21.9095238 L12,0.614285714 L0,21.9095238 Z M13.1,19.247619 L10.9,19.247619 L10.9,16.9952381 L13.1,16.9952381 L13.1,19.247619 L13.1,19.247619 Z M13.1,14.7428571 L10.9,14.7428571 L10.9,7.47380952 L13.1,7.47380952 L13.1,14.7428571 L13.1,14.7428571 Z' id='path-1'%3E%3C/path%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='CBMA-UI' transform='translate(-256.000000, -206.000000)'%3E%3Cg id='alert_icon_warning' transform='translate(256.000000, 206.000000)'%3E%3Cg id='Icons/General/Alert-Copy'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cg id='align_icon_general_alert' fill-rule='nonzero'%3E%3C/g%3E%3Cg id='🎨-color' mask='url(%23mask-2)' fill='%23FF9E25'%3E%3Cg transform='translate(0.000000, -1.023810)' id='Rectangle-4-Copy'%3E%3Crect x='0' y='0' width='24' height='24.5714286'%3E%3C/rect%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") $alertWarning no-repeat 15px 15px;
  padding-left: 3em;
  @include border-top(1px, solid, $alertWarning);
  @include border-bottom(1px, solid, $alertWarning);
  padding-right: 37px;
}

/************************************/

/* INFO */

.msg-info {
  min-height: 23px;
  /* prevent container collapse if java fails to add a message */
  text-align: left;
    // border-color: $blue;
    border-color: $light-gray;
    background-color: $light-gray;
}

li.msg-info {
  background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 54.1 (76490) - https://sketchapp.com --%3E%3Ctitle%3EEC13F084-A5F8-408B-9987-433E4AF6C49D%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='My-Account-Image-Library' transform='translate(-95.000000, -215.000000)' fill='%23009AE0'%3E%3Cg id='alert_information' transform='translate(95.000000, 215.000000)'%3E%3Cpath d='M10.8,8.4 L13.2,8.4 L13.2,6 L10.8,6 L10.8,8.4 Z M10.8,19.2 L13.2,19.2 L13.2,10.8 L10.8,10.8 L10.8,19.2 Z M12,0 C5.376,0 0,5.376 0,12 C0,18.624 5.376,24 12,24 C18.624,24 24,18.624 24,12 C24,5.376 18.624,0 12,0 L12,0 Z' id='Fill-1'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A") $infoalertBgColor no-repeat 15px 15px;
  padding-left: 3em;
  @include border-top(1px, solid,$light-gray);
  @include border-bottom(1px, solid, $light-gray);
  border-left-width: 10px;
  border-left-color: $infoalertWarning;
  padding-top:0.9em;
  padding-bottom: 0.9em;
}

/************************************/

/* SUCCESS */

.msg-success {
  min-height: 23px;
  /* prevent container collapse if java fails to add a message */
  text-align: left;
  border-color: $alertSuccessBorder;
  background-color: $alertSuccessBorder;
  
}

li.msg-success {
  background: url("~assets/images/blueprint/icons/alert_success.svg") $error-green-bg no-repeat 15px 15px;
  padding-left: 3em;
  // border-top: 1px solid $green;
  // border-bottom: 1px solid $green;
  @include border-top(1px, solid,$alertSuccessBorder);
  @include border-bottom(1px, solid, $alertSuccessBorder);
  border-left-width: 10px;
  border-left-color: $alertSuccessLeft;
  color: $black;
  padding-top:0.9em;
  padding-bottom: 0.9em;
}

/************************************/

/* SPACING */

