.cb-mat-tab.mat-tab-group {
    .mat-tab-labels {
        border-bottom: 1px solid $gray-modal-header;
        width: fit-content;
        .mat-tab-label {
            opacity: 1;
            padding: 0 4px;
            color: $light-black;
            letter-spacing: 0.16px;

            &.mat-tab-label-active {
                font-weight: 700;
                color: $hover-color;
                border-bottom: 3px solid $hover-color;
            }
        }

        .mat-tab-label:not(:last-child) {
            margin-right: 40px;
        }
    }
}